import axios from "axios";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";

export const loginRequest = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/login`,
      { login: data }
    );
    return {
      status: response.status,
      sessionResponse: jwtDecode(response.data.data),
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};
