import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";

const NoAuthRequired = (Component) => {
  const WithoutAuth = (props) => {
    const [isUnauthorized, setIsUnauthorized] = useState(null);

    useEffect(() => {
      const checkAuth = async () => {
        try {
          let storedUser = null;
          try {
            storedUser = localStorage.getItem("user");
          } catch (error) {
            console.error("Failed to access localStorage:", error);
            setIsUnauthorized(true);
            return;
          }

          if (storedUser) {
            const localToken = await CryptoService(storedUser, "decrypt-json");
            const isTokenExpired = moment().unix() > localToken.exp;
            const shouldTokenBeReplaced = !!localToken.toReplace;

            if (
              localToken.sessionId &&
              !isTokenExpired &&
              !shouldTokenBeReplaced
            ) {
              window.location.href = "/dashboard";
              return;
            }
          }
          setIsUnauthorized(true);
        } catch (err) {
          console.error(err);
          window.location.href = "/login";
        }
      };

      checkAuth();
    }, []);

    return isUnauthorized === null ? null : isUnauthorized ? (
      <Component {...props} />
    ) : null;
  };

  return WithoutAuth;
};

export default NoAuthRequired;
