import CryptoJS from "crypto-js";

export const CryptoService = async (data, type) => {
  if (type === "decrypt-json" || type === "decrypt-string") {
    try {
      const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_JWT_KEY);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (type === "decrypt-string") {
        return originalText;
      } else if (type === "decrypt-json") {
        const localTokenJson = await JSON.parse(originalText);
        return localTokenJson;
      }
    } catch (err) {
      return err;
    }
  } else if (type === "encrypt-crypto") {
    try {
      const response = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
        `${process.env.REACT_APP_JWT_KEY}`
      ).toString();
      return response;
    } catch (err) {
      return err;
    }
  } else if (type === "encrypt-jwt") {
    try {
      const response = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
        `${process.env.REACT_APP_JWT_KEY}`
      ).toString();
      return response;
    } catch (err) {
      return err;
    }
  }
};
