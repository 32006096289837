export default function Blockquote({color,icon,text, observation}) {
    return (
      <div className="flex w-full overflow-hidden rounded-md">
        <div
          className="bg-alertBackground border-l-4 border-[#2463eb] p-4 flex w-full"
          style={{
            borderColor: color,
          }}
        >
          <div
            className="min-w-[1rem] mr-4 text-[1rem]"
            style={{
              color: color,
            }}
          >
            {icon}
          </div>
          <div className="flex flex-col gap-y-2">
            <p
              className="text-sm leading-normal"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {observation && (
              <p className="text-sm leading-normal"><b>Observação:</b> {observation}.</p>
            )}
          </div>
        </div>
      </div>
    );
} 