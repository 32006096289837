import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const postPlayerMoment = async (data) => {
  try {
    const sessionId = data.sessionId;
    const classId = data.classId;
    const courseId = data.courseId;
    const disciplineId = data.disciplineId;
    const watchedSeconds = data.watchedSeconds;
    const totalSeconds = data.totalSeconds;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        classId: classId,
        courseId: courseId,
        watchedSeconds: watchedSeconds,
        totalSeconds: totalSeconds,
        disciplineId: disciplineId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/postPlayerMoment`,
      body,
      config
    );
    return { status: responseCrypt.data.code };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    if (err.response && err.response.data) {
      return { status: err.response.data.code };
    } else {
      return { status: "Unknown error occurred" };
    }
  }
};
