import React from "react";

import { HiPlay } from "react-icons/hi2";
import { Link } from "react-router-dom";

function ProgressCard({
  widthPercent,
  image,
  typeCourse,
  name,
  progress,
  redirectTo,
}) {
  const progressBar = {
    width: progress + "%",
  };

  return (
    <div className="flex flex-col group w-full h-full">
      <Link
        to={`/course/${redirectTo}`}
        className="flex bg-backgroundTwo h-full p-4 rounded-md gap-x-5 cursor-pointer z-10 max-h-[242px] max-lg:flex-col max-lg:p-0 max-lg:max-h-full"
      >
        <div className="min-w-[120px] w-[45%] h-full max-lg:min-w-full max-lg:w-[58,13vw]">
          <img
            src={image}
            className="w-full rounded-md object-cover h-[213px] max-lg:h-[37.33vw] max-lg:rounded-b-none"
            alt="Capa do curso"
          />
        </div>
        <div className="flex flex-col justify-between w-full max-lg:p-4 max-lg:min-h-[186px]">
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-sm text-themeColor max-lg:text-xs">
              {typeCourse}
            </span>
            <span className="font-medium text-base select-none leading-tight line-clamp-3 max-lg:text-sm">
              {name}
            </span>
          </div>
          <div className="flex flex-col gap-4 pt-4 pb-1">
            <div className="w-full h-[0.3125rem] rounded-full bg-[#2a2d31]">
              <div
                className="h-full rounded-full bg-themeColor"
                style={progressBar}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                <div className="bg-[#2a2d31] group-hover:text-[#2a2d31] group-hover-bg-themeColor transition-all duration-300 h-[1.625rem] w-[1.625rem] rounded-full flex items-center justify-center text-themeColor">
                  <HiPlay className="w-2 h-2" />
                </div>
                <span className="font-normal text-sm max-lg:text-xs">
                  Continuar
                </span>
              </div>
              <span className="font-normal text-sm max-lg:text-xs">
                {progress}%
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProgressCard;
