import { HiInformationCircle, HiMiniXMark } from "react-icons/hi2";
import Blockquote from "../Blockquote/Blockquote";

export default function BlockquoteModal({
  text_top,
  text_blockquote,
  outsideClick,
  buttonsLeft,
  buttonsRight,
  innerHTML,
  innerObject,
  blockquoteClassName,
  disable_blockquoteMode,
  activeCloseButton
}) {
  return (
    <div className={`fixed z-[199] w-full h-[100vh] flex items-center justify-center top-0`}>
      <div
        onClick={outsideClick}
        className="absolute w-full h-full bg-backgroundOne/40 backdrop-blur-[1px]"
      />
      <div className={`flex z-[1] flex-col gap-y-5 bg-backgroundTwo shadow-2xl p-6 rounded-xl w-[688px] max-[745px]:w-[90%] ${blockquoteClassName}`}>
        <div className="flex w-full items-center justify-between gap-x-3">
          <p className="text-textPrimary text-[1rem] font-semibold">
            {text_top}
          </p>
          <div className="cursor-pointer flex" onClick={outsideClick}>
            <HiMiniXMark className="w-5 h-5" />
          </div>
        </div>
        <div className="w-full h-[1px] bg-backgroundThree rounded-full" />
        <div className="flex flex-col gap-y-5 py-3 w-full">
          {text_blockquote && disable_blockquoteMode ? (
            <p
              className="text-sm leading-normal"
              dangerouslySetInnerHTML={{ __html: text_blockquote }}
            />
          ) : text_blockquote ? (
            <Blockquote
              text={text_blockquote}
              color="#2563eb"
              icon={<HiInformationCircle />}
            />
          ) : null}
          {innerHTML && (
            <span dangerouslySetInnerHTML={{ __html: innerHTML }} />
          )}
          {innerObject && innerObject}
        </div>
        <div className="w-full h-[1px] bg-backgroundThree rounded-full" />
        <div className="flex w-[100%] justify-between">
          {buttonsLeft && buttonsLeft.length > 0 && (
            <div className="flex gap-x-4 w-[100%]">
              {buttonsLeft && buttonsLeft.map((button) => button)}
            </div>
          )}
          <div className="flex w-[100%]">
            <div className="flex justify-end gap-x-4 w-full">
              {buttonsRight &&
                buttonsRight.map((button) => (
                  <div
                    className={`flex ${
                      button.props.textButton === "Cancelar"
                        ? "max-sm:hidden"
                        : ""
                    }`}>
                    {button}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
