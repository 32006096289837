import React, { useEffect, useState } from "react";
import "./MobileNavigationBar.css";
import {
  HiClipboardDocument,
  HiClipboardDocumentList,
  HiDocumentCheck,
  HiDocumentText,
  HiHome,
  HiIdentification,
  HiMiniAcademicCap,
  HiMiniBriefcase,
  HiMiniChevronRight,
  HiMiniCurrencyDollar,
  HiMiniFolder,
  HiMiniHome,
  HiMiniNewspaper,
  HiOutlineUserCircle,
} from "react-icons/hi2";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { Link } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import moment from "moment/moment";
import * as Sentry from "@sentry/react";
import { logoutRequest } from "../../services/logout/logout.service";
import { certifier } from "../../mocks/certifier";
import { serverTime } from "../../services/serverTime/serverTime";

const sign = require("jwt-encode");

function MobileNavigationBar({ actualPage }) {
  const [focusedContent, setFocusedContent] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [actualRoute, setActualRoute] = useState(actualPage);
  const [navBar, setNavBar] = useState(0);
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const student = await getStudentInfos();
        setSessionName(student.name);
        setIsFirstAccess(student.firstAccess);
        if (window) {
          setActualRoute(window.location.pathname);
          const navBar = document.getElementById("mobile-navigation");
          if (navBar) setNavBar(navBar.offsetHeight);
        }
      } catch (error) {
        console.error("Erro ao buscar informações do estudante:", error);
      }
    };
    fetchStudentInfo();
  }, []);

  const handleExitAction = async (action) => {
    try {
      const studentInfo = await getStudentInfos();
      if (studentInfo) {
        const sessionId = studentInfo.sessionId;

        const timeServer = await serverTime();

        let iat = moment().unix();
        let exp = moment().add(2, "hours").unix();

        if (timeServer.status === 200) {
          const serverTimeISO = timeServer.data;
          iat = moment(serverTimeISO).unix();
          exp = iat + 2 * 3600;
        }

        const post_data = sign(
          {
            sessionId, certifierName: certifier.generalSettings.name,
            environment: process.env.REACT_APP_API_ENVIRONMENT,
            exp,
            iat,
          },
          process.env.REACT_APP_JWT_KEY
        );
        logoutRequest(post_data)
          .then((res) => {
            if (res.status === 200) {
              Sentry.setUser(null);

              localStorage.removeItem("user");
              window.location.href = "/login";
              return;
            }
          })
          .catch((err) => {
            console.error("Erro ao processar a solicitação.");
          });
      } else {
        throw new Error("No user session found");
      }
    } catch (error) {
      console.error(
        "Erro sessão inválida ou ao buscar informações do estudante:",
        error
      );
      window.location.href = "/login";
    }
  };

  const classesSpan = [
    {
      sectionName: "Início",
      content: [
        {
          icon: <HiMiniHome className="w-6 h-6" />,
          title: "Página Inicial",
          url: "/dashboard",
        },
        {
          icon: <HiMiniAcademicCap className="w-6 h-6" />,
          title: "Meus Cursos",
          url: "/studentCourses",
        },
      ],
    },
  ];

  const avaliationsSpan = [
    {
      sectionName: "Provas",
      content: [
        {
          icon: <HiClipboardDocumentList className="w-6 h-6" />,
          title: "Avaliações",
          url: "/avaliations",
        },
        {
          icon: <HiClipboardDocument className="w-6 h-6" />,
          title: "Trabalho de Conclusão de Curso",
          url: "/tcc",
        },
      ],
    },
  ];

  const docsSpans = [
    {
      sectionName: "Documentos",
      content: [
        {
          icon: <HiDocumentCheck className="w-6 h-6" />,
          title: "Declarações",
          url: "/user/declarations",
        },
        {
          icon: <HiIdentification className="w-6 h-6" />,
          title: "Documentos",
          url: "/user/documents",
        },
        {
          icon: <HiDocumentText className="w-6 h-6" />,
          title: "Contratos",
          url: "/user/contracts",
        },
        {
          icon: <HiMiniFolder className="w-6 h-6" />,
          title: "Certificados",
          url: "/user/certificates",
        },
      ],
    },
  ];

  const profileSpans = [
    {
      sectionName: "Perfil",
      content: [
        {
          icon: <HiOutlineUserCircle className="w-6 h-6" />,
          title: "Dados Pessoais",
          url: "/user/basicInfos",
        },
      ],
    },
  ];

  const menuItems = [
    {
      name: "Início",
      type: "onScreen",
      content: classesSpan,
      icon: <HiHome className="w-6 h-6" />,
    },
    {
      name: "Provas",
      type: "onScreen",
      content: avaliationsSpan,
      icon: <HiMiniNewspaper className="w-6 h-6" />,
    },
    {
      name: "Documentos",
      type: "onScreen",
      content: docsSpans,
      icon: <HiMiniBriefcase className="w-6 h-6" />,
    },
    {
      name: "Financeiro",
      type: "url",
      link: "/finance",
      icon: <HiMiniCurrencyDollar className="w-6 h-6" />,
    },
    {
      name: "Perfil",
      type: "onScreen",
      content: profileSpans,
      icon: (
        <div className="flex items-center justify-center w-6 h-6">
          <div className="rounded-full bg-white w-[20px] h-[20px] text-black flex items-center justify-center">
            <span className="text-[12px] text-center">
              {sessionName.charAt(0)}
            </span>
          </div>
        </div>
      ),
      exitButton: true,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const navBar = document.getElementById("mobile-navigation");
      if (navBar) setNavBar(navBar.offsetHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header
        id="mobile-navigation"
        className={`hidden z-[104] max-lg:grid fixed bottom-0 left-0 w-full h-[3.75rem] border-white/5 overflow-hidden transition-all duration-150 bg-backgroundOne ${isMenuOpen ? "border-t " : ""
          } ${isFirstAccess &&
          "hidden opacity-0 pointer-events-none select-none h-0"
          }`}
        style={{
          gridTemplateColumns: `repeat(${menuItems.length}, minmax(0, 1fr))`,
        }}
      >
        {menuItems.map((item, index) =>
          item.link ? (
            <Link
              onClick={() => {
                actualPage === item.name && setIsMenuOpen(false);
              }}
              to={`${item.link}`}
              key={index}
              className={`flex flex-col items-center justify-center gap-1 ${(item.name === actualPage && !isMenuOpen) ||
                (item.name === focusedContent && isMenuOpen)
                ? "opacity-100"
                : "opacity-50 transition-all duration-150"
                } ${isFirstAccess &&
                item.name !== menuItems[menuItems.length - 1].name &&
                "important-opacity-0 select-none pointer-events-none"
                }`}
            >
              {item.icon}
              <p className="text-[0.6rem] text-textPrimary">{item.name}</p>
            </Link>
          ) : (
            <button
              onClick={() => {
                focusedContent !== item.name && setFocusedContent(item.name);
                focusedContent === item.name && isMenuOpen
                  ? setIsMenuOpen(false)
                  : setIsMenuOpen(true);
              }}
              key={index}
              m
              className={`flex flex-col items-center justify-center gap-1 ${(item.name === actualPage && !isMenuOpen) ||
                (item.name === focusedContent && isMenuOpen)
                ? "opacity-100"
                : "opacity-50 transition-all duration-150"
                } ${isFirstAccess &&
                item.name !== menuItems[menuItems.length - 1].name &&
                "important-opacity-0 select-none pointer-events-none"
                }`}
            >
              {item.icon}
              <p className="text-[0.6rem] text-textPrimary">{item.name}</p>
            </button>
          )
        )}
      </header>
      <div
        className={`fixed top-0 w-full h-full z-[103] transition-all overflow-hidden duration-150 ${isMenuOpen ? "translate-y-0" : "translate-y-[100%]"
          }`}
      >
        <div
          className="flex flex-col w-full h-full gap-y-10 justify-between bg-backgroundOne p-[5vw]"
          style={{ paddingBottom: `calc(${navBar}px + 5vw)` }}
        >
          <div className="flex flex-col gap-y-10 w-full">
            {menuItems
              .find((item) => item.name === focusedContent)
              ?.content.map((item, index) => (
                <div key={index} className="flex flex-col gap-y-4">
                  <p className="font-bold text-left leading-tight text-[1.25rem]">
                    {item.sectionName}
                  </p>
                  <div className="flex flex-col gap-y-2 pl-[5vw]">
                    {item.content.map((singleContent, index) => (
                      <Link
                        to={singleContent.url}
                        onClick={() => {
                          singleContent && actualRoute && actualRoute === singleContent.url &&
                            window.location.reload();
                        }}
                        key={index}
                        className="flex w-full gap-x-3 justify-between text-sm font-medium py-2"
                      >
                        <div className="flex items-center gap-x-4">
                          <div className="min-w-6 h-full">
                            {singleContent.icon}
                          </div>
                          <span className="font-normal">
                            {singleContent.title}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <HiMiniChevronRight />
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          {/* Exit button */}
          {menuItems.find((item) => item.name === focusedContent)
            ?.exitButton && (
              <div className="w-full flex justify-center">
                <ButtonPrimary
                  disableHorizontalMovement
                  optionalBackground={certifier.colors.buttons.buttonTertiary}
                  optionalTextColor={"#fff"}
                  textButton="Sair"
                  mobileWidth="60%"
                  outlineType
                  outlineColor="#1c1c24"
                  optionalClass="hoverWhiteBg"
                  verifyOnClick={handleExitAction}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default MobileNavigationBar;
