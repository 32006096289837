import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const getStudentBasicInfos = async (studentInfos) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const config = {
      headers: {
        auth: `${post_data}`
      }
    };

    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getStudentBasicInfos`,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);

      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const putStudentBasicInfos = async (studentInfos, data, step) => {
  try {
    const sessionId = studentInfos.sessionId;

    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const dataCrypt = {
      dataUpdate: data,
      step,
      exp: moment().add(2, "hours").unix()
    };

    const cryptoInfos = await CryptoService(dataCrypt, "encrypt-jwt");

    const body = { token: cryptoInfos };

    const config = {
      headers: {
        auth: `${post_data}`
      }
    };
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/putStudentBasicInfos`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: responseCrypt.data.data,
      status: responseCrypt.data.code
    };
  } catch (err) {
    return { status: err.response.data.code };
  }
};

export const addNewCourse = async (studentInfos, data) => {
  try {
    const sessionId = studentInfos.sessionId;
    const courseAlias = data.courseAlias;
    const typeCourse = data.typeCourse;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,
        certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix()
      },
      "encrypt-jwt"
    );

    const dataCrypt = {
      courseAlias,
      typeCourse,
    };

    const body = dataCrypt;

    const config = {
      headers: {
        auth: `${post_data}`
      }
    };

    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/addNewCourse`,
      body,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: responseCrypt.data.data,
      status: responseCrypt.data.code
    };
  } catch (err) {
    console.log(err);
    return { status: err.response.data.code };
  }
};
