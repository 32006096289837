import { useState, useEffect } from "react";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { certifier } from "../../mocks/certifier";
import { HiOutlineArrowSmallLeft } from "react-icons/hi2";
import Input from "../../components/Input/input";
import { Toastify } from "../Toastify/Toastify";
import {
  getStudentBasicInfos,
  putStudentBasicInfos
} from "../../services/studentService/student.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { CryptoService } from "../../services/cryptoService/crypto.service";

const allOptions = [
  {
    mobileHeight: 445,
    title: "Situação Profissional",
    value: "professionalSituation",
    type: "multiple",
    buttons: [
      { id: "Servidor Público", name: "Servidor Público", position: "top" },
      {
        id: "Profissional Privado",
        name: "Profissional Privado",
        position: "top"
      },
      { id: "Empresário", name: "Empresário", position: "bottom" },
      { id: "Desempregado", name: "Desempregado", position: "bottom" }
    ]
  },
  {
    mobileHeight: 280,
    title: "Qual é a sua profissão?",
    value: "professional",
    type: "input"
  },
  {
    mobileHeight: 580,
    title: "Informe sua faixa Salarial",
    value: "salaryRange",
    type: "multiple",
    buttons: [
      {
        id: "Até R$719",
        name: "Até R$719",
        position: "top",
        desktopWidth: "20"
      },
      { id: "De R$720 a R$1.749", name: "R$720 a R$1.749", position: "top" },
      {
        id: "De R$1.750 a R$3.084",
        name: "R$1.750 a R$3.084",
        position: "top",
        desktopWidth: "28"
      },
      {
        id: "De R$3.085 a R$5.640",
        name: "R$3.085 a R$5.640",
        position: "top",
        desktopWidth: "27"
      },
      {
        id: "De R$5.641 a R$11.278",
        name: "R$5.641 a R$11.278",
        position: "bottom"
      },
      {
        id: "De R$11.279 a R$25.554",
        name: "R$11.279 a R$25.554",
        position: "bottom"
      },
      { id: "Acima de R$25.555", name: "+ R$ 25.555", position: "bottom" }
    ]
  },
  {
    mobileHeight: 639,
    title: `Como conheceu ${certifier.generalSettings.grammatical.pronoun} ${certifier.generalSettings.shortName}?`,
    value: "howDidYouMeet",
    type: "multiple",
    buttons: [
      { id: "Familiares", name: "Familiares", position: "top" },
      { id: "Google", name: "Google", position: "top" },
      { id: "Youtube", name: "Youtube", position: "top" },
      {
        id: "Amigos de Trabalho",
        name: "Amigos de Trabalho",
        position: "top",
        desktopWidth: "35"
      },
      { id: "Linkedin", name: "Linkedin", position: "bottom" },
      { id: "Facebook", name: "Facebook", position: "bottom" },
      { id: "Instagram", name: "Instagram", position: "bottom" },
      { id: "Televisão", name: "Televisão", position: "bottom" },
      { id: "Podcast", name: "Podcast", position: "bottom" }
    ]
  },
  {
    mobileHeight: 618,
    title: "Por que decidiu fazer uma Pós-Graduação?",
    value: "reason",
    type: "multiple",
    buttons: [
      {
        id: "Quero mudar minha área de atuação",
        name: `Mudar<br class="max-md:hidden"/> de Carreira`,
        position: "top"
      },
      {
        id: "Quero ganhar um aumento salarial",
        name: `Aumento<br class="max-md:hidden"/> Salarial`,
        position: "top"
      },
      {
        id: "Quero adquirir novos conhecimentos",
        name: `Novos<br class="max-md:hidden"/> Conhecimentos`,
        position: "top"
      },
      {
        id: "Quero passar em um concurso público",
        name: "Passar em Concurso",
        position: "bottom"
      },
      {
        id: "Quero conseguir um emprego em uma empresa",
        name: `Novo<br class="max-md:hidden"/> Emprego`,
        position: "bottom"
      },
      {
        id: "Quero fazer networking",
        name: `Fazer<br class="max-md:hidden"/> Networking`,
        position: "bottom"
      },
      {
        id: "Nenhuma das alternativas",
        name: "Nenhuma das alternativas",
        position: "bottom"
      }
    ]
  }
];

export default function CareerPopUp({ handleClosePopUp }) {
  const [stepsDisplay, setStepsDisplay] = useState({
    acceptedSearch: false,
    actual: 0,
    total: 0
  });
  const [studentInfos, setStudentInfos] = useState(null);
  const [answers, setAnswers] = useState({});
  const [transitionClass, setTransitionClass] = useState("opacity-100");
  const [stepDisappearAnimation, setStepDisappearAnimation] = useState(false);
  const [startFormAnimation, setStartFormAnimation] = useState(false);
  const [contentTransitionClass, setContentTransitionClass] =
    useState("opacity-100");
  const [closing, setClosing] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleGetInfos = async () => {
      try {
        const student = await getStudentInfos();
        setStudentInfos(student);

        const studentData = await getStudentBasicInfos(student);
        const unfilledFields = [];
        const filledAnswers = {};

        allOptions.forEach((option) => {
          const fieldName = option.value;
          const fieldValue = studentData.data["5"][fieldName];
          if (!fieldValue) {
            unfilledFields.push(option);
          } else {
            filledAnswers[fieldName] = fieldValue;
          }
        });

        setOptionsToShow(unfilledFields);
        let allAnswers = filledAnswers;
        const instagram = studentData.data["5"].instagram;
        if (instagram) allAnswers.instagram = instagram;
        setAnswers(allAnswers);

        setStepsDisplay((prevState) => ({
          ...prevState,
          total: unfilledFields.length
        }));
      } catch (error) {
        console.error("Error fetching student info:", error);
      }
    };
    handleGetInfos();
  }, []);

  function handleOptionSelect(fieldName, value) {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [fieldName]: value
    }));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value
    }));
  }

  function handleStepTransition(direction) {
    const currentStep = stepsDisplay.actual;
    const nextIndex =
      direction === "next" ? stepsDisplay.actual + 1 : stepsDisplay.actual - 1;

    const shouldTransitionContent =
      (currentStep === 1 && nextIndex === 2) ||
      (currentStep === 2 && nextIndex === 1) ||
      (!stepsDisplay.acceptedSearch && nextIndex === 0) ||
      (stepsDisplay.acceptedSearch && nextIndex < 0);

    if (!currentStep && nextIndex === -1 && direction === "prev") {
      setStepDisappearAnimation(true);
      setTimeout(() => setStepDisappearAnimation(false), 300);
    }
    if (shouldTransitionContent) {
      setContentTransitionClass("opacity-0");
    }

    setTransitionClass("opacity-0");

    setTimeout(() => {
      if (direction === "next") {
        setStepsDisplay((prevState) => ({
          ...prevState,
          actual: prevState.actual + 1
        }));
      } else {
        if (nextIndex < 0) {
          setStepsDisplay({
            acceptedSearch: false,
            actual: 0,
            total: optionsToShow.length
          });
        } else {
          setStepsDisplay((prevState) => ({
            ...prevState,
            actual: prevState.actual - 1
          }));
        }
      }

      setTransitionClass("opacity-100");
      if (shouldTransitionContent) {
        setContentTransitionClass("opacity-100");
      }
    }, 300);
  }

  const handleClickWithDelay = (callback) => {
    const now = Date.now();
    if (now - lastClickTime < 400) return;
    setLastClickTime(now);
    callback();
  };

  const totalSteps = optionsToShow ? optionsToShow.length : 0;
  const currentOption =
    stepsDisplay.acceptedSearch && optionsToShow[stepsDisplay.actual]
      ? optionsToShow[stepsDisplay.actual]
      : null;

  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (currentOption && currentOption.mobileHeight) {
      setContainerHeight(currentOption.mobileHeight);
    } else {
      setContainerHeight(460);
    }
  }, [currentOption, optionsToShow]);

  return (
    <div className="fixed z-[199] w-full h-[100vh] flex items-center justify-center top-0">
      <div
        className={`absolute w-full h-full bg-backgroundOne/40 backdrop-blur-[1px] transition-opacity duration-300 ${
          closing ? "opacity-0" : "opacity-100"
        } max-md:mx-[5vw]`}
      />
      <div
        className={`flex z-[1] flex-col gap-y-5 ${
          currentOption && currentOption.type === "input"
            ? "bg-backgroundThree"
            : "bg-backgroundTwo"
        } shadow-2xl p-10 rounded-2xl w-[660px] md:min-h-[430px] justify-between transition-all duration-300 ${
          closing ? "scale-80 opacity-0" : "scale-100 opacity-100"
        } max-md:p-[5vw] max-md:w-[90vw] overflow-hidden`}
        style={{
          height:
            windowWidth <= 768 && containerHeight
              ? `${containerHeight}px`
              : "auto"
        }}>
        <div
          className={`flex flex-col gap-y-5 w-full transition-opacity duration-300`}>
          {stepsDisplay.acceptedSearch && (
            <div
              className={`flex w-full gap-[10px] py-3`}
              style={{
                animation: startFormAnimation
                  ? "fadeInOpacity 0.6s ease forwards"
                  : stepDisappearAnimation
                  ? "fadeOutOpacity 0.6s ease forwards"
                  : "none"
              }}>
              {Array.from({ length: stepsDisplay.total }, (_, index) => (
                <div
                  key={index}
                  className="w-[2.7rem] h-[4px] rounded-full bg-[#64758b] relative overflow-hidden">
                  <div
                    className="h-full bg-themeColor absolute left-0 top-0 transition-all duration-300"
                    style={{
                      width:
                        index < stepsDisplay.actual
                          ? "100%"
                          : index === stepsDisplay.actual
                          ? "100%"
                          : "0%"
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          {stepsDisplay.acceptedSearch ? (
            currentOption ? (
              <div
                key={0}
                className={`flex flex-col gap-y-5 w-full transition-opacity duration-300 key-0-test ${transitionClass}`}
                style={{
                  animation:
                    ((stepsDisplay.actual === 1 &&
                      stepsDisplay.actual - 1 === 0) ||
                      (stepsDisplay.actual === 0 &&
                        stepsDisplay.actual + 1 === 1)) &&
                    !startFormAnimation
                      ? "none"
                      : "fadeInOpacity 0.6s ease forwards"
                }}>
                <div className="flex w-full h-full">
                  <p
                    className={`text-textPrimary text-[1.375rem] font-semibold transition-opacity duration-300 ${transitionClass}`}>
                    {currentOption.title
                      ? currentOption.title
                      : "Carregando..."}
                  </p>
                </div>
                <div
                  className={`flex flex-col gap-2 transition-opacity duration-300 ${contentTransitionClass}`}>
                  {currentOption.type === "multiple" && (
                    <>
                      {["top", "bottom"].map((position) => {
                        const buttons = currentOption.buttons.filter(
                          (button) => button.position === position
                        );
                        const buttonIds = buttons.map((button) => button.id);
                        const buttonNames = buttons.map(
                          (button) => button.name
                        );
                        const buttonDesktopWidths = buttons.map(
                          (button) => button.desktopWidth
                        );
                        const buttonCount = buttonIds.length;

                        return (
                          <div
                            className="flex gap-2 max-md:flex-col"
                            key={position}>
                            {buttonIds.map((buttonId, index) => {
                              const isSelected =
                                answers[currentOption.value] === buttonId;
                              const textColor = isSelected
                                ? "#000"
                                : certifier.colors.buttons.buttonTertiary;
                              const bgColor = isSelected
                                ? "#FFF"
                                : "var(--backgroundThree)";

                              return (
                                <button
                                  key={`${position}-button-${index}`}
                                  className={`flex justify-center items-center min-h-[70px] rounded-lg transition-all duration-300 text-sm max-md:min-h-[45px]`}
                                  style={{
                                    width: `${
                                      windowWidth <= 768
                                        ? 100
                                        : buttonDesktopWidths[index]
                                        ? buttonDesktopWidths[index]
                                        : 100 / buttonCount
                                    }%`,
                                    backgroundColor: bgColor
                                  }}
                                  onClick={() =>
                                    handleOptionSelect(
                                      currentOption.value,
                                      buttonId
                                    )
                                  }>
                                  <span
                                    className={`transition-all duration-300 line-clamp-2 ${transitionClass}`}
                                    style={{
                                      transitionDelay:
                                        windowWidth > 768
                                          ? `${index * 40}ms`
                                          : "",
                                      color: textColor
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: buttonNames[index]
                                    }}/>
                                </button>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  )}
                  {currentOption.type === "input" && (
                    <Input
                      type="text"
                      name={currentOption.value}
                      value={answers[currentOption.value] || ""}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-y-5 w-full">
                <p className="text-center text-lg font-medium">
                  Todas as informações já estão preenchidas.
                </p>
              </div>
            )
          ) : (
            <div
              key={1}
              className={`flex flex-col gap-y-5 w-full transition-opacity duration-300 ${transitionClass}`}
              style={{
                animation:
                  transitionClass === "opacity-100"
                    ? "fadeInOpacity 0.6s ease forwards"
                    : "fadeOutOpacity 0.6s ease forwards"
              }}>
              <div
                className={`flex w-full justify-center transition-opacity duration-300 min-h-[165px] ${contentTransitionClass}`}>
                <img
                  src="/images/career/career-search.webp"
                  alt="chip"
                  className="py-6"
                />
              </div>
              <div
                className={`font-medium transition-opacity duration-300 ${contentTransitionClass}`}>
                Seu perfil de carreira está parcialmente preenchido. Atualize-o
                para acessar todos os benefícios do portal.
                <div
                  className={`text-sm w-full transition-opacity font-medium duration-300 ${transitionClass} pt-8 md:hidden`}
                  style={{
                    animation:
                      transitionClass === "opacity-100"
                        ? "fadeInOpacity 0.6s ease forwards"
                        : "fadeOutOpacity 0.6s ease forwards"
                  }}>
                  Responda em até: <b>2 minutos</b>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-5 w-full">
          <div className="w-full h-[1px] bg-backgroundThree rounded-full" />
          <div className="flex w-[100%] justify-between items-center">
            {!stepsDisplay.acceptedSearch && (
              <div
                className={`text-sm w-full transition-opacity font-medium duration-300 ${transitionClass} max-md:hidden`}
                style={{
                  animation:
                    transitionClass === "opacity-100"
                      ? "fadeInOpacity 0.6s ease forwards"
                      : "fadeOutOpacity 0.6s ease forwards"
                }}>
                Responda em até: <b>2 minutos</b>
              </div>
            )}
            <div className="flex justify-end gap-x-4 w-full">
              <div className="flex gap-5 max-md:w-full">
                <ButtonPrimary
                  optionalBackground={
                    stepsDisplay.acceptedSearch
                      ? certifier.colors.buttons.buttonTertiary
                      : "transparent"
                  }
                  optionalHoverBackground={
                    stepsDisplay.acceptedSearch ? "" : "#FFFFFF08"
                  }
                  optionalTextColor={
                    certifier.colors.buttons.buttonTertiary_darkTheme ||
                    !stepsDisplay.acceptedSearch
                      ? "#fff"
                      : "#000"
                  }
                  textButton={stepsDisplay.acceptedSearch ? "Voltar" : "Pular"}
                  disableHorizontalMovement={!stepsDisplay.acceptedSearch}
                  optionalMovingIcon={
                    stepsDisplay.acceptedSearch && <HiOutlineArrowSmallLeft />
                  }
                  leftIcon={stepsDisplay.acceptedSearch}
                  desktopWidth={140}
                  mobileWidth={"46%"}
                  verifyOnClick={() =>
                    handleClickWithDelay(() => {
                      if (stepsDisplay.acceptedSearch) {
                        handleStepTransition("prev");
                      } else {
                        setClosing(true);
                        setTimeout(() => handleClosePopUp(), 300);
                      }
                    })
                  }
                />
                <ButtonPrimary
                  textButton={
                    stepsDisplay.acceptedSearch
                      ? stepsDisplay.actual === totalSteps - 1 || !currentOption
                        ? "Finalizar"
                        : "Próximo"
                      : "Começar"
                  }
                  desktopWidth={140}
                  mobileWidth={"46%"}
                  verifyOnClick={() =>
                    handleClickWithDelay(async () => {
                      if (!stepsDisplay.acceptedSearch) {
                        if (optionsToShow.length === 0) {
                          Toastify(
                            "success",
                            "Todas as informações já estão preenchidas."
                          );
                          setClosing(true);
                          setTimeout(() => handleClosePopUp(), 300);
                        } else {
                          setTransitionClass("opacity-0");
                          setContentTransitionClass("opacity-0");
                          setTimeout(() => {
                            setStepsDisplay({
                              acceptedSearch: true,
                              actual: 0,
                              total: optionsToShow.length
                            });
                            setTransitionClass("opacity-100");
                            setContentTransitionClass("opacity-100");
                            setStartFormAnimation(true);
                            setTimeout(() => setStartFormAnimation(false), 300);
                          }, 300);
                        }
                      } else if (
                        stepsDisplay.actual === totalSteps - 1 ||
                        !currentOption
                      ) {
                        if (currentOption.type === "multiple") {
                          if (!answers[currentOption.value]) {
                            Toastify(
                              "error",
                              "Por favor, selecione uma opção antes de prosseguir."
                            );
                            return;
                          }
                        } else if (currentOption.type === "input") {
                          if (!answers[currentOption.value]) {
                            Toastify(
                              "error",
                              "Por favor, preencha o campo antes de prosseguir."
                            );
                            return;
                          }
                        }
                        setClosing(true);
                        setTimeout(() => handleClosePopUp(), 300);
                        let update = await putStudentBasicInfos(
                          studentInfos,
                          answers,
                          5
                        );
                        let localToken = await getStudentInfos();
                        localToken.completedCareerInfos = true;
                        const ciphertext = await CryptoService(
                          localToken,
                          "encrypt-crypto"
                        );
                        localStorage.setItem("user", ciphertext);
                        localStorage.removeItem("career");
                        if (update.status === 200) {
                          Toastify(
                            "success",
                            "Informações atualizadas com sucesso!"
                          );
                        } else {
                          Toastify("error", "Erro ao Atualizar.");
                        }
                      } else {
                        if (currentOption.type === "multiple") {
                          if (!answers[currentOption.value]) {
                            Toastify(
                              "error",
                              "Por favor, selecione uma opção antes de prosseguir."
                            );
                          } else {
                            handleStepTransition("next");
                          }
                        } else if (currentOption.type === "input") {
                          if (!answers[currentOption.value]) {
                            Toastify(
                              "error",
                              "Por favor, preencha o campo antes de prosseguir."
                            );
                          } else {
                            handleStepTransition("next");
                          }
                        }
                      }
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
