import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const getPreviewContract = async ({
  studentInfos,
  selectedCourseOption,
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: selectedCourseOption.matriculationId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/previewContract`,
      body,
      config
    );
    if (response.status !== 200) {
      return { status: response.status };
    }

    const responseData = await CryptoService(response.data, "decrypt-string");

    return {
      data: responseData,
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const getTimelineContract = async ({
  studentInfos,
  selectedCourseOption,
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: selectedCourseOption.matriculationId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getTimelineContract`,
      body,
      config
    );
    if (response.status !== 200) {
      return { status: response.status };
    }

    const responseData = await CryptoService(
      response.data.data,
      "decrypt-json"
    );

    return {
      data: responseData,
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const signContract = async ({ studentInfos, selectedCourseOption }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        matriculationId: selectedCourseOption.matriculationId,
        certifier,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/signContract`,
      body,
      config
    );
    if (response.status !== 200) {
      return { status: response.status };
    }

    return {
      data: "OK",
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};

export const downloadContracts = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_ADDRESS}/download/contract`;

    const post_data = await CryptoService(
      {
        matriculationId: data.matriculationId,
        courseId: data.courseId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const auth = await CryptoService(
      {
        sessionId: data.studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const options = { responseType: "blob", headers: { auth: auth } };
    const response = await axios.post(url, { token: post_data }, options);

    return { data: response.data, status: response.status };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};
