import React, { useEffect, useState } from "react";
import "./CoursesRecommended.css";
import RecommendedCard from "./RecommendedCard/RecommendedCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { certifier } from "../../mocks/certifier";

function CoursesRecommended({ courses, isAvailableCourses }) {
  return (
    <div className="w-full">
      <section className="swiper-cards-progress w-full flex flex-col gap-2">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-2/3 flex-col gap-0.5 -ml-1 max-lg:ml-0 max-lg:w-full">
            <span className="font-bold text-left leading-tight text-2xl max-lg:text-[20px]">
              Recomendados para você
            </span>
          </div>
          {courses.length > 3 && (
            <Link
              className="flex w-1/3 justify-end max-lg:hidden md:mr-2"
              to={`/recommendedCourses${
                isAvailableCourses ? `?typeCourse=${isAvailableCourses}` : ""
              }`}>
              <ButtonPrimary
                // optionalBackground={certifier.colors.text.textPrimary}
                textButton="ver todos"
                desktopWidth="35%"
                mobileWidth="100%"
                outlineType
                outlineColor={certifier.colors.text.textPrimary}
                optionalTextColor={certifier.colors.text.textPrimary}
                optionalClass="hoverWhiteBg"
              />
            </Link>
          )}
        </div>

        <Swiper
          className="w-full"
          slidesPerView={1.5}
          slidesPerGroup={1}
          style={{
            marginRight: "0px",
            "--swiper-navigation-color": "#fff",
            "--swiper-navigation-size": "30px",
            "--swiper-navigation-top-offset": "0%",
            "--swiper-navigation-sides-offset": "0px"
          }}
          modules={[Navigation]}
          navigation={false}
          spaceBetween={5}
          speed={450}
          breakpoints={{
            1025: {
              navigation: true,
              slidesPerView: 4,
              slidesPerGroup: 4,
              style: { marginRight: "-12px" }
            }
          }}>
          {courses.map((course, index) => (
            <SwiperSlide key={index} className="w-full">
              <RecommendedCard
                isAvailableCourses={isAvailableCourses}
                key={index}
                index={index}
                image={course.image}
                name={course.name}
                courseAlias={course.alias}
                typeCourse={course.typeCourse}
                type={course.typeCourse}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
}

export default CoursesRecommended;
