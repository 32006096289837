import React, { useState, useEffect } from "react";
import Input from "../Input/input";
import "./CreditCardForm.css";
import CreditCardObject from "./CreditCardObject";
import SelectElement from "../SelectElement/MainSelect";
import {
  HiMiniShieldCheck,
  HiMiniCurrencyDollar,
  HiMiniCreditCard,
  HiMiniUserCircle,
  HiCalendar,
  HiLockClosed,
} from "react-icons/hi2";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { postPaymentCertificate } from "../../services/certificateService/certificate.service";
import { postGenerateCardPayment } from "../../services/financeService/finance.service";
import { Toastify } from "../Toastify/Toastify";
import { getHashCard } from "../../services/cardService/card.service";
import LoadingSection from "../LoadingElements/LoadingSection";

const CreditCardForm = ({
  totalInstallments,
  financesId,
  type,
  totalValue,
  studentInfos,
  selectedCourseOption,
  method,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardLogo, setCardLogo] = useState("");
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvv: "",
    name: "",
    focus: "",
  });
  const [installmentData, setInstallmentData] = useState([]);
  const [selectedInstallmentData, setSelectedInstallmentData] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(0); // Estado para armazenar o mês atual
  const [currentYear, setCurrentYear] = useState(0); // Estado para armazenar o ano atual
  const [expiryError, setExpiryError] = useState(false); // Estado para controlar a exibição do erro de expiração
  const [updateCardtoFutureCharges, setUpdateCardtoFutureCharges] =
    useState(false);

  useEffect(() => {
    const currentDate = new Date();
    setCurrentMonth(currentDate.getMonth() + 1);
    setCurrentYear(currentDate.getFullYear() % 100);
  }, []);

  const handleCheckboxChange = () => {
    setUpdateCardtoFutureCharges(!updateCardtoFutureCharges);
  };

  useEffect(() => {
    setIsFormValid(
      Object.entries(state)
        .filter(([key]) => key !== "focus") // Exclui o campo "focus"
        .every(([, value]) => value.trim() !== "")
    );
  }, [state]);

  useEffect(() => {
    if (type === "finance") {
      let valueCourse = totalValue;
      let installmentArray = [];
      for (
        let installment = 1;
        installment <= totalInstallments;
        installment++
      ) {
        let installmentValue = (valueCourse / installment).toLocaleString(
          "pt-BR",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        );
        installmentArray.push({
          label: `${installment}x de R$${installmentValue}`,
          value: installment,
        });
      }

      setSelectedInstallmentData(installmentArray[installmentArray.length - 1]);
      setInstallmentData(installmentArray);
    } else if (type === "certificate") {
    }
  }, [totalInstallments]);

  const handleSelectInstallment = (value) => {
    setSelectedInstallmentData(value);
  };

  const paymentCertificate = async () => {
    try {
      setIsLoading(true);
      let card = {
        card_number: state.number.replace(/\D/g, ""), //
        card_holder_name: state.name,
        card_expiration_date: state.expiry.replace(/\D/g, ""),
        card_cvv: state.cvv.replace(/\D/g, ""),
      };

      let cardHash = await getHashCard(card);

      let financeCard = await postPaymentCertificate({
        studentInfos,
        data: {
          cardHash: cardHash.hash_card,
          selectId: financesId,
          value: totalValue * 100,
        },
        type: "creditCard",
      });
      if (financeCard.status === 200) {
        Toastify(
          "success",
          "Pagamento referente ao certificado efetuado com sucesso."
        );
        setTimeout(() => {
          return window.location.reload();
        }, 2000);
      } else if (financeCard.status === 420) {
        Toastify("error", "Falha ao efetuar pagamento. #1");
      } else {
        Toastify("error", "Error ao efetuar pagamento. #1");
      }
    } catch (err) {
      setIsLoading(false);
    } finally {
      setState({
        number: "",
        expiry: "",
        cvv: "",
        name: "",
        focus: "",
      });
      setIsLoading(false);
    }
  };

  const paymentFinance = async () => {
    try {
      setIsLoading(true);
      let card = {
        card_number: state.number.replace(/\D/g, ""), //
        card_holder_name: state.name,
        card_expiration_date: state.expiry.replace(/\D/g, ""),
        card_cvv: state.cvv.replace(/\D/g, ""),
      };

      let cardHash = await getHashCard(card);

      if (type === "finance") {
        let financeCard = await postGenerateCardPayment({
          sessionId: studentInfos.sessionId,
          matriculationId: selectedCourseOption.matriculationId,
          financesId: financesId,
          updateCardtoFutureCharges: updateCardtoFutureCharges,
          cardHash: cardHash.hash_card,
          installmentSelected: selectedInstallmentData.value,
        });
        if (financeCard.status === 200) {
          Toastify("success", "Pagamento efetuado com sucesso.");
          setTimeout(() => {
            return window.location.reload();
          }, 2000);
        } else if (financeCard.status === 420) {
          Toastify("error", "Falha ao efetuar pagamento.");
        } else {
          Toastify("error", "Error ao efetuar pagamento.");
        }
      }
    } catch (err) {
      setIsLoading(false);
    } finally {
      setState({
        number: "",
        expiry: "",
        cvv: "",
        name: "",
        focus: "",
      });
      setIsLoading(false);
    }
  };

  const paymentCard = async () => {
    if (type === "finance") {
      paymentFinance();
    } else if (type === "certificate") {
      paymentCertificate();
    }
  };

  const applyMask = (cardValue, mask) => {
    let i = 0;
    const formattedNumber = mask
      .replace(/#/g, (_) => cardValue[i++] || "#")
      .replaceAll("#", "")
      .trim();
    return formattedNumber;
  };

  const valuePTBR = (value) => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const onNumberChange = (value) => {
    let cardValue = value.replace(/\s/g, "");
    let mask = "#### #### #### ####";

    if (cardValue[0] === "5" || cardValue.substring(0, 4) === "4444") {
      setCardLogo("mastercard");
      mask = "#### #### #### ####";
    }

    if (cardValue[0] === "4") {
      setCardLogo("visa");
      mask = "#### #### #### ####";
    }

    if (
      cardValue.substring(0, 2) === "34" ||
      cardValue.substring(0, 2) === "37" ||
      cardValue.substring(0, 2) === "33"
    ) {
      setCardLogo("amex");
      mask = "#### ###### ####";
    }

    if (
      cardValue.substring(0, 6) === "636368" ||
      cardValue.substring(0, 6) === "438935" ||
      cardValue.substring(0, 6) === "504175" ||
      cardValue.substring(0, 6) === "451416" ||
      cardValue.substring(0, 6) === "509048" ||
      cardValue.substring(0, 6) === "509067" ||
      cardValue.substring(0, 6) === "509049" ||
      cardValue.substring(0, 6) === "509069" ||
      cardValue.substring(0, 6) === "509050" ||
      cardValue.substring(0, 6) === "509074" ||
      cardValue.substring(0, 6) === "509068" ||
      cardValue.substring(0, 6) === "509040" ||
      cardValue.substring(0, 6) === "509045" ||
      cardValue.substring(0, 6) === "509051" ||
      cardValue.substring(0, 6) === "509046" ||
      cardValue.substring(0, 6) === "509066" ||
      cardValue.substring(0, 6) === "509047" ||
      cardValue.substring(0, 6) === "509042" ||
      cardValue.substring(0, 6) === "509052" ||
      cardValue.substring(0, 6) === "509043" ||
      cardValue.substring(0, 6) === "509064" ||
      cardValue.substring(0, 6) === "509064" ||
      cardValue.substring(0, 5) === "36297" ||
      cardValue.substring(0, 4) === "5067" ||
      cardValue.substring(0, 4) === "4576" ||
      cardValue.substring(0, 4) === "4011"
    ) {
      setCardLogo("elo");
      mask = "#### #### #### ####";
    }

    if (
      cardValue.substring(0, 2) === "38" ||
      cardValue.substring(0, 2) === "60"
    ) {
      setCardLogo("hipercard");
      mask = "#### #### #### ####";
    }

    if (cardValue.substring(0, 2) === "35") {
      setCardLogo("jcb");
      mask = "#### #### #### ####";
    }

    if (
      cardValue.substring(0, 4) === "6011" ||
      cardValue.substring(0, 3) === "622" ||
      cardValue.substring(0, 2) === "64" ||
      cardValue.substring(0, 2) === "65"
    ) {
      setCardLogo("discover");
      mask = "#### #### #### ####";
    }
    if (
      cardValue.substring(0, 3) === "301" ||
      cardValue.substring(0, 3) === "305" ||
      cardValue.substring(0, 2) === "36"
    ) {
      setCardLogo("diners");
      // mask = ("#### ###### ####||#### #### #### ####")
      mask = "#### ###### ####";
      // mask = ("#### #### #### ####")
    }

    if (cardValue.length === 0) {
      mask = "#### #### #### ####";
    }
    let formattedValue = applyMask(cardValue, mask);
    setState((prev) => ({ ...prev, number: formattedValue }));
    return formattedValue;
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    let formattedValue = value;
    switch (name) {
      case "number":
        formattedValue = onNumberChange(value.replace(/\D/g, ""));
        break;
      // case "name":
      //   formattedValue = value.replace(/[^a-zA-Z^. ]/g, "");
      //   break;
      case "expiry":
        // Formatar o valor de entrada do mês de expiração
        if (value.length <= 2) {
          formattedValue = value.replace(/\D/g, "");
        } else {
          formattedValue = value
            .replace(/\D/g, "")
            .replace(/(.{2})/, "$1/")
            .substr(0, 5);
        }

        const [expiryMonth, expiryYear] = formattedValue.split("/");

        if (
          parseInt(expiryYear) < currentYear ||
          (parseInt(expiryYear) === currentYear &&
            parseInt(expiryMonth) < currentMonth) ||
          parseInt(expiryMonth) > 12
        ) {
          setExpiryError(true);
        } else {
          setExpiryError(false);
        }

        break;
      case "cvv":
        formattedValue = value.replace(/\D/g, "");
        if (formattedValue.length > 4) {
          formattedValue = formattedValue.substr(0, 4);
        }
        break;
      default:
        break;
    }
    setState((prev) => ({ ...prev, [name]: formattedValue }));
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <div className="flex flex-col gap-y-6 w-full">
      <LoadingSection isLoading={isLoading} />

      <CreditCardObject
        number={state.number}
        name={state.name}
        expiry={state.expiry}
        cvv={state.cvv}
        focused={state.focus}
        preview={true}
        locale={{ valid: "Validade" }}
        placeholders={{ name: "NOME IGUAL AO CARTÃO" }}
        logo={cardLogo}
      />
      <div className="flex flex-col gap-y-4">
        {type === "finance" && (
          <div className="flex flex-col gap-y-3">
            <label className="span-small-white">Parcelamento no Cartão</label>
            <SelectElement
              disableGroups
              options={installmentData}
              value={selectedInstallmentData.value}
              onChange={handleSelectInstallment}
            />
          </div>
        )}
        {type === "certificate" && (
          <div className="flex flex-col gap-y-3">
            <label className="span-small-white">Valor Total</label>
            <Input
              type="select"
              name="number"
              value={`R$ ${valuePTBR(totalValue)}`}
              disabled={true}
              optionalIcon={<HiMiniCurrencyDollar />}
            />
          </div>
        )}
        <div className="flex flex-col gap-y-3">
          <label className="span-small-white">Número do Cartão</label>
          <Input
            type="text"
            name="number"
            value={state.number}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            optionalIcon={<HiMiniCreditCard />}
          />
        </div>
        <div className="flex flex-col gap-y-3">
          <label className="span-small-white">Titular do Cartão</label>
          <Input
            type="text"
            name="name"
            value={state.name}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            optionalIcon={<HiMiniUserCircle />}
          />
        </div>
        <div className="flex gap-5">
          <div className="flex flex-col gap-y-3">
            <label className="span-small-white">Validade</label>
            <Input
              type="text"
              name="expiry"
              value={state.expiry}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              optionalIcon={<HiCalendar />}
            />
            {expiryError ? (
              <span className="text-xs text-left leading-tight text-red-500">
                A data inválida.
              </span>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-3">
            <label className="span-small-white">CVV</label>
            <Input
              type="text"
              name="cvv"
              value={state.cvv}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              optionalIcon={<HiLockClosed />}
            />
            {expiryError ? (
              <span className="text-xs text-left leading-tigh">&nbsp;</span>
            ) : null}
          </div>
        </div>
        {type === "finance" && method === "Recorrente" && (
          <label class="inline-flex items-center cursor-pointer mt-4">
            <input
              type="checkbox"
              checked={updateCardtoFutureCharges}
              onChange={handleCheckboxChange}
              className="sr-only"
              class="sr-only peer"
            />
            <div class="relative w-9 h-4 rounded-full peer dark:bg-backgroundTwo peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[-4px] after:start-[-7px] after:bg-white after:peer-checked:bg-themeColor after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:transition-all peer-checked:bg-[#f675a9]"></div>
            <span class="ms-4 text-sm">Salvar para cobranças futuras.</span>
          </label>
        )}
        <div className="flex mt-4 flex-col gap-y-4">
          <ButtonPrimary
            optionalBackground="#08bf4f"
            optionalTextColor="#FFF"
            optionalMovingIcon={<HiMiniShieldCheck />}
            disableBorderRadius
            desktopPadding="16px"
            mobilePadding="16px"
            textButton="Efetuar Pagamento"
            disabled={!isFormValid || expiryError}
            verifyOnClick={() => {
              paymentCard();
            }}
          />
          <span className="text-[10px] pb-4">
            Ambiente criptografado e 100% seguro.
          </span>
          <img src="/images/assets/CompraSegura.webp" />
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
