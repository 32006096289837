import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ClassContentSection from "../../components/ClassContentSection/ClassContentSection";
import { Toastify } from "../../components/Toastify/Toastify";
import { useLocation, useParams } from "react-router-dom";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import {
  getClassFromDemoCourse,
  getDisciplineAndClasses
} from "../../services/disciplineService/discipline.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import { getStartAvaliationClassPage } from "../../services/avaliationServices/avaliation.service";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";
import {
  getAllStudentCourses,
  updateRateCourse
} from "../../services/courseService/course.service";
import { getNextStepsRequest } from "../../services/dashboardService/dashboard.service";

function ClassPageComponent({ demoCourse }) {
  const location = useLocation();
  const [classContent, setClassContent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const classId = useParams().classId;
  const disciplineId = useParams().disciplineId;
  const courseAlias = useParams().courseAlias;
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [avaliationModal, setAvaliationModal] = useState(undefined);
  const [rateCourseBox, setRateCourseBox] = useState(undefined);
  const [starsState, setStarsState] = useState(-1);
  const [starsHoverState, setStarsHoverState] = useState(-1);
  const [starAnimationTime, setStarAnimationTime] = useState(undefined);

  useEffect(() => {
    const fetchClassPageInfo = async () => {
      setIsLoading(true);
      try {
        if (demoCourse) {
          const student = await getStudentInfos();

          const arrayNextSteps = await getNextStepsRequest({
            sessionId: student.sessionId
          });
          if (arrayNextSteps.status !== 200) {
            Toastify("error", "Erro ao buscar informações de steps.");
            console.error(
              "Erro ao buscar informações de steps - 9:",
              arrayNextSteps
            );
          }
          try {
            if (arrayNextSteps.data !== undefined) {
              const nextStep = arrayNextSteps.data.find(
                (item) => item.status === true
              );
              if (!(nextStep && nextStep.type === "availableCourse"))
                window.location.href = `/dashboard?message=${encodeURI(
                  "Você não possui cursos disponíveis para resgate."
                )}`;
            }
          } catch {}

          const arrayStudentCourses = await getAllStudentCourses({
            sessionId: student.sessionId
          });
          let allStudentCoursesArray = [];
          if (arrayStudentCourses.status !== 200)
            window.location.href = `/dashboard?message=${encodeURI(
              "Erro ao buscar informações de curso demonstrativo."
            )}`;
          else {
            if (
              arrayStudentCourses.data.coursesPosGraduation &&
              arrayStudentCourses.data.coursesPosGraduation.length > 0
            ) {
              allStudentCoursesArray = allStudentCoursesArray.concat(
                arrayStudentCourses.data.coursesPosGraduation
              );
            }
            if (
              arrayStudentCourses.data.extraCourses &&
              arrayStudentCourses.data.extraCourses.length > 0
            ) {
              allStudentCoursesArray = allStudentCoursesArray.concat(
                arrayStudentCourses.data.extraCourses
              );
            }
          }

          let studentHasSpecifiedWord = false;
          let specifiedWord = "estudos";

          allStudentCoursesArray.find((course) => {
            if (course.alias.includes(specifiedWord)) {
              studentHasSpecifiedWord = true;
            }
          });

          if (!studentHasSpecifiedWord && courseAlias.includes(specifiedWord)) {
            window.location.href = `/dashboard?message=${encodeURI(
              "Procure o atendimento para a liberação do curso."
            )}`;
          }

          if (
            allStudentCoursesArray.find(
              (course) => course.alias === courseAlias
            )
          )
            window.location.href = `/dashboard?message=${encodeURI(
              "Você já possui acesso a este curso."
            )}`;

          const response = await getClassFromDemoCourse({
            courseAlias: courseAlias
          });
          if (response.status !== 200) {
            Toastify("error", "Erro ao buscar informações de demonstração.");
            console.error(
              "Erro ao buscar informações de demonstração - 1:",
              response.status
            );
          }
          try {
            setClassContent(response.data);
          } catch {
            Toastify("error", "Erro ao processar itens da demonstração: #01");
          }
        } else {
          const student = await getStudentInfos();
          // getDisciplineAndClasses --- START
          const arrayDiscipline = await getDisciplineAndClasses({
            sessionId: student.sessionId,
            classId: classId,
            courseAlias: courseAlias,
            disciplineId: disciplineId
          });
          if (arrayDiscipline.status !== 200) {
            Toastify("error", "Erro ao buscar informações de disciplina.");
            console.error(
              "Erro ao buscar informações de disciplina - 1:",
              arrayDiscipline.status
            );
          }
          // getDisciplineAndClasses --- END
          try {
            setClassContent(arrayDiscipline.data);
            setRateCourseBox(
              !arrayDiscipline.data.otherEvaluation &&
                arrayDiscipline.data.currentClass.lastVideoClass
            );
          } catch {
            Toastify("error", "Erro ao processar itens da disciplina: #01");
          }
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar disciplina.");
        window.location.href = "/";
      } finally {
        setIsLoading(false);
      }
    };
    fetchClassPageInfo();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    getStudentInfos()
      .then(async (response) => {
        setStudentInfos(response);
      })
      .catch((error) => {
        Toastify("error", "Erro ao buscar informações do aluno.");
      });
  }, [location, classId]);

  const handleActiveLoadingObj = () => {
    setIsLoading(!isLoading);
  };

  const handleStartAvaliation = async () => {
    try {
      if (!studentInfos)
        return Toastify("error", "Erro ao iniciar avaliação. #1");
      setIsLoading(true);
      const avaliationData = {
        sessionId: studentInfos.sessionId,
        courseId: classContent.courseId,
        disciplineId
      };
      const avaliationResponse = await getStartAvaliationClassPage(
        avaliationData
      );
      if (avaliationResponse.status === 200) {
        const avaliationData = {
          sessionId: studentInfos.sessionId,
          matriculationId: classContent.matriculationId,
          courseId: classContent.courseId,
          courseAlias: courseAlias,
          disciplineId: classContent.disciplineMongoId,
          avaliationType: "avo"
        };
        const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
        window.location.href = `/avaliation?key=${encodeURIComponent(
          String(cryptParam)
        )}`;
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Toastify("error", "Erro ao iniciar avaliação.");
      }
    } catch (err) {
      setIsLoading(false);
      Toastify("error", "Erro ao iniciar avaliação. #2");
    }
  };

  const handleDirectAvaliation = async (item) => {
    if (!studentInfos)
      return Toastify("error", "Erro ao iniciar avaliação direta.");
    const avaliationData = {
      sessionId: studentInfos.sessionId,
      avaliationId: item.avaliationId,
      isViewAvaliation: true
    };
    const cryptParam = await CryptoService(avaliationData, "encrypt-jwt");
    window.location.href = `/avaliation?key=${encodeURIComponent(
      String(cryptParam)
    )}`;
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        hiddenHeaderOnLG
        actualPage="Aulas"
        courseNavigation={false}
        mobileBackPage={`/course/${courseAlias}`}
        disableBlurBackground
      />
      <MobileNavigationBar actualPage="Aulas" />
      <LoadingSection isLoading={isLoading} hideScroll />
      <div className="w-full px-14 max-lg:px-0">
        <div className="flex gap-10 w-full h-full pb-9 max-lg:pb-0">
          {classContent && (
            <ClassContentSection
              demoCourse={demoCourse}
              studentInfos={studentInfos}
              classContent={classContent}
              activeLoadingObj={handleActiveLoadingObj}
              handleSetAvaliationModal={setAvaliationModal}
            />
          )}
        </div>
      </div>
      {avaliationModal && (
        <BlockquoteModal
          text_top="Realizar avaliação"
          text_blockquote={
            avaliationModal.situation !== "Em andamento"
              ? `A prova terá uma duração de ${String(
                  avaliationModal.duration / 60
                ).replace(".", ",")} hora${
                  avaliationModal.duration / 60 >= 2 ? "s" : ""
                }. Além disso, você terá ${String(
                  avaliationModal.retries - avaliationModal.tries
                )} tentativa${
                  avaliationModal.retries >= 2 ? "(s)" : ""
                } para realizar a avaliação. Está pronto para iniciar?`
              : "Você tem certeza de que deseja dar continuidade a esta avaliação?"
          }
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsLeft={
            (avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
            !avaliationModal.fake
              ? [
                  <ButtonPrimary
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Ver avaliação"
                    desktopWidth="160px"
                    mobileWidth="100%"
                    verifyOnClick={() =>
                      handleDirectAvaliation(avaliationModal)
                    }
                  />
                ]
              : []
          }
          buttonsRight={
            ((avaliationModal.situation === "Reprovado" ||
              avaliationModal.situation === "Aprovado") &&
              avaliationModal.tries < avaliationModal.retries) ||
            avaliationModal.situation === "Disponível" ||
            avaliationModal.situation === "Em andamento"
              ? [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    optionalHoverBackground="#FFFFFF08"
                    optionalBackground="#00000000"
                    textButton="Cancelar"
                    optionalTextColor={certifier.colors.text.textPrimary}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />,
                  <ButtonPrimary
                    verifyOnClick={handleStartAvaliation}
                    textButton={`${
                      avaliationModal.situation === "Em andamento"
                        ? "Continuar"
                        : "Começar"
                    }`}
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />
                ]
              : [
                  <ButtonPrimary
                    verifyOnClick={() => setAvaliationModal(undefined)}
                    disableHorizontalMovement
                    textButton="Voltar"
                    desktopWidth="160px"
                    mobileWidth="100%"
                  />
                ]
          }
        />
      )}

      {typeof rateCourseBox !== "undefined" && (
        <section
          className={`fixed top-0 left-0 z-[199] transition-300 ${
            !rateCourseBox && "opacity-0 pointer-events-none"
          }`}>
          <BlockquoteModal
            blockquoteClassName="max-w-[500px] max-md:max-w-[100%]"
            disable_blockquoteMode
            text_top="Avaliação de qualidade do curso"
            text_blockquote="Compartilhe com a gente como foi sua experiencia com nosso conteúdo."
            innerObject={
              <div className="flex flex-col gap-y-5 items-center justify-center">
                <div className={`py-1 px-2 rounded-full`}>
                  <div className="flex h-full gap-x-8 items-center justify-center max-md:gap-x-6">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div className="transition-300 hover:scale-[1.15]">
                        <div
                          className={`${
                            starAnimationTime === index + 1 &&
                            "translate-y-[4px] translate-x-[1px] transition-300"
                          }`}>
                          <div
                            className={`origin-center w-[42px] h-[42px] cursor-pointer ${
                              starAnimationTime === index + 1 &&
                              "rotate-[144deg] transition-300"
                            } max-md:w-[36px] max-md:h-[36px]`}
                            onMouseLeave={() => setStarsHoverState(0)}
                            onMouseEnter={() => setStarsHoverState(index + 1)}
                            onClick={async () => {
                              setStarsState(index + 1);
                              setStarAnimationTime(index + 1);
                            }}>
                            <img
                              src="/images/assets/star_empty.svg"
                              className={`select-none absolute w-[42px] h-[42px] max-md:w-[36px] max-md:h-[36px] transition-600 ${
                                starsState <= index
                                  ? "opacity-100"
                                  : "opacity-100"
                              }`}
                            />
                            <img
                              src="/images/assets/star_filled.svg"
                              className={`select-none absolute w-[42px] h-[42px] max-md:w-[36px] max-md:h-[36px] transition-600 ${
                                starsState > index
                                  ? "opacity-100"
                                  : starsHoverState > index
                                  ? "opacity-100"
                                  : "opacity-0"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
            outsideClick={() => setRateCourseBox(false)}
            buttonsRight={[
              <ButtonPrimary
                verifyOnClick={() => setRateCourseBox(false)}
                disableHorizontalMovement
                optionalHoverBackground="#FFFFFF08"
                optionalBackground="#00000000"
                textButton="Cancelar"
                optionalTextColor={certifier.colors.text.textPrimary}
                desktopWidth="160px"
                mobileWidth="100%"
              />,
              <ButtonPrimary
                verifyOnClick={async () => {
                  setRateCourseBox(false);
                  await updateRateCourse({
                    sessionId: studentInfos.sessionId,
                    content: {
                      courseId: classContent.courseId,
                      courseAlias,
                      quantityStars: starsState
                    }
                  });
                  Toastify("success", "Avaliação enviada com sucesso!");
                }}
                textButton="Confirmar"
                disabled={starsState <= 0}
                desktopWidth="160px"
                mobileWidth="100%"
              />
            ]}
          />
        </section>
      )}
    </main>
  );
}

const WrappedClassPageComponent = verifyAuth(ClassPageComponent);

const ClassPage = (props) =>
  props.demoCourse ? (
    <ClassPageComponent {...props} />
  ) : (
    <WrappedClassPageComponent {...props} />
  );

export default ClassPage;
