import React from "react";
import { certifier } from "../../mocks/certifier";

export default function StepsList({
  steps,
  height,
  gapDistance,
  activatedBg,
  className,
}) {
  return (
    <div
      className="flex flex-col relative"
      style={{ rowGap: gapDistance ? `${gapDistance}px` : "32px" }}
    >
      {steps.map((step, index) => (
        <div key={index} className={`flex relative ${className}`}>
          <div
            className={`w-9 h-9 min-w-9 min-h-9 rounded-full mr-3 z-[1] border-2 relative flex items-center justify-center text-base font-bold select-none ${
              step.warning
                ? "border-stepReproved text-stepsIconAndTextReproved"
                : step.activated
                ? "bg-themeColor border-themeColor"
                : "bg-[#64758b] border-[#64758b]"
            }`}
            style={{
              backgroundColor: step.warning
                ? certifier.colors.steps.stepReproved
                : activatedBg
                ? step.activated
                  ? activatedBg
                  : `${activatedBg}80`
                : "",
              borderColor: step.warning
                ? certifier.colors.steps.stepReproved
                : activatedBg && "#00000000",
              color: activatedBg
                ? step.activated
                  ? "#FFF"
                  : `#FFFFFF80`
                : step.warning
                ? ""
                : certifier.colors.steps.iconAndText,
            }}
          >
            {step.icon ? step.icon : index + 1}
          </div>
          {step.content}
          {index < steps.length - 1 && (
            <div
              key={`stick-${index}`}
              name="STEP-STICK"
              className={`absolute top-0 left-[17px] w-0.5 mt-9  ${
                steps[index + 1].activated && step.activated
                  ? "bg-themeColor"
                  : "bg-[#64758b]"
              }`}
              style={{
                backgroundColor: activatedBg
                  ? steps[index + 1].activated && step.activated
                    ? activatedBg
                    : `${activatedBg}80`
                  : "",
                height: `100%`,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
}
