import React, { useEffect, useRef, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SelectElement from "../../components/SelectElement/MainSelect";
import StepsList from "../../components/StepsList/StepsList";
import DocumentViewer from "../../components/DocumentViewer/DocumentViewer";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { HiPencil, HiMiniArrowDownTray, HiMiniCheck } from "react-icons/hi2";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { getListStudentCourses } from "../../services/courseService/course.service";
import { Toastify } from "../../components/Toastify/Toastify";
import Cookies from "js-cookie";
import {
  getPreviewContract,
  signContract,
  getTimelineContract,
  downloadContracts
} from "../../services/contractService/contract.service";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import moment from "moment";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import "./contract.css";
import { certifier } from "../../mocks/certifier";
// import * as Sentry from "@sentry/react";

function ContractPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCourseOption, setSelectedCourseOption] = useState({});
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [studentInfos, setStudentInfos] = useState(undefined);
  const [previewContract, setPreviewContract] = useState(undefined);
  const [timelineContract, setTimelineContract] = useState([]);
  const [contractGroups, setContractGroups] = useState([]);
  const [contractModal, setcontractModal] = useState(undefined);
  const [emailModal, setEmailModal] = useState(undefined);
  const [mobileScreen, setMobileScreen] = useState(1);
  const [windowWidth, setWindowWidth] = useState(0);
  const documentViewerRef = useRef(null);
  const [navBar, setNavBar] = useState(0);
  const [contract, setContract] = useState({
    RA: "",
    index: "",
    contractSign: false,
    version: "",
    student: {},
    studentId: ""
  });
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    async function fetchStudentCourses() {
      setIsLoading(true);
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        if (!student.completed) window.location.href = `/user/basicInfos`;
        setStudentInfos(student);
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId
        });

        if (coursesResponse.status === 200) {
          const allCourses = coursesResponse.data.map((course) => ({
            label: course.courseName,
            value: course.courseAlias,
            courseId: course.courseId,
            matriculationId: course.matriculationId,
            typeCourse: course.typeCourse
          }));
          setCoursesOptions(allCourses);
          if (allCourses.length > 0) {
            const lastCourseAccessed = Cookies.get(
              "lastCourseAccessed_contract"
            );
            if (lastCourseAccessed) {
              const lastCourse = allCourses.find(
                (course) => course.value === lastCourseAccessed
              );
              if (lastCourse) {
                setSelectedCourseOption(lastCourse);
                await renewContractContent(allCourses);
              } else {
                setSelectedCourseOption(allCourses[0]);
                await renewContractContent(allCourses);
              }
            } else {
              setSelectedCourseOption(allCourses[0]);
              await renewContractContent(allCourses);
            }
          }
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos no contrato.");
      }
      setIsLoading(false);
    }

    fetchStudentCourses();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSelectCourseChange = (e) => {
    setMobileScreen(1);
    setSelectedCourseOption(e);
    Cookies.set("lastCourseAccessed_contract", e.value, {
      expires: 1 / 24
    });
  };

  async function renewContractContent(array) {
    try {
      const contractGroups = array.reduce((group, matriculation) => {
        const matriculationNew = matriculation.matriculationId;
        if (!group[matriculationNew]) {
          group[matriculationNew] = [];
        }
        group[matriculationNew].push(matriculation);
        return group;
      }, {});
      setContractGroups(contractGroups);
    } catch (error) {
      Toastify(
        "error",
        "Erro ao gerar o contrato. Tente novamente mais tarde."
      );
    }
  }

  const downloadContract = async (data) => {
    setIsLoading(true);
    try {
      let response = await downloadContracts(data);
      setIsLoading(false);
      if (response.status === 200) {
        const tempLink = document.createElement("a");
        tempLink.href = URL.createObjectURL(response.data);
        tempLink.setAttribute("download", `${String(data.name)}.pdf`);
        tempLink.click();
      } else {
        console.error("Erro ao baixar o contrato");
        Toastify(
          "error",
          "Erro ao baixar o conteúdo. Tente novamente mais tarde."
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Erro ao baixar o contrato", error);
      Toastify(
        "error",
        "Erro ao baixar o conteúdo. Tente novamente mais tarde."
      );
    }
  };

  useEffect(() => {
    async function callHandleContract() {
      if (selectedCourseOption && studentInfos) {
        setIsLoading(true);
        await handleContract();
        setIsLoading(false);
      }
    }
    callHandleContract();
    // eslint-disable-next-line
  }, [selectedCourseOption]);

  async function handleContract() {
    try {
      if (selectedCourseOption.matriculationId && studentInfos) {
        const previewContractData = {
          studentInfos,
          selectedCourseOption
        };
        const previewContractContent = await getPreviewContract(
          previewContractData
        );
        if (previewContractContent.status === 200) {
          setPreviewContract(previewContractContent.data);
        }
        const TimelineContract = await getTimelineContract(previewContractData);
        if (TimelineContract.status === 200) {
          let matriculationData = {
            index: TimelineContract.data.indexMatriculation,
            RA: TimelineContract.data.studentRa,
            contractSign: TimelineContract.data.contractSign,
            version: TimelineContract.data.version,
            studentId: TimelineContract.data.studentId,
            student: TimelineContract.data.student
          };
          if (documentViewerRef && documentViewerRef.current) {
            documentViewerRef.current.scrollTop = 0;
          }
          setContract(matriculationData);
          let dataContract = [];
          for (const step of TimelineContract.data.timeline) {
            try {
              if (step.sign) {
                dataContract.push({
                  content: (
                    <div className="flex flex-col gap-1.5">
                      <span className="font-semibold text-sm">
                        {step.title}
                      </span>
                      <span className="text-xs text-textSecondary">
                        {step.description}
                      </span>
                    </div>
                  ),
                  activated: step.sign,
                  icon: <HiMiniCheck className="text-xl" />
                });
              } else {
                dataContract.push({
                  content: (
                    <div className="flex flex-col gap-1.5">
                      <span className="font-semibold text-sm">
                        {step.title}
                      </span>
                      <span className="text-xs text-textSecondary">
                        {step.description}
                      </span>
                    </div>
                  ),
                  activated: step.sign
                });
              }
            } catch (err) {
              Toastify("error", "Erro nos steps do contrato.");
            }
          }
          setTimelineContract(dataContract);
        }
      }
    } catch (err) {
      Toastify("error", "Erro ao buscar na área de contratos.");
      console.error("Erro ao buscar na área de contratos.", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSignContract() {
    if (selectedCourseOption && studentInfos) {
      const previewContractData = {
        studentInfos,
        selectedCourseOption
      };
      const previewContractContent = await signContract(previewContractData);

      if (previewContractContent.status === 200) {
        Toastify("success", "Email enviado com sucesso!");
      }
    }
  }

  function formatCPF(cpf) {
    cpf = String(cpf);
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  const handleOpenInfo = () => {
    // const mobileScreenNum = mobileScreen;
    // if (mobileScreenNum === 1) setMobileScreen(2);
    // if (mobileScreenNum === 2) setMobileScreen(1);
    setMobileScreen(mobileScreen === 1 ? 2 : 1);
  };

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne min-h-screen items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        actualPage="Documentos"
        mobileMainText="Contrato"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Documentos" />
      <LoadingSection isLoading={isLoading} />
      <section
        style={{
          paddingTop: windowWidth < 960 ? navBarHeader : 0,
          paddingBottom: windowWidth < 960 ? navBar : 0
        }}
        className={`overflow-hidden flex w-full justify-start mt-9 mb-9 max-w-[1170px] h-[85vh] rounded-xl max-mc:mt-0 max-mc:mb-0 max-mc:flex-col max-mc:rounded-none`}>
        <div
          className={`px-4 pt-4 bg-backgroundTwo ${
            window.innerWidth < 960 ? "w-[100%]" : "w-[62%]"
          }`}>
          <div
            className={`max-h-[auto] w-full ${
              windowWidth < 960 ? "h-[80vh]" : "h-full"
            }`}>
            {previewContract && contract && (
              <DocumentViewer
                className="w-full h-full"
                ref={documentViewerRef}
                blockedStatus={false}
                src={previewContract}
                bottomButton={
                  !contract?.contractSign ? (
                    <ButtonPrimary
                      optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
                      textButton="Iniciar Assinatura"
                      desktopWidth="200px"
                      mobileWidth="40%"
                      optionalMovingIcon={<HiPencil className="text-[1rem]" />}
                      verifyOnClick={() => setcontractModal(contract?.student)}
                      disabled={studentInfos?.userTeste}
                    />
                  ) : (
                    <ButtonPrimary
                      optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
                      textButton="Baixar documento"
                      desktopWidth="200px"
                      mobileWidth="100%"
                      optionalMovingIcon={
                        <HiMiniArrowDownTray className="text-[1rem]" />
                      }
                      verifyOnClick={() =>
                        downloadContract({
                          matriculationId: selectedCourseOption.matriculationId,
                          courseId: selectedCourseOption.courseId,
                          name: `Contrato ${certifier.generalSettings.shortName} - ${contract?.student.name}`,
                          studentInfos
                        })
                      }
                    />
                  )
                }
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingBottom: windowWidth < 960 ? navBar * 2 + 10 : "4rem"
          }}
          className={`${
            windowWidth < 960 && mobileScreen === 1
              ? "transition-all duration-300 absolute p-6 w-[85%] justify-start overflow-x-hidden z-[101] -translate-x-[100%]"
              : windowWidth < 960 && mobileScreen === 2
              ? "transition-all duration-300 absolute p-6 w-[85%] justify-start overflow-x-hidden z-[101]"
              : "p-16 w-[38%]"
          } h-full flex flex-col gap-y-8 m-0 overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background bg-backgroundThree`}>
          <div>
            <h1 className="text-3xl text-textPrimary font-bold leading-none">
              Contrato
            </h1>
          </div>
          {coursesOptions.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <label className="span-small-white">Selecione o curso</label>
              <SelectElement
                options={coursesOptions}
                value={selectedCourseOption.value}
                onChange={handleSelectCourseChange}
              />
            </div>
          )}
          {contractGroups &&
            contract &&
            selectedCourseOption.matriculationId && (
              <span className="text-textPrimary text-sm">
                Documento referente aos cursos de{" "}
                <b>
                  {contractGroups[selectedCourseOption.matriculationId]
                    ?.map((course) => course.label)
                    .join(", ")}
                </b>{" "}
                da matrícula {contract.RA}-{contract.index}
              </span>
            )}

          {contract?.contractSign ? (
            <span className="text-textPrimary text-sm leading-none flex items-center gap-x-2">
              <div className="w-[10px] h-[10px] rounded-full bg-themeColor"></div>
              Assinado
            </span>
          ) : (
            <span className="text-textPrimary text-sm leading-none flex items-center gap-x-2">
              <div className="w-[10px] h-[10px] rounded-full bg-[#9d9e9e]"></div>
              Não assinado
            </span>
          )}

          <StepsList steps={timelineContract} />
        </div>
      </section>
      <div
        style={{
          marginBottom: `${window.innerWidth <= 960 ? navBar : 0}px`,
          height: `${window.innerWidth <= 960 ? navBar : 0}px`
        }}
        className="hidden max-mc:flex z-[102] fixed bottom-0 gap-5 bg-backgroundThree justify-center items-center w-full">
        <div className="w-[40px] flex justify-center items-center absolute left-0 pl-[10vw]">
          <div
            className={`bar ${mobileScreen === 2 && "checked"}`}
            onClick={handleOpenInfo}>
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
          </div>
        </div>

        {contract?.contractSign ? (
          <ButtonPrimary
            optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
            textButton="Baixar documento"
            desktopWidth="200px"
            mobileWidth={180}
            optionalMovingIcon={<HiMiniArrowDownTray className="text-[1rem]" />}
            verifyOnClick={() =>
              downloadContract({
                matriculationId: selectedCourseOption.matriculationId,
                courseId: selectedCourseOption.courseId,
                name: `Contrato ${certifier.generalSettings.shortName} - ${contract.student.name}`,
                studentInfos
              })
            }
          />
        ) : (
          <ButtonPrimary
            optionalDisabledColor={`${certifier.colors.background.backgroundTwo}B3"`}
            textButton="Iniciar Assinatura"
            desktopWidth="200px"
            mobileWidth={150}
            optionalMovingIcon={<HiPencil className="text-[1rem]" />}
            verifyOnClick={() => setcontractModal(contract?.student)}
            disabled={studentInfos?.userTeste}
          />
        )}
      </div>
      {/* Modal */}
      {contractModal && contract && studentInfos && (
        <BlockquoteModal
          activeCloseButton
          text_top="Confira seus dados para assinar"
          text_blockquote="Você irá assinar esse documento como:"
          outsideClick={() => setcontractModal(undefined)}
          innerHTML={`
              <div class="p-2">
                <span class="font-bold text-sm">Nome Completo</span>
                <div class="p-2 text-sm">${contract.student.name}</div>
              </div>

              <div class="flex max-[472px]:flex-col">
                <div class="w-full p-2">
                  <span class="font-bold text-sm">CPF: </span>
                  <div class="p-2 text-sm">${formatCPF(
                    contract.student.cpf
                  )}</div>
                </div>

                <div class="w-full p-2">
                  <span class="font-bold text-sm">Data de Nascimento: </span>
                  <div class="p-2 text-sm">${
                    contract.student.filiation &&
                    contract.student.filiation.birthDate
                      ? moment(contract.student.filiation.birthDate).format(
                          "DD/MM/YYYY"
                        )
                      : ""
                  }</div>
                </div>
              </div>
                
              <div class="p-2">
                <span class="font-bold text-sm">Aparência da assinatura</span>
                <div class="p-2 text-sm">Assinado eletrônicamente</div>
              </div>
            `}
          buttonsRight={[
            <ButtonPrimary
              verifyOnClick={() => {
                setcontractModal(undefined);
                setEmailModal(true);
                handleSignContract();
              }}
              textButton="Assinar"
              desktopWidth="160px"
              mobileWidth="100%"
              optionalMovingIcon={<HiPencil className="text-[1rem]" />}
              disabled={studentInfos?.userTeste}
            />
          ]}
        />
      )}
      {/* Modal */}
      {emailModal && (
        <BlockquoteModal
          activeCloseButton
          text_top="Confirme a assinatura por email"
          outsideClick={() => setEmailModal(undefined)}
          innerHTML={`
          <div >
          <ol>
                  <li class="relative group pt-6 pb-2">
                      <div
                          class="absolute top-6 left-4 w-0.5 h-full -ml-px bg-gray-300"
                      ></div>
                      <div class="relative flex items-start">
                          <div class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset ring-transparent dark:bg-default bg-gray-400">
                              <p class="text-2xl font-semibold text-textPrimary">
                                  1
                              </p>
                          </div>
                          <div class="ml-4">
                              <p class="mb-4">
                                  Localize o email abaixo na sua caixa de entrada.
                              </p>
                              <img class="w-[310px]"
                                  src="/images/contracts/email-01.webp"
                                  alt="Email"
                              />
                          </div>
                      </div>
                  </li>
  
                  <li class="relative group py-6 pb-6">
                      <div class="relative flex items-start">
                          <div class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset ring-transparent dark:bg-default bg-gray-400">
                              <div class="text-2xl font-semibold text-textPrimary">
                                  2
                              </div>
                          </div>
                          <div class="ml-4 email-card">
                              <p class="leading-4 mb-4">
                                  Abra o e-mail e clique no botão
                                  <strong>"confirmar assinatura"</strong>
                              </p>
                              <img class="w-[310px]"
                                  src="/images/contracts/email-02.webp"
                                  alt="Email"
                              />
                          </div>
                      </div>
                  </li>
              </ng-container>
          </ol>
      </div>
  
      <!-- Actions -->
      <span class="p-2 text-secondary mb-10">Atalhos para o seu email:</span>
      <div class="flex items-center gap-2 pt-5 pb-5">
          <!-- Links -->
          <a href="https://mail.google.com/mail/" target="_blank" class="ml-2 rounded-md block w-full py-4 px-4 bg-backgroundThree" >
              <div class="flex gap-2 items-center">
                  <img src="/images/contracts/icon-gmail.webp" alt="Gmail"  />
                  <span class="text-xs">Gmail</span>
              </div>
          </a>
  
          <a href="https://outlook.live.com/mail/" target="_blank" class="ml-2 rounded-md block w-full py-3 px-3 bg-backgroundThree">
              <div class="flex gap-2 items-center">
                  <img src="/images/contracts/icon-outlook.webp" alt="Outlook"/>
                  <span class="text-xs">Outlook</span>
              </div>
          </a>
    
          <a href="https://login.yahoo.com/" target="_blank" class="ml-2 rounded-md block w-full py-4 px-4 bg-backgroundThree">
              <div class="flex gap-2 items-center">
                  <img src="/images/contracts/icon-yahoo.webp" alt="Yahoo" />
                  <span class="text-xs">Yahoo!</span>
              </div>
          </a>
      </div>
        `}
        />
      )}
    </main>
  );
}

export default verifyAuth(ContractPage);
