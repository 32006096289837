import React, { useEffect, useState } from "react";
import "./FinanceTablePending.css";
import { HiMiniCheck } from "react-icons/hi2";

function FinanceTablePending({ onValueChange, onFinancesSelected, finances }) {
  const [selectedFinances, setSelectedFinances] = useState([]);
  const [valueTotal, setValueTotal] = useState(0);

  useEffect(() => {
    let ids = [...selectedFinances];
    let value = valueTotal;

    for (const finance of finances) {
      if (finance.selected) {
        if (!selectedFinances.includes(finance.financeId)) {
          ids.push(finance.financeId);
          value = value + finance.valueCent;
        }
      }
    }
    setSelectedFinances(ids);
    setValueTotal(value);
    onValueChange(value);
    onFinancesSelected(ids);
    // eslint-disable-next-line
  }, [finances]);

  const handleSelectFinance = (financeId) => () => {
    let ids = [...selectedFinances];
    let value = valueTotal;

    const financeSelected = finances.find(
      (finance) => finance.financeId === financeId
    );

    if (financeSelected.financeName === "Matrícula") return;

    if (
      financeSelected.situation !== "Atrasado" &&
      financeSelected.paymentPlan.paymentMethod !== "Cartão"
    ) {
      if (ids.includes(financeId)) {
        ids = ids.filter((id) => id !== financeId);
        value =
          value -
          finances.find((finance) => finance.financeId === financeId).valueCent;
      } else {
        ids.push(financeId);
        value =
          value +
          finances.find((finance) => finance.financeId === financeId).valueCent;
      }
    }

    setSelectedFinances(ids);
    setValueTotal(value);
    onValueChange(value);
    onFinancesSelected(ids);
  };

  return (
    <main className="flex flex-col w-full gap-y-8">
      <div className="">
        <h1 className="text-xl text-textPrimary font-bold leading-[1.35]">
          Pendentes
        </h1>
        <span className="text-sm text-textSecondary">Cobranças em aberto.</span>
      </div>

      <section>
        <div className="grid financePending-grid gap-2 pb-5 px-4">
          <span className="text-sm font-semibold max-sm:hidden">Cobrança</span>
          <span className="text-sm font-semibold text-center">Valor</span>
          <span className="text-sm font-semibold text-center">Vencimento</span>
          <span className="text-sm font-semibold text-center max-sm:hidden">
            Método
          </span>
          <span className="text-sm font-semibold text-center px-2">
            Situação
          </span>
          <div className="max-sm:hidden"></div>
        </div>
        <div className="flex flex-col gap-2">
          {finances.map((finance, index) => (
            <div
              index={index}
              className={`grid financePending-grid gap-2 border bg-backgroundThree rounded-md py-5 px-4 items-center ${
                selectedFinances.includes(finance.financeId)
                  ? "border-themeColor"
                  : "border-backgroundThree"
              } ${
                finance.financeName === "Matrícula"
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={handleSelectFinance(finance.financeId)}>
              <span className="text-sm truncate max-sm:hidden">
                {finance.financeName}
              </span>
              <span className="text-sm text-center max-sm:text-start">
                {finance.value}
              </span>
              <span className="text-sm text-center">{finance.dueDate}</span>
              <span className="text-sm text-center max-sm:hidden">
                {finance.methodPayment}
              </span>
              <div className="flex w-full justify-center">
                <span
                  className={`text-xs text-center font-bold px-2 w-fit py-1 rounded-full ${
                    finance.situation === "Pendente" ||
                    finance.situation === "Processando"
                      ? "text-yellow-800 bg-yellow-100"
                      : "bg-red-100 text-red-800"
                  }`}>
                  {String(finance.situation).toUpperCase()}
                </span>
              </div>
              <span className={`flex justify-center items-center max-sm:hidden ${finance.financeName === "Matrícula" && "hidden"}`}>
                <div
                  className={`flex items-center justify-center transition-all duration-300 w-4 h-4 rounded-full border ${
                    selectedFinances.includes(finance.financeId)
                      ? "bg-themeColor border-themeColor"
                      : "bg-transparent  border-white"
                  }`}>
                  <span className="flex items-center justify-center transition-all duration-300 w-4 h-4 rounded-full overflow-hidden">
                    <HiMiniCheck
                      className={`transition-all text-xs ease-in-out duration-300 text-white ${
                        !selectedFinances.includes(finance.financeId) &&
                        "opacity-0 translate-y-6"
                      }`}
                    />
                  </span>
                </div>
              </span>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default FinanceTablePending;
