import React from "react";
import Select from "react-select";
import { certifier } from "../../mocks/certifier";

function SelectElement({ label, options, disableGroups, value, onChange }) {
  const selectedOption =
    options.find((option) => option.value === value) || null;

  const optionGroups = options.reduce((group, typeCourse) => {
    const newTypeCourse = typeCourse.typeCourse;
    if (!group[newTypeCourse]) {
      group[newTypeCourse] = [];
    }
    group[newTypeCourse].push(typeCourse);
    return group;
  }, {});

  const formattedOptions = Object.keys(optionGroups).map((course) => ({
    label: course,
    options: optionGroups[course],
  }));

  return (
    <div className="flex flex-col w-full gap-y-2">
      {label && <label className="text-textPrimary">{label}</label>}
      <Select
        options={disableGroups ? options : formattedOptions}
        isSearchable={false}
        value={selectedOption}
        onChange={onChange}
        placeholder=""
        styles={{
          control: (base, state) => ({
            ...base,
            backgroundColor: certifier.colors.background.backgroundTwo,
            borderRadius: "0.375rem",
            outline: "none",
            color: certifier.colors.text.textPrimary,
            fontSize: "14px !important",
            padding: "0.75rem 1rem",
            border: "none",
            cursor: "pointer",
            boxShadow: "none",
            "&:focus": {
              border: "none",
              boxShadow: "none",
            },
            "&:hover": {
              border: "none",
              boxShadow: "none",
            },
          }),
          groupHeading: (base) => ({
            ...base,
            textTransform: "none",
            padding: "0.5rem 1rem",
            margin: "0",
            fontSize: "14px !important",
            backgroundColor: certifier.colors.background.backgroundTwo,
          }),
          group: (base) => ({
            ...base,
            padding: "0",
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: "250px",
            padding: "0",
            "&::-webkit-scrollbar-thumb": {
              background: `${certifier.colors.basics.primary}`,
              borderRadius: "1.25rem",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: certifier.colors.background.backgroundTwo,
            },
          }),
          menu: (base, state) => ({
            ...base,
            marginTop: "4px",
            backgroundColor: certifier.colors.background.backgroundTree,
            color: `${certifier.colors.text.textPrimary} !important`,
            borderRadius: "0.375rem",
            fontSize: "14px !important",
            outline: "none",
            overflow: "hidden",
            zIndex: "105",
          }),
          option: (base, state) => ({
            ...base,
            padding: "0.8rem 1.5rem",
            color: `${certifier.colors.text.textPrimary} !important`,
            fontSize: "14px !important",
            backgroundColor: certifier.colors.background.backgroundTwo,
            outline: "none",
            border: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: certifier.colors.background.backgroundTwo_lighter,
            },
          }),
          singleValue: (base, state) => ({
            ...base,
            color: certifier.colors.text.textPrimary,
          }),
          valueContainer: (base, state) => ({
            ...base,
            outline: "none",
            paddingLeft: "0rem",
          }),
          indicatorSeparator: (base, state) => ({
            ...base,
            display: "none",
          }),
          dropdownIndicator: (base, state) => ({
            color: "#676767",
            marginRight: "0rem",
            // transition: "all 0.3s ease-in-out",
            transform: state.selectProps.menuIsOpen
              ? "rotate(180deg)"
              : "rotate(0deg)",
          }),
          clearIndicator: (base, state) => ({
            ...base,
            color: "#676767",
            outline: "none",
          }),
          input: (base, state) => ({
            ...base,
            backgroundColor: "#f00",
          }),
        }}
      />
    </div>
  );
}

export default SelectElement;
