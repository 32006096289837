import { Link } from "react-router-dom";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

export default function InfoCard({
  typeContent,
  typeBg,
  typeColor,
  icon,
  iconColor,
  mainContent,
  timelineContent,
  buttonText,
  buttonRedirect,
}) {
  return (
    <div className="w-full flex flex-col items-center gap-8">
      <div className="p-6 flex flex-col gap-y-5 w-full rounded-xl h-full shadow-2xl bg-backgroundThree">
        <div className="flex justify-between items-center">
          <span
            className="uppercase select-none font-bold px-2.5 py-0.5 rounded-full text-[0.625rem] tracking-wide"
            style={{ backgroundColor: typeBg, color: typeColor }}
          >
            {typeContent}
          </span>
          <span style={{ color: iconColor }} className="text-[1.2rem]">
            {icon}
          </span>
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="font-semibold text-sm">{mainContent.title}</p>
          <p className="text-xs text-textSecondary">{mainContent.content}</p>
        </div>
        <div className="h-[1px] w-14 bg-white rounded-full my-2" />
        <div className="flex flex-col gap-y-1">
          {timelineContent.map((item, index) => (
            <div className="flex gap-x-4 items-center">
              <div
                style={{ backgroundColor: item.color }}
                className="rounded-full w-2 h-2"
              />
              <p key={index} className="text-xs">
                {item.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end max-mc:pb-8">
        <Link to={buttonRedirect}>
          <ButtonPrimary
            disableSubmit
            textButton={buttonText}
            desktopWidth={150} 
            mobileWidth={150} 
          />
        </Link>
      </div>
    </div>
  );
}
