import { CryptoService } from "../cryptoService/crypto.service";

export default async function getStudentInfos() {
  try {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      return await CryptoService(storedUser, "decrypt-json");
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Erro ao puxar o estudante:`, error.message);
    return null;
  }
}
