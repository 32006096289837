import React, { useEffect, useState, useRef } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ListCourses from "../../components/ListCourses/ListCourses";
import { useLocation } from "react-router-dom";
import {
  getCoursesByAreaAlias,
  getAllAvailableCourses,
  getCoursesRecommendedRequest
} from "../../services/dashboardService/dashboard.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { CiSearch } from "react-icons/ci";
import { certifier } from "../../mocks/certifier";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { getAllStudentCourses } from "../../services/courseService/course.service";

const options = [
  { name: "Todos", alias: "all" },
  { name: "Educação", alias: "educacao" },
  { name: "Saúde", alias: "saude" },
  { name: "Engenharia", alias: "engenharia" },
  { name: "Direito", alias: "direito" },
  { name: "Psicologia", alias: "psicologia" },
  { name: "Empresarial", alias: "empresarial" },
  { name: "MBA Executivo", alias: "mba-executivo" },
  { name: "Meio Ambiente", alias: "meio-ambiente" },
  { name: "Serviço Social", alias: "servico-social" },
  { name: "Soft Skills", alias: "softskills" }
];

function SearchAreaPage() {
  const [courseLists, setCourseLists] = useState(undefined);
  const [cursosLivresCoursesFiltered, setCursosLivresCoursesFiltered] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [actualTab, setActualTab] = useState(options[0]);
  const [delayedActualTab, setDelayedActualTab] = useState(options[0]);
  const [searchValue, setSearchValue] = useState("");
  const [delayedSearchValue, setDelayedSearchValue] = useState("");
  const debounceHandlerRef = useRef(null);
  const [visible, setVisible] = useState(true);
  const fadeOutTimeoutRef = useRef(null);
  const sliderRef = useRef(null);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [allStudentCourses, setAllStudentCourses] = useState(null);
  const [renderedAllCourses, setRenderedAllCourses] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const typeCourse = getQueryParam("typeCourse");
  const areaAliasParam = getQueryParam("area") || "all";

  useEffect(() => {
    const initialTab =
      options.find((option) => option.alias === areaAliasParam) || options[0];
    handleTabChange(initialTab);
  }, [areaAliasParam]);

  useEffect(() => {
    const fetchStudentCourses = async () => {
      const student = await getStudentInfos();
      const studentCoursesResponse = await getAllStudentCourses({
        sessionId: student.sessionId
      });
      if (studentCoursesResponse.status !== 200) {
        Toastify("error", "Erro ao buscar informações de seus cursos.");
        console.error(
          "Erro ao buscar informações de seus cursos - 1:",
          studentCoursesResponse.status
        );
      } else {
        let allStudentCoursesArray = [];
        if (
          studentCoursesResponse.data.coursesPosGraduation &&
          studentCoursesResponse.data.coursesPosGraduation.length > 0
        ) {
          allStudentCoursesArray = allStudentCoursesArray.concat(
            studentCoursesResponse.data.coursesPosGraduation
          );
        }
        if (
          studentCoursesResponse.data.extraCourses &&
          studentCoursesResponse.data.extraCourses.length > 0
        ) {
          allStudentCoursesArray = allStudentCoursesArray.concat(
            studentCoursesResponse.data.extraCourses
          );
        }
        setAllStudentCourses(allStudentCoursesArray);
      }
    };

    fetchStudentCourses();
  }, []);

  useEffect(() => {
    if (!allStudentCourses) return;
    fetchCourses();
  }, [actualTab, allStudentCourses]);

  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const student = await getStudentInfos();

      let arrayCourses = [];
      let params = {
        sessionId: student.sessionId,
        typeCourse: typeCourse
      };

      if (actualTab.alias === "all") {
        arrayCourses = await getAllAvailableCourses({
          sessionId: student.sessionId,
          typeCourse: typeCourse || "posGraduacao"
        });
        if (arrayCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de cursos.");
          console.error(
            "Erro ao buscar informações de cursos disponíveis:",
            arrayCourses.status
          );
        }
      } else {
        params.areaAlias = actualTab.alias;
        arrayCourses = await getCoursesByAreaAlias(params);
        if (arrayCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de cursos.");
          console.error(
            "Erro ao buscar informações de cursos - 1:",
            arrayCourses.status
          );
        }
      }

      try {
        setRenderedAllCourses(false);
        const courses = arrayCourses.data.courses;
        const coursesWithUnlock = courses.map((course) => {
          const studentHasCourse = allStudentCourses.some(
            (studentCourse) => studentCourse.alias === course.alias
          );
          return {
            ...course,
            unlockCourse: studentHasCourse
          };
        });
        setAllCourses(coursesWithUnlock);
        setCourseLists({
          areaName: arrayCourses.data.areaName || "Pós-Graduação",
          courses: coursesWithUnlock
        });
      } catch (error) {
        console.error(`Erro ao processar itens da área: #01`, error);
        // Toastify("error", "Erro ao processar itens da área: #01");
      }
    } catch (error) {
      Toastify("error", "Erro generalizado ao buscar área.");
      console.error("Erro ao buscar área: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!allStudentCourses) return;
    fetchCursosLivres();
  }, [allStudentCourses]);

  const fetchCursosLivres = async () => {
    const student = await getStudentInfos();

    const studentCoursesResponse = await getAllAvailableCourses({
      sessionId: student.sessionId,
      typeCourse: "cursosLivres"
    });
    const courses = studentCoursesResponse.data.courses;

    const coursesWithUnlock = courses
      .filter((course) => course.typeCourse === "cursosLivres")
      .map((course) => {
        const studentHasCourse = allStudentCourses.some(
          (studentCourse) => studentCourse.alias === course.alias
        );
        return {
          ...course,
          unlockCourse: studentHasCourse
        };
      });

    setCursosLivresCoursesFiltered(coursesWithUnlock);
  };

  useEffect(() => {
    // Fetch recommended courses
    const fetchRecommendedCourses = async () => {
      try {
        const student = await getStudentInfos();
        const recommendedCoursesResponse = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: true
        });
        if (recommendedCoursesResponse.status !== 200) {
          console.error(
            "Erro ao buscar informações de cursos recomendados - 4:",
            recommendedCoursesResponse.status
          );
        } else {
          console.log("Cursos recomendados:", recommendedCoursesResponse);
          console.log("Cursos já cursados:", allStudentCourses);
          if (!allStudentCourses) return;
          const filteredRecommendedCourses =
            recommendedCoursesResponse.data.courses.filter(
              (course) =>
                !allStudentCourses.some(
                  (studentCourse) => studentCourse.alias === course.alias
                )
            );
          setRecommendedCourses(filteredRecommendedCourses);
        }
      } catch (error) {
        console.error("Erro ao buscar cursos recomendados:", error);
      }
    };

    fetchRecommendedCourses();
  }, [allStudentCourses]);

  const handleTabChange = (option) => {
    if (option === actualTab) return;
    setActualTab(option);
    setTimeout(() => {
      setDelayedActualTab(option);
    }, 300);

    if (sliderRef.current) {
      const index = options.findIndex((opt) => opt.alias === option.alias);
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleCourseInput = (e) => {
    setSearchValue(e.target.value);
    if (debounceHandlerRef.current) {
      clearTimeout(debounceHandlerRef.current);
    }
    if (fadeOutTimeoutRef.current) {
      clearTimeout(fadeOutTimeoutRef.current);
    }

    debounceHandlerRef.current = setTimeout(() => {
      setVisible(false);

      fadeOutTimeoutRef.current = setTimeout(() => {
        setDelayedSearchValue(e.target.value);
        setVisible(true);
      }, 300);
    }, 500);
  };

  useEffect(() => {
    if (!allCourses) return;

    const filtered = filterCourses(allCourses);
    setFilteredCourses(filtered);
  }, [delayedSearchValue, allCourses]);

  const filterCourses = (coursesToFilter) => {
    if (!coursesToFilter) return [];

    let filteredCourses = coursesToFilter;

    if (delayedSearchValue) {
      const removeAccents = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const inputValue = removeAccents(delayedSearchValue.toLowerCase());
      filteredCourses = filteredCourses.filter((course) =>
        removeAccents(course.name.toLowerCase()).includes(inputValue)
      );
    }

    return filteredCourses;
  };

  const handleAfterChange = (current) => {
    if (current === 0) {
      setIsFirstSlide(true);
    } else {
      setIsFirstSlide(false);
    }

    const lastPossibleSlide =
      options.length - (window.innerHeight > 768 ? 8 : 2);
    if (current >= lastPossibleSlide) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 3,
    draggable: true,
    swipeToSlide: true,
    autoplay: false,
    variableWidth: true,
    afterChange: (current) => handleAfterChange(current),
    arrows: false,
    touchThreshold: 15,
    edgeFriction: 0.15,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ]
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const onRenderAllCourses = (value) => {
    setRenderedAllCourses(value);
  };

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <style jsx global>{`
        .slick-track {
          width: 1444px !important;
        }

        @media (max-width: 768px) {
          .slick-track {
            width: 1444px !important;
          }
        }
      `}</style>
      <HeaderBar
        courseNavigation={false}
        mobileMainText={courseLists ? courseLists.areaName : "Cursos"}
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar />
      <LoadingSection isLoading={isLoading} />
      <section className="flex flex-col items-center justify-center w-full px-6">
        <section
          className={`flex flex-col w-full max-w-[1182px] p-6 gap-3 rounded-2xl bg-backgroundTwo max-md:mt-16 mt-6`}>
          <div className="flex items-center gap-3 overflow-x-hidden relative">
            <div
              className={`absolute left-0 flex pl-6 items-center w-[70px] h-full`}>
              <div
                className={` h-full absolute left-0 pointer-events-none z-[1] transition-300 ${
                  isFirstSlide && "opacity-0"
                } w-[20px]`}
                style={{
                  background: `linear-gradient(to right, ${certifier.colors.background.backgroundTwo} 40%, transparent)`
                }}
              />
              <button
                onClick={previous}
                className={`absolute z-[2] p-3 transition-300 ${
                  isFirstSlide && "opacity-0 pointer-events-none"
                } -ml-10`}
                aria-label="Anterior">
                <HiChevronLeft color="white" />
              </button>
            </div>
            <Slider ref={sliderRef} {...settings}>
              {options.map((option, index) => (
                <div key={index} style={{ width: "fit-content" }}>
                  <button
                    className={`py-2 my-3 rounded-full transition-300 ${
                      actualTab.alias === option.alias
                        ? "bg-backgroundThree"
                        : "opacity-60"
                    } px-6`}
                    onClick={() => handleTabChange(option)}>
                    {option.name}
                  </button>
                </div>
              ))}
            </Slider>
            <div
              className={`absolute right-0 flex justify-end pr-6 items-center w-[70px] h-full`}>
              <div
                className={`h-full absolute right-0 pointer-events-none z-[1] transition-300 ${
                  isLastSlide && "opacity-0"
                } w-[20px]`}
                style={{
                  background: `linear-gradient(to left, ${certifier.colors.background.backgroundTwo} 40%, transparent)`
                }}
              />
              <button
                onClick={next}
                className={`absolute z-[2] p-3 transition-300 ${
                  isLastSlide && "opacity-0 pointer-events-none"
                } -mr-10`}
                aria-label="Próximo">
                <HiChevronRight color="white" />
              </button>
            </div>
          </div>
          <div className="flex max-md:flex-col w-full items-center gap-6">
            <div className="relative w-full">
              <input
                className="w-full rounded-full cursor-text px-[56px] py-3 text-sm"
                style={{
                  backgroundColor: certifier.colors.background.backgroundThree,
                  color: `white`
                }}
                placeholder="Pesquisar curso"
                onInput={handleCourseInput}
              />
              <CiSearch
                className="absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                size={24}
                color="#9B9B9B"
              />
              <style jsx>{`
                input::placeholder {
                  color: #9b9b9b;
                }
              `}</style>
            </div>
          </div>
        </section>
      </section>
      {courseLists && (
        <div
          className={`transition-opacity duration-300 w-full ${
            visible ? "opacity-100" : "opacity-0"
          }`}>
          {filteredCourses.length > 0 ? (
            <ListCourses
              isAvailableCourses={typeCourse}
              headerTitle={
                delayedSearchValue
                  ? `Resultados para "${delayedSearchValue}" ${
                      delayedActualTab.name !== "Todos"
                        ? `em ${delayedActualTab.name}`
                        : ""
                    }`
                  : courseLists.areaName
              }
              allCourses={filteredCourses}
              onRenderAllCourses={onRenderAllCourses}
            />
          ) : (
            <ListCourses
              isAvailableCourses={typeCourse}
              headerTitle={`Nenhum curso encontrado.<div class="h-[0.75rem]"></div><div class="w-full h-[1px] min-h-[1px] bg-backgroundThree rounded-full my-3" ></div><div class="h-[0.75rem]"></div>Cursos recomendados:`}
              allCourses={recommendedCourses}
            />
          )}
        </div>
      )}
      {courseLists && renderedAllCourses && actualTab.alias === "all" && (
        <ListCourses
          isAvailableCourses={typeCourse}
          headerTitle="Cursos Livres"
          allCourses={cursosLivresCoursesFiltered}
        />
      )}
    </main>
  );
}

export default verifyAuth(SearchAreaPage);
