import axios from "axios";
import * as Sentry from "@sentry/react";

export const newPassRequest = async (data) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_ADDRESS}/newPass`, {newPass:data});
    return {
      status: response.status
    };
  } catch (err) {    
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};