import axios from "axios";
import * as Sentry from "@sentry/react";

export const logoutRequest = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/logout`,
      { logout: data }
    );
    localStorage.removeItem("career")
    return {
      status: response.status,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.status };
  }
};
