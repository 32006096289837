import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import * as Sentry from "@sentry/react";

export default function ExternalLoginRedirect() {
  const { firstAccessKey } = useParams();

  useEffect(() => {
    async function handleRedirect() {
      try {
        const firstAccessDecoded = jwtDecode(firstAccessKey);
        const ciphertext = await CryptoService(
          firstAccessDecoded,
          "encrypt-crypto"
        );
        localStorage.setItem("user", ciphertext);
        if (window) {
          if (firstAccessDecoded.firstAccess) {
            window.location.href = "/user/basicInfos";
          } else {
            window.location.href = "/";
          }
        }
      } catch (error) {
        Sentry.setUser(null);
        const currentPath = "/user/basicInfos";
        localStorage.removeItem("user");
        if (window)
          window.location.href = `/login?redirect=${encodeURIComponent(
            currentPath
          )}&reason=invalidFirstAccessKey`;
      }
    }

    if (firstAccessKey) {
      handleRedirect();
    } else {
      window.location.href = "/";
    }
  }, [firstAccessKey]);

  return <LoadingSection isLoading={true} />;
}
