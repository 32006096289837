import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import {
  HiArrowPath,
  HiExclamationTriangle,
  HiInformationCircle,
  HiMiniArrowUpTray,
  HiMiniUserCircle,
  HiAcademicCap,
} from "react-icons/hi2";
import Blockquote from "../../components/Blockquote/Blockquote";
import StepsList from "../../components/StepsList/StepsList";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { HiMiniMapPin, HiDocument } from "react-icons/hi2";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import {
  getStudentDocuments,
  sendStudentDocuments,
} from "../../services/documentsService/documents.service";
import { Link } from "react-router-dom";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";

function DocumentsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [studentInfos, setStudentInfos] = useState({});
  const [documentInfos, setDocumentInfos] = useState(undefined);
  const [documentFile_share1, setDocumentFile_share1] = useState(undefined);
  const [documentFile_share2, setDocumentFile_share2] = useState(undefined);
  const [documentFile_share3, setDocumentFile_share3] = useState(undefined);
  const [documentFile_share4, setDocumentFile_share4] = useState(undefined);
  const [documentFile_share5, setDocumentFile_share5] = useState(undefined);
  const [documentSteps, setDocumentSteps] = useState(undefined);
  const [correctionArea, setCorrectionArea] = useState(undefined);
  const [disapprovedQuantity, setDisapprovedQuantity] = useState(undefined);
  const [mobileScreen, setMobileScreen] = useState(1);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const fetchStudentCourses = async () => {
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        setStudentInfos(student);
        const documentResponse = await getStudentDocuments({ student });
        setDocumentSteps([
          {
            content: (
              <div className="flex flex-col">
                <span className="font-semibold text-sm">Identificação</span>
                <span className="text-xs text-textSecondary">
                  {documentResponse.data.currentStep === null &&
                  documentResponse.data.document[0].status === "approved"
                    ? "Aprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[0].status === "disapproved"
                    ? "Reprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[0].status === "in_analysis"
                    ? "Em análise"
                    : "CNH, RG e CPF."}
                </span>
              </div>
            ),
            activated:
              documentResponse.data.currentStep === null ||
              documentResponse.data.currentStep >= 0,
            icon: <HiMiniUserCircle />,
            warning: documentResponse.data.stepsDisapproved.includes(0),
          },
          {
            content: (
              <div className="flex flex-col">
                <span className="font-semibold text-sm">
                  Comprovante de Endereço
                </span>
                <span className="text-xs text-textSecondary">
                  {documentResponse.data.currentStep === null &&
                  documentResponse.data.document[1].status === "approved"
                    ? "Aprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[1].status === "disapproved"
                    ? "Reprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[1].status === "in_analysis"
                    ? "Em análise"
                    : "Conta de água, luz ou telefone."}
                </span>
              </div>
            ),
            activated:
              documentResponse.data.currentStep === null ||
              documentResponse.data.currentStep >= 1,
            icon: <HiMiniMapPin />,
            warning: documentResponse.data.stepsDisapproved.includes(1),
          },
          {
            content: (
              <div className="flex flex-col">
                <span className="font-semibold text-sm">
                  Diploma de Graduação
                </span>
                <span className="text-xs text-textSecondary">
                  {documentResponse.data.currentStep === null &&
                  documentResponse.data.document[2].status === "approved"
                    ? "Aprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[2].status === "disapproved"
                    ? "Reprovado"
                    : documentResponse.data.currentStep === null &&
                      documentResponse.data.document[2].status === "in_analysis"
                    ? "Em análise"
                    : "Seu documento de formatura."}
                </span>
              </div>
            ),
            activated:
              documentResponse.data.currentStep === null ||
              documentResponse.data.currentStep >= 2,
            icon: <HiAcademicCap />,
            warning: documentResponse.data.stepsDisapproved.includes(2),
          },
        ]);
        setDocumentInfos(documentResponse.data);
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os documentos.");
        console.error("Erro ao buscar os documentos: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStudentCourses();
  }, []);

  const handleAttachOrChangeFile = (inputNumber, alias, type, isCorrection) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      "application/pdf, application/msword, image/jpeg, image/png, image/jpg";
    input.click();
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          setIsLoading(true);
          if (disapprovedQuantity) {
            setDisapprovedQuantity(disapprovedQuantity - 1);
          }
          const documentSentResult = await sendStudentDocuments({
            studentInfos,
            file,
            documentInfos,
            alias,
            type,
            isCorrectionType: isCorrection,
          });

          if (documentSentResult.status !== 200) {
            Toastify("error", "Erro ao enviar o documento.");
            return;
          }
          switch (inputNumber) {
            case 1:
              setDocumentFile_share1(file);
              break;
            case 2:
              setDocumentFile_share2(file);
              break;
            case 3:
              setDocumentFile_share3(file);
              break;
            case 4:
              setDocumentFile_share4(file);
              break;
            case 5:
              setDocumentFile_share5(file);
              break;
            default:
              break;
          }
          Toastify("success", "Documento enviado com sucesso.");
        } catch (error) {
          Toastify("error", "Erro ao enviar o documento.");
          console.error("Erro ao enviar o documento: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  };

  const handleNextDocumentsStep = async () => {
    try {
      setIsLoading(true);
      setDocumentFile_share1(undefined);
      setDocumentFile_share2(undefined);
      setDocumentFile_share3(undefined);
      setDocumentFile_share4(undefined);
      setDocumentFile_share5(undefined);
      setCorrectionArea(undefined);
      setDocumentInfos(undefined);
      const documentResponse = await getStudentDocuments({
        student: studentInfos,
      });
      if (documentResponse.status !== 200) {
        Toastify("error", "Erro ao passar para a próxima etapa.");
        return;
      }

      setDocumentInfos(documentResponse.data);
      setDocumentSteps([
        {
          content: (
            <div className="flex flex-col">
              <span className="font-semibold">Identificação</span>
              <span className="text-xs">
                {documentResponse.data.currentStep === null &&
                documentResponse.data.document[0].status === "approved"
                  ? "Aprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[0].status === "disapproved"
                  ? "Reprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[0].status === "in_analysis"
                  ? "Em análise"
                  : "CNH, RG e CPF."}
              </span>
            </div>
          ),
          activated:
            documentResponse.data.currentStep === null ||
            documentResponse.data.currentStep >= 0,
          icon: <HiOutlineUserCircle />,
          warning: documentResponse.data.stepsDisapproved.includes(0),
        },
        {
          content: (
            <div className="flex flex-col">
              <span className="font-semibold">Comprovante de Endereço</span>
              <span className="text-xs">
                {documentResponse.data.currentStep === null &&
                documentResponse.data.document[1].status === "approved"
                  ? "Aprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[1].status === "disapproved"
                  ? "Reprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[1].status === "in_analysis"
                  ? "Em análise"
                  : "Conta de água, luz ou telefone."}
              </span>
            </div>
          ),
          activated:
            documentResponse.data.currentStep === null ||
            documentResponse.data.currentStep >= 1,
          icon: <HiMiniMapPin />,
          warning: documentResponse.data.stepsDisapproved.includes(1),
        },
        {
          content: (
            <div className="flex flex-col">
              <span className="font-semibold">Diploma de Graduação</span>
              <span className="text-xs">
                {documentResponse.data.currentStep === null &&
                documentResponse.data.document[2].status === "approved"
                  ? "Aprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[2].status === "disapproved"
                  ? "Reprovado"
                  : documentResponse.data.currentStep === null &&
                    documentResponse.data.document[2].status === "in_analysis"
                  ? "Em análise"
                  : "Seu documento de formatura."}
              </span>
            </div>
          ),
          activated:
            documentResponse.data.currentStep === null ||
            documentResponse.data.currentStep >= 2,
          icon: <HiOutlineAcademicCap />,
          warning: documentResponse.data.stepsDisapproved.includes(2),
        },
      ]);

      if (
        documentResponse.data.document[0].documents.length === 0 &&
        documentResponse.data.document[1].documents.length === 0 &&
        documentResponse.data.document[2].documents.length === 0
      ) {
        setMobileScreen(1);
      }
    } catch (error) {
      Toastify("error", "Erro ao passar para a próxima etapa.");
      console.error("Erro ao passar para a próxima etapa: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCorrectionDocuments = () => {
    setMobileScreen(2);
    setDisapprovedQuantity(documentInfos.documentsDisapproved.length);
    setCorrectionArea(documentInfos.documentsDisapproved);
  };

  const handleSetMobileScreen = () => {
    setMobileScreen(2);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary min-h-screen">
      <HeaderBar
        courseNavigation={false}
        actualPage="Documentos"
        mobileMainText="Documentos"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Documentos" />
      <LoadingSection isLoading={isLoading} />
      {documentInfos && (
        <section
        style={{paddingTop: windowWidth < 960 ? navBarHeader : 0}}
          className={`flex gap-5 max-mc:gap-0 overflow-hidden w-full h-full max-w-[1170px] justify-center bg-backgroundTwo min-h-[600px] mt-9 mb-9  rounded-xl max-mc:mt-0 max-mc:min-h-[100vh] max-mc:rounded-none`}
        >
          <div
            style={{
              height:
                windowWidth < 960 && mobileScreen === 1
                  ? windowHeight - navBar
                  : "auto",
            }}
            className={`flex border-black/20 ${
              windowWidth < 960 && mobileScreen === 1
                ? "w-full p-8 overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
                : windowWidth < 960
                ? "hidden"
                : "w-[62%] p-32 py-16"
            }`}
          >
            <div className="flex flex-col gap-y-8 w-full h-full">
              <div className="flex flex-col gap-y-4">
                <h1
                  className={`max-mc:text-4xl text-5xl text-textPrimary font-bold leading-none`}
                >
                  {documentInfos.currentStep !== null
                    ? `${
                        String(studentInfos.name).split(" ")[0]
                      }, vamos enviar seus
                    documentos?`
                    : documentInfos.stepsDisapproved.length > 0
                    ? `${
                        String(studentInfos.name).split(" ")[0]
                      }, essa é a situação dos seus documentos`
                    : documentInfos.document[0].status === "approved" &&
                      documentInfos.document[1].status === "approved" &&
                      documentInfos.document[2].status === "approved"
                    ? `${
                        String(studentInfos.name).split(" ")[0]
                      }, todos os seus documentos foram aprovados.`
                    : "Fantástico, você enviou todos os documentos!"}
                </h1>
                {documentInfos.stepsDisapproved.length > 0 &&
                  documentInfos.currentStep === null && (
                    <span className="text-textSecondary text-sm">
                      {documentInfos.currentStep !== null
                        ? "É muito provável que você precise emitir uma declaração de matrícula durante o curso, e é justamente para isso que solicitamos seus documentos."
                        : documentInfos.stepsDisapproved.length > 0
                        ? "Verifique a situação e realize a correção."
                        : "Agora é com a gente, utilize esse espaço para acompanhar e saber se os seus documentos serão aprovados."}
                    </span>
                  )}
              </div>
              <StepsList steps={documentSteps} />
              {documentInfos.currentStep !== null ? (
                <div
                  className={`w-full justify-end ${
                    window.innerWidth < 960 && mobileScreen === 1
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <ButtonPrimary
                    verifyOnClick={handleSetMobileScreen}
                    textButton="Próximo"
                    desktopWidth={150}
                    mobileWidth={150}
                  />
                </div>
              ) : documentInfos.document[0].status === "approved" &&
                documentInfos.document[1].status === "approved" &&
                documentInfos.document[2].status === "approved" ? (
                <Link
                  to="/studentCourses"
                  className={`w-full justify-end ${
                    window.innerWidth < 960 && mobileScreen === 1
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <ButtonPrimary
                    disabled={disapprovedQuantity && disapprovedQuantity > 0}
                    textButton="Estudar"
                    desktopWidth={120}
                    mobileWidth={120}
                    verifyOnClick={handleNextDocumentsStep}
                  />
                </Link>
              ) : documentInfos.stepsDisapproved.length > 0 ? (
                <div
                  className={`w-full justify-end ${
                    window.innerWidth < 960 && mobileScreen === 1
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <ButtonPrimary
                    disabled={!documentInfos}
                    textButton="Corrigir"
                    verifyOnClick={handleCorrectionDocuments}
                    desktopWidth={120}
                    mobileWidth={120}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              height:
                windowWidth < 960 && mobileScreen === 2
                  ? windowHeight - navBar
                  : "auto",
            }}
            className={`bg-backgroundThree flex flex-col m-0 gap-8 ${
              window.innerWidth < 960 && mobileScreen === 2
                ? "w-full p-8 overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
                : window.innerWidth < 960
                ? "hidden"
                : "w-[38%] p-16"
            }`}
          >
            <div>
              <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                {documentInfos.currentStep !== null
                  ? `Passo ${documentInfos.currentStep + 1} de 3`
                  : documentInfos.stepsDisapproved.length > 0
                  ? "Correções"
                  : documentInfos.document[0].status === "approved" &&
                    documentInfos.document[1].status === "approved" &&
                    documentInfos.document[2].status === "approved"
                  ? "Hora de estudar!"
                  : studentInfos.name.split(" ")[0]}
              </h1>
              <span className="text-textPrimary text-sm">
                {documentInfos.currentStep === 0
                  ? "Envie seus documentos de Identificação."
                  : documentInfos.currentStep === 1
                  ? "Envie seu comprovante de Endereço."
                  : documentInfos.currentStep === 2
                  ? "Envie seu diploma de Graduação."
                  : documentInfos.stepsDisapproved.length > 0
                  ? "Reenvie os documentos corretamente."
                  : documentInfos.document[0].status === "approved" &&
                    documentInfos.document[1].status === "approved" &&
                    documentInfos.document[2].status === "approved"
                  ? "Clique no botão abaixo para assistir as aulas."
                  : "Agora é só aguardar!"}
              </span>
            </div>
            {(documentInfos.document[0].status === "in_analysis" ||
              documentInfos.document[1].status === "in_analysis" ||
              documentInfos.document[2].status === "in_analysis") &&
              documentInfos.stepsDisapproved.length === 0 && (
                <Blockquote
                  text={
                    documentInfos.currentStep === 0
                      ? "Caso prefira, você pode tirar uma foto do seu próprio celular, desde que esteja nítida e com boa iluminação."
                      : documentInfos.currentStep === 1
                      ? "O comprovante de endereço precisa estar em seu nome, você pode anexar uma conta de água, luz, telefone ou até mesmo uma fatura bancária."
                      : documentInfos.currentStep === 2
                      ? "É necessário enviar frente e verso do diploma, com sua assinatura e número de registro legíveis, deve conter nome da instituição de ensino e seu nome completo."
                      : "Em até dez dias úteis nós retornaremos sobre a situação dos seus documentos."
                  }
                  color="#2563eb"
                  icon={<HiInformationCircle />}
                />
              )}
            <div className="flex flex-col gap-y-8">
              {!correctionArea &&
                documentInfos.currentStep === 0 &&
                (documentFile_share1 ||
                  documentInfos.document[0].documents.find(
                    (document) =>
                      document.alias === "identification" &&
                      document.type === "front"
                  )) && (
                  <div className="flex flex-col gap-y-3 w-full items-center">
                    <span
                      className="text-textPrimary text-sm w-full text-left"
                      dangerouslySetInnerHTML={{
                        __html: `Envie a parte da <b>frente</b> da sua CNH, RG ou CPF.`,
                      }}
                    />
                    <div className="flex flex-col w-full gap-y-5 items-center">
                      {documentFile_share1 ? (
                        documentFile_share1.type.includes("application") ? (
                          <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                        ) : (
                          <img
                            src={URL.createObjectURL(documentFile_share1)}
                            className="h-auto rounded-md w-full"
                            alt="Document"
                          />
                        )
                      ) : (
                        <img
                          src="/images/assets/frontDocument.webp"
                          className="h-auto rounded-md w-full"
                          alt="Document"
                        />
                      )}
                      {documentFile_share1 &&
                        documentFile_share1.type.includes("application") && (
                          <p className="line-clamp-1 text-center text-sm">
                            {documentFile_share1.name}
                          </p>
                        )}
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      {documentFile_share1 !== undefined ? (
                        <ButtonSecondary
                          optionalMovingIcon={<HiArrowPath />}
                          textButton="Substituir"
                          desktopWidth="150px"
                          mobileWidth="150px"
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              1,
                              "identification",
                              "front",
                              false
                            )
                          }
                          disabled={studentInfos.userTeste}
                        />
                      ) : (
                        <ButtonSecondary
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              1,
                              "identification",
                              "front",
                              false
                            )
                          }
                          textButton="Anexar"
                          optionalMovingIcon={<HiMiniArrowUpTray />}
                          desktopWidth="120px"
                          mobileWidth="120px"
                          disabled={studentInfos.userTeste}
                        />
                      )}
                    </div>
                  </div>
                )}

              {!correctionArea &&
                documentInfos.currentStep === 0 &&
                (documentFile_share2 ||
                  documentInfos.document[0].documents.find(
                    (document) =>
                      document.alias === "identification" &&
                      document.type === "back"
                  )) && (
                  <div className="flex flex-col gap-y-3 w-full items-center">
                    <span
                      className="text-textPrimary text-sm w-full text-left"
                      dangerouslySetInnerHTML={{
                        __html: `Envie a parte de <b>trás</b> da sua CNH, RG ou CPF.`,
                      }}
                    />
                    <div className="flex flex-col w-full gap-y-5 items-center">
                      {documentFile_share2 ? (
                        documentFile_share2.type.includes("application") ? (
                          <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                        ) : (
                          <img
                            src={URL.createObjectURL(documentFile_share2)}
                            className="h-auto rounded-md w-full"
                            alt="Document"
                          />
                        )
                      ) : (
                        <img
                          src="/images/assets/backDocument.webp"
                          className="h-auto rounded-md w-full"
                          alt="Document"
                        />
                      )}
                      {documentFile_share2 &&
                        documentFile_share2.type.includes("application") && (
                          <p className="line-clamp-1 text-center text-sm">
                            {documentFile_share2.name}
                          </p>
                        )}
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      {documentFile_share2 !== undefined ? (
                        <ButtonSecondary
                          optionalMovingIcon={<HiArrowPath />}
                          textButton="Substituir"
                          desktopWidth="150px"
                          mobileWidth="150px"
                          disabled={studentInfos.userTeste}
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              2,
                              "identification",
                              "back",
                              false
                            )
                          }
                        />
                      ) : (
                        <ButtonSecondary
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              2,
                              "identification",
                              "back",
                              false
                            )
                          }
                          textButton="Anexar"
                          optionalMovingIcon={<HiMiniArrowUpTray />}
                          desktopWidth="120px"
                          mobileWidth="120px"
                          disabled={studentInfos.userTeste}
                        />
                      )}
                    </div>
                  </div>
                )}

              {!correctionArea && documentInfos.currentStep === 1 && (
                <div className="flex flex-col gap-y-3 w-full items-center">
                  <span
                    className="text-textPrimary text-sm w-full text-left"
                    dangerouslySetInnerHTML={{
                      __html: `Envie a parte da <b>frente</b> do seu comprovante de endereço.`,
                    }}
                  />
                  <div className="flex flex-col w-full gap-y-5 items-center">
                    {documentFile_share3 ? (
                      documentFile_share3.type.includes("application") ? (
                        <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                      ) : (
                        <img
                          src={URL.createObjectURL(documentFile_share3)}
                          className="h-auto rounded-md w-full"
                          alt="Document"
                        />
                      )
                    ) : (
                      <img
                        src="/images/assets/comprovanteEndereço.webp"
                        className="h-auto rounded-md w-full"
                        alt="Document"
                      />
                    )}
                    {documentFile_share3 &&
                      documentFile_share3.type.includes("application") && (
                        <p className="line-clamp-1 text-center text-sm">
                          {documentFile_share3.name}
                        </p>
                      )}
                  </div>
                  <div className="my-2 flex w-full justify-center">
                    {documentFile_share3 !== undefined ? (
                      <ButtonSecondary
                        optionalMovingIcon={<HiArrowPath />}
                        textButton="Substituir"
                        desktopWidth="150px"
                        disabled={studentInfos.userTeste}
                        mobileWidth="150px"
                        verifyOnClick={() =>
                          handleAttachOrChangeFile(
                            3,
                            "checking",
                            "front",
                            false
                          )
                        }
                      />
                    ) : (
                      <ButtonSecondary
                        verifyOnClick={() =>
                          handleAttachOrChangeFile(
                            3,
                            "checking",
                            "front",
                            false
                          )
                        }
                        textButton="Anexar"
                        optionalMovingIcon={<HiMiniArrowUpTray />}
                        desktopWidth="120px"
                        mobileWidth="120px"
                        disabled={studentInfos.userTeste}
                      />
                    )}
                  </div>
                </div>
              )}

              {!correctionArea &&
                documentInfos.currentStep === 2 &&
                (documentFile_share4 ||
                  documentInfos.document[2].documents.find(
                    (document) =>
                      document.alias === "graduation" &&
                      document.type === "front"
                  )) && (
                  <div className="flex flex-col w-full gap-y-3 items-center">
                    <span
                      className="text-textPrimary text-sm w-full text-left"
                      dangerouslySetInnerHTML={{
                        __html: `Envie a parte da <b>frente</b> do seu diploma.`,
                      }}
                    />
                    <div className="flex flex-col w-full gap-y-5 items-center">
                      {documentFile_share4 ? (
                        documentFile_share4.type.includes("application") ? (
                          <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                        ) : (
                          <img
                            src={URL.createObjectURL(documentFile_share4)}
                            className="h-auto rounded-md w-full"
                            alt="Document"
                          />
                        )
                      ) : (
                        <img
                          src="/images/assets/frontDiploma.webp"
                          className="h-auto rounded-md w-full"
                          alt="Document"
                        />
                      )}
                      {documentFile_share4 &&
                        documentFile_share4.type.includes("application") && (
                          <p className="line-clamp-1 text-center text-sm">
                            {documentFile_share4.name}
                          </p>
                        )}
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      {documentFile_share4 !== undefined ? (
                        <ButtonSecondary
                          optionalMovingIcon={<HiArrowPath />}
                          textButton="Substituir"
                          desktopWidth="150px"
                          mobileWidth="150px"
                          disabled={studentInfos.userTeste}
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              4,
                              "graduation",
                              "front",
                              false
                            )
                          }
                        />
                      ) : (
                        <ButtonSecondary
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              4,
                              "graduation",
                              "front",
                              false
                            )
                          }
                          textButton="Anexar"
                          optionalMovingIcon={<HiMiniArrowUpTray />}
                          desktopWidth="120px"
                          mobileWidth="120px"
                          disabled={studentInfos.userTeste}
                        />
                      )}
                    </div>
                  </div>
                )}

              {!correctionArea &&
                documentInfos.currentStep === 2 &&
                (documentFile_share5 ||
                  documentInfos.document[2].documents.find(
                    (document) =>
                      document.alias === "graduation" &&
                      document.type === "back"
                  )) && (
                  <div className="flex flex-col gap-y-3 w-full items-center">
                    <span
                      className="text-textPrimary text-sm w-full text-left"
                      dangerouslySetInnerHTML={{
                        __html: `Envie a parte de <b>trás</b> do seu diploma.`,
                      }}
                    />
                    <div className="flex flex-col w-full gap-y-5 items-center">
                      {documentFile_share5 ? (
                        documentFile_share5.type.includes("application") ? (
                          <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                        ) : (
                          <img
                            src={URL.createObjectURL(documentFile_share5)}
                            className="h-auto rounded-md w-full"
                            alt="Document"
                          />
                        )
                      ) : (
                        <img
                          src="/images/assets/backDiploma.webp"
                          className="h-auto rounded-md w-full"
                          alt="Document"
                        />
                      )}
                      {documentFile_share5 &&
                        documentFile_share5.type.includes("application") && (
                          <p className="line-clamp-1 text-center text-sm">
                            {documentFile_share5.name}
                          </p>
                        )}
                    </div>
                    <div className="my-2 flex w-full justify-center">
                      {documentFile_share5 !== undefined ? (
                        <ButtonSecondary
                          optionalMovingIcon={<HiArrowPath />}
                          textButton="Substituir"
                          desktopWidth="150px"
                          mobileWidth="150px"
                          disabled={studentInfos.userTeste}
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              5,
                              "graduation",
                              "back",
                              false
                            )
                          }
                        />
                      ) : (
                        <ButtonSecondary
                          verifyOnClick={() =>
                            handleAttachOrChangeFile(
                              5,
                              "graduation",
                              "back",
                              false
                            )
                          }
                          textButton="Anexar"
                          optionalMovingIcon={<HiMiniArrowUpTray />}
                          desktopWidth="120px"
                          mobileWidth="120px"
                          disabled={studentInfos.userTeste}
                        />
                      )}
                    </div>
                  </div>
                )}

              {documentInfos.currentStep !== null ? (
                <div className="flex w-full justify-end">
                  <ButtonPrimary
                    disabled={
                      !(
                        (documentInfos.currentStep === 0 &&
                          documentFile_share1 &&
                          documentFile_share2) ||
                        (documentInfos.currentStep === 0 &&
                          documentInfos.document[0].documents.length === 1 &&
                          (documentFile_share1 || documentFile_share2)) ||
                        (documentInfos.currentStep === 1 &&
                          documentFile_share3) ||
                        (documentInfos.currentStep === 2 &&
                          documentFile_share4 &&
                          documentFile_share5) ||
                        (documentInfos.currentStep === 2 &&
                          documentInfos.document[2].documents.length === 1 &&
                          (documentFile_share4 || documentFile_share5))
                      )
                    }
                    textButton="Próximo"
                    desktopWidth={150}
                    mobileWidth={150}
                    verifyOnClick={handleNextDocumentsStep}
                  />
                </div>
              ) : !correctionArea && documentInfos.currentStep === null ? (
                documentInfos.documentsDisapproved.length > 0 && (
                  <div className="flex w-full">
                    <ButtonPrimary
                      disabled={!documentInfos}
                      textButton="Corrigir"
                      verifyOnClick={handleCorrectionDocuments}
                      desktopWidth={120}
                    />
                  </div>
                )
              ) : null}

              {correctionArea && (
                <>
                  {correctionArea
                    .filter(
                      (item) =>
                        item.alias === "identification" && item.type === "front"
                    )
                    .map((item) => (
                      <>
                        <Blockquote
                          text={`A parte da frente do seu documento está <b>reprovado</b>.`}
                          observation={item.observation}
                          color={certifier.colors.steps.stepReproved}
                          icon={<HiExclamationTriangle />}
                        />
                        <div className="flex flex-col gap-y-2 items-center">
                          <span
                            className="text-textPrimary text-sm text-left"
                            dangerouslySetInnerHTML={{
                              __html: `Reenvie a parte da <b>frente</b> da sua CNH, RG ou CPF.`,
                            }}
                          />
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            {documentFile_share1 ? (
                              documentFile_share1.type.includes(
                                "application"
                              ) ? (
                                <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                              ) : (
                                <img
                                  src={URL.createObjectURL(documentFile_share1)}
                                  className="h-auto rounded-md w-full"
                                  alt="Document"
                                />
                              )
                            ) : (
                              <img
                                src="/images/assets/frontDocument.webp"
                                className="h-auto rounded-md w-full"
                                alt="Document"
                              />
                            )}
                            {documentFile_share1 &&
                              documentFile_share1.type.includes(
                                "application"
                              ) && (
                                <p className="line-clamp-1 text-center text-sm">
                                  {documentFile_share1.name}
                                </p>
                              )}
                          </div>
                          <div className="my-2 flex w-full justify-center">
                            {documentFile_share1 !== undefined ? (
                              <ButtonSecondary
                                optionalMovingIcon={<HiArrowPath />}
                                textButton="Substituir"
                                desktopWidth="150px"
                                mobileWidth="150px"
                                disabled={studentInfos.userTeste}
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    1,
                                    "identification",
                                    "front",
                                    true
                                  )
                                }
                              />
                            ) : (
                              <ButtonSecondary
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    1,
                                    "identification",
                                    "front",
                                    true
                                  )
                                }
                                textButton="Anexar"
                                optionalMovingIcon={<HiMiniArrowUpTray />}
                                desktopWidth="120px"
                                mobileWidth="120px"
                                disabled={studentInfos.userTeste}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                  {correctionArea
                    .filter(
                      (item) =>
                        item.alias === "identification" && item.type === "back"
                    )
                    .map((item) => (
                      <>
                        <Blockquote
                          text={`A parte de trás do seu documento está <b>reprovado</b>.`}
                          observation={item.observation}
                          color={certifier.colors.steps.stepReproved}
                          icon={<HiExclamationTriangle />}
                        />
                        <div className="flex flex-col gap-y-2 items-center">
                          <span
                            className="text-textPrimary text-sm text-left"
                            dangerouslySetInnerHTML={{
                              __html: `Reenvie a parte de <b>trás</b> da sua CNH, RG ou CPF.`,
                            }}
                          />
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            {documentFile_share2 ? (
                              documentFile_share2.type.includes(
                                "application"
                              ) ? (
                                <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                              ) : (
                                <img
                                  src={URL.createObjectURL(documentFile_share2)}
                                  className="h-auto rounded-md w-full"
                                  alt="Document"
                                />
                              )
                            ) : (
                              <img
                                src="/images/assets/backDocument.webp"
                                className="h-auto rounded-md w-full"
                                alt="Document"
                              />
                            )}
                            {documentFile_share2 &&
                              documentFile_share2.type.includes(
                                "application"
                              ) && (
                                <p className="line-clamp-1 text-center text-sm">
                                  {documentFile_share2.name}
                                </p>
                              )}
                          </div>
                          <div className="my-2 flex w-full justify-center">
                            {documentFile_share2 !== undefined ? (
                              <ButtonSecondary
                                optionalMovingIcon={<HiArrowPath />}
                                textButton="Substituir"
                                desktopWidth="150px"
                                disabled={studentInfos.userTeste}
                                mobileWidth="150px"
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    2,
                                    "identification",
                                    "back",
                                    true
                                  )
                                }
                              />
                            ) : (
                              <ButtonSecondary
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    2,
                                    "identification",
                                    "back",
                                    true
                                  )
                                }
                                textButton="Anexar"
                                optionalMovingIcon={<HiMiniArrowUpTray />}
                                desktopWidth="120px"
                                mobileWidth="120px"
                                disabled={studentInfos.userTeste}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                  {correctionArea
                    .filter(
                      (item) =>
                        item.alias === "checking" && item.type === "front"
                    )
                    .map((item) => (
                      <>
                        <Blockquote
                          text={`A parte da frente do seu documento está <b>reprovado</b>.`}
                          observation={item.observation}
                          color={certifier.colors.steps.stepReproved}
                          icon={<HiExclamationTriangle />}
                        />
                        <div className="flex flex-col gap-y-2 items-center">
                          <span
                            className="text-textPrimary text-sm text-left"
                            dangerouslySetInnerHTML={{
                              __html: `Reenvie a parte da <b>frente</b> do seu comprovante de endereço.`,
                            }}
                          />
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            {documentFile_share3 ? (
                              documentFile_share3.type.includes(
                                "application"
                              ) ? (
                                <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                              ) : (
                                <img
                                  src={URL.createObjectURL(documentFile_share3)}
                                  className="h-auto rounded-md w-full"
                                  alt="Document"
                                />
                              )
                            ) : (
                              <img
                                src="/images/assets/comprovanteEndereço.webp"
                                className="h-auto rounded-md w-full"
                                alt="Document"
                              />
                            )}
                            {documentFile_share3 &&
                              documentFile_share3.type.includes(
                                "application"
                              ) && (
                                <p className="line-clamp-1 text-center text-sm">
                                  {documentFile_share3.name}
                                </p>
                              )}
                          </div>
                          <div className="my-2 flex w-full justify-center">
                            {documentFile_share3 !== undefined ? (
                              <ButtonSecondary
                                optionalMovingIcon={<HiArrowPath />}
                                textButton="Substituir"
                                desktopWidth="150px"
                                disabled={studentInfos.userTeste}
                                mobileWidth="150px"
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    3,
                                    "checking",
                                    "front",
                                    true
                                  )
                                }
                              />
                            ) : (
                              <ButtonSecondary
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    3,
                                    "checking",
                                    "front",
                                    true
                                  )
                                }
                                textButton="Anexar"
                                optionalMovingIcon={<HiMiniArrowUpTray />}
                                desktopWidth="120px"
                                mobileWidth="120px"
                                disabled={studentInfos.userTeste}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                  {correctionArea
                    .filter(
                      (item) =>
                        item.alias === "graduation" && item.type === "front"
                    )
                    .map((item) => (
                      <>
                        <Blockquote
                          text={`A parte da frente do seu documento está <b>reprovado</b>.`}
                          observation={item.observation}
                          color={certifier.colors.steps.stepReproved}
                          icon={<HiExclamationTriangle />}
                        />
                        <div className="flex flex-col gap-y-2 items-center">
                          <span
                            className="text-textPrimary text-sm text-left"
                            dangerouslySetInnerHTML={{
                              __html: `Reenvie a parte da <b>frente</b> do seu diploma.`,
                            }}
                          />
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            {documentFile_share4 ? (
                              documentFile_share4.type.includes(
                                "application"
                              ) ? (
                                <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                              ) : (
                                <img
                                  src={URL.createObjectURL(documentFile_share4)}
                                  className="h-auto rounded-md w-full"
                                  alt="Document"
                                />
                              )
                            ) : (
                              <img
                                src="/images/assets/frontDiploma.webp"
                                className="h-auto rounded-md w-full"
                                alt="Document"
                              />
                            )}
                            {documentFile_share4 &&
                              documentFile_share4.type.includes(
                                "application"
                              ) && (
                                <p className="line-clamp-1 text-center text-sm">
                                  {documentFile_share4.name}
                                </p>
                              )}
                          </div>
                          <div className="my-2 flex w-full justify-center">
                            {documentFile_share4 !== undefined ? (
                              <ButtonSecondary
                                optionalMovingIcon={<HiArrowPath />}
                                textButton="Substituir"
                                desktopWidth="150px"
                                disabled={studentInfos.userTeste}
                                mobileWidth="150px"
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    4,
                                    "graduation",
                                    "front",
                                    true
                                  )
                                }
                              />
                            ) : (
                              <ButtonSecondary
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    4,
                                    "graduation",
                                    "front",
                                    true
                                  )
                                }
                                textButton="Anexar"
                                optionalMovingIcon={<HiMiniArrowUpTray />}
                                desktopWidth="120px"
                                mobileWidth="120px"
                                disabled={studentInfos.userTeste}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                  {correctionArea
                    .filter(
                      (item) =>
                        item.alias === "graduation" && item.type === "back"
                    )
                    .map((item) => (
                      <>
                        <Blockquote
                          text={`A parte de trás do seu documento está <b>reprovado</b>.`}
                          observation={item.observation}
                          color={certifier.colors.steps.stepReproved}
                          icon={<HiExclamationTriangle />}
                        />
                        <div className="flex flex-col gap-y-2 items-center">
                          <span
                            className="text-textPrimary text-sm text-left"
                            dangerouslySetInnerHTML={{
                              __html: `Reenvie a parte de <b>trás</b> do seu diploma.`,
                            }}
                          />
                          <div className="flex flex-col w-full gap-y-5 items-center">
                            {documentFile_share5 ? (
                              documentFile_share5.type.includes(
                                "application"
                              ) ? (
                                <HiDocument className="text-[#64758b] pt-3 w-10 h-auto rounded-m" />
                              ) : (
                                <img
                                  src={URL.createObjectURL(documentFile_share5)}
                                  className="h-auto rounded-md w-full"
                                  alt="Document"
                                />
                              )
                            ) : (
                              <img
                                src="/images/assets/backDiploma.webp"
                                className="h-auto rounded-md w-full"
                                alt="Document"
                              />
                            )}
                            {documentFile_share5 &&
                              documentFile_share5.type.includes(
                                "application"
                              ) && (
                                <p className="line-clamp-1 text-center text-sm">
                                  {documentFile_share5.name}
                                </p>
                              )}
                          </div>
                          <div className="my-2 flex w-full justify-center">
                            {documentFile_share5 !== undefined ? (
                              <ButtonSecondary
                                optionalMovingIcon={<HiArrowPath />}
                                textButton="Substituir"
                                desktopWidth="150px"
                                disabled={studentInfos.userTeste}
                                mobileWidth="150px"
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    5,
                                    "graduation",
                                    "back",
                                    true
                                  )
                                }
                              />
                            ) : (
                              <ButtonSecondary
                                verifyOnClick={() =>
                                  handleAttachOrChangeFile(
                                    5,
                                    "graduation",
                                    "back",
                                    true
                                  )
                                }
                                textButton="Anexar"
                                optionalMovingIcon={<HiMiniArrowUpTray />}
                                desktopWidth="120px"
                                mobileWidth="120px"
                                disabled={studentInfos.userTeste}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  <div
                    className={`w-full flex justify-end`}
                  >
                    <ButtonPrimary
                      disabled={disapprovedQuantity && disapprovedQuantity > 0}
                      textButton="Finalizar"
                      desktopWidth={150}
                      mobileWidth={120}
                      verifyOnClick={handleNextDocumentsStep}
                    />
                  </div>
                </>
              )}

              {documentInfos.document[0].status === "approved" &&
                documentInfos.document[1].status === "approved" &&
                documentInfos.document[2].status === "approved" && (
                  <Link to="/studentCourses" className="flex w-full">
                    <ButtonPrimary
                      disabled={disapprovedQuantity && disapprovedQuantity > 0}
                      textButton="Estudar"
                      desktopWidth={120}
                      verifyOnClick={handleNextDocumentsStep}
                    />
                  </Link>
                )}
            </div>
          </div>
        </section>
      )}
    </main>
  );
}

export default verifyAuth(DocumentsPage);
