import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ListCourses from "../../components/ListCourses/ListCourses";
import { useLocation, useParams } from "react-router-dom";
import {
  getCoursesByAreaAlias,
  getCoursesRecommendedRequest
} from "../../services/dashboardService/dashboard.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import "./specifiedAreaPage.css";

function SpecifiedAreaPage({ recommendedCourses }) {
  const [courseLists, setCourseLists] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const areaAlias = useParams().areaAlias;
  const location = useLocation();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const typeCourse = getQueryParam("typeCourse");

  useEffect(() => {
    const fetchAreasInfo = async () => {
      try {
        const student = await getStudentInfos();
        let arrayCourses = [];
        if (!recommendedCourses) {
          // get --- START
          arrayCourses = await getCoursesByAreaAlias({
            sessionId: student.sessionId,
            areaAlias: areaAlias
          });
          if (arrayCourses.status !== 200) {
            Toastify("error", "Erro ao buscar informações de cursos.");
            console.error(
              "Erro ao buscar informações de cursos - 1:",
              arrayCourses.status
            );
          }
          // get --- END
        } else {
          // get --- START
          arrayCourses = await getCoursesRecommendedRequest({
            sessionId: student.sessionId,
            viewAll: true
          });
          if (arrayCourses.status !== 200) {
            Toastify("error", "Erro ao buscar informações de cursos. - 3");
            console.error(
              "Erro ao buscar informações de cursos - 4:",
              arrayCourses.status
            );
          }
          // get --- END
        }
        try {
          setCourseLists(arrayCourses.data);
        } catch {
          Toastify("error", "Erro ao processar itens da área: #01");
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar área.");
        console.error("Erro ao buscar área: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAreasInfo();
  }, [areaAlias, recommendedCourses]);
  if (typeof recommendedCourses === "undefined")
    throw new Error("Pagina quebrada artificialmente!");

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        mobileMainText={courseLists ? courseLists.areaName : "Cursos"}
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar />
      <LoadingSection isLoading={isLoading} />
      {courseLists && (
        <ListCourses
          isAvailableCourses={typeCourse}
          allCourses={courseLists.courses}
        />
      )}
    </main>
  );
}

export default verifyAuth(SpecifiedAreaPage);
