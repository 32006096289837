import { Link } from "react-router-dom";
import "./GuestFooter.css";
import moment from 'moment';
import 'moment/locale/pt-br';
import { certifier } from "../../mocks/certifier";

function GuestFooter() {
    return(
        <footer className="w-full flex max-lg:flex-col justify-between items-end max-lg:px-[1rem] px-28 py-6">
          <div className="flex flex-col gap-7 max-lg:w-full max-lg:items-center">
            <img
              src={`/images/logos/logo-text-on-dark.webp`}
              alt="Logo"
              className="max-lg:w-full max-w-[150px] w-[40%]"
            />
            <span className="font-normal text-textSecondary text-sm text-center">
              © {moment().format('YYYY')} {certifier.generalSettings.name} | Todos os direitos reservados.
            </span>
          </div>
          <div className="flex gap-x-8 justify-center items-center max-lg:w-full max-lg:flex-col max-lg:py-5" >
            <Link to="/support">
              <span className="link-style">Suporte</span>
            </Link>
            <Link to="/terms-of-use">
              <span className="link-style">Termos de Uso</span>
            </Link>
            <Link to="/privacy-policy">
              <span className="link-style">Políticas de Privacidade</span>
            </Link>
          </div>
        </footer>
    )
}

export default GuestFooter;