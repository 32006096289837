export default function StarRating({ averageStars }) {
    const MAX_STARS = 5;
    const stars = [];
  
    for (let i = 1; i <= MAX_STARS; i++) {
      let starFillPercentage = 0;
  
      if (averageStars >= i) {
        starFillPercentage = 100;
      } else if (averageStars > i - 1) {
        starFillPercentage = (averageStars - (i - 1)) * 100;
      } else {
        starFillPercentage = 0;
      }
  
      stars.push(
        <div key={i} className="relative w-5 h-5">
          {/* Empty Star */}
          <img
            src="/images/assets/star_empty.svg"
            alt="Empty star"
            className="w-full h-full"
          />
  
          {/* Filled Star */}
          <img
            src="/images/assets/star_filled.svg"
            alt="Filled star"
            className="absolute top-0 left-0 w-full h-full"
            style={{
              clipPath: `inset(0% ${100 - starFillPercentage}% 0% 0%)`
            }}
          />
        </div>
      );
    }
  
    return <div className="flex gap-1">{stars}</div>;
  }