import axios from "axios";

export const viaCep = async (cep) => {
  try {
    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err) {
    return {
      status: err.response ? err.response.data.code : 500,
      data: err.response.data.message,
    };
  }
};
