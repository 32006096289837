import React, { useEffect, useState } from "react";
import "./login.css";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import Input from "../../components/Input/input";
import { loginRequest } from "../../services/loginService/login.service";
import moment from "moment";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import "react-toastify/dist/ReactToastify.css";
import { Toastify } from "../../components/Toastify/Toastify";
import NoAuthRequired from "../../services/noAuthRequired/noAuthRequired";
import GuestFooter from "../../components/Footers/GuestFooter";
import { certifier } from "../../mocks/certifier";
import { serverTime } from "../../services/serverTime/serverTime";

const sign = require("jwt-encode");

function Login() {
  const [submitState, setSubmitState] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    keepConected: false,
  });

  const [redirectUrl, setRedirectUrl] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get("redirect");
    if (redirect) {
      setRedirectUrl(decodeURIComponent(redirect));
    }
  }, []);

  const statusMessages = [
    {
      code: 403,
      message: "Senha inválida.",
    },
    {
      code: 404,
      message: "Usuário não encontrado.",
    },
    {
      code: 500,
      message: "Ocorreu algum erro interno. Tente novamente em alguns minutos.",
    },
    {
      code: 503,
      message: "Ocorreu um erro interno.",
    },
    {
      code: 5001,
      message: "E-mail vazio.",
    },
    {
      code: 5002,
      message: "E-mail ou senha inválida.",
    },
    {
      code: 5003,
      message: "Senha vazia.",
    },
    {
      code: 5004,
      message: "Senha insuficiente.",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleCheckbox = (e) => {
    const { checked } = e.target;
    setLoginData({ ...loginData, keepConected: checked });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitState(true);

    if (loginData.email === "") {
      Toastify("error", statusMessages[4].message);
      setLoginData({ ...loginData, email: "", password: "" });
      setSubmitState(false);
      return;
    }
    if (loginData.email.indexOf("@") === -1) {
      Toastify("error", statusMessages[5].message);
      setSubmitState(false);
      return;
    }
    if (loginData.password === "") {
      Toastify("error", statusMessages[6].message);
      setSubmitState(false);
      return;
    }
    if (loginData.password.length < 6 || loginData.password.length > 30) {
      Toastify("error", statusMessages[7].message);
      setSubmitState(false);
      return;
    }

    const timeServer = await serverTime();

    let iat = moment().unix();
    let exp = moment().add(15, "hours").unix();

    if (timeServer.status === 200) {
      const serverTimeISO = timeServer.data;
      iat = moment(serverTimeISO).unix();
      exp = iat + 15 * 3600;
    }

    const form_data = {
      login: loginData.email,
      pass: await CryptoService(loginData.password, "encrypt-jwt"),
      keepConected: loginData.keepConected,
      environment: process.env.REACT_APP_API_ENVIRONMENT,
      exp,
      iat
    };

    const post_data = sign(form_data, process.env.REACT_APP_JWT_KEY);

    loginRequest(post_data)
      .then(async (response) => {
        if (response.status === 200) {
          try {
            const ciphertext = await CryptoService(
              response.sessionResponse,
              "encrypt-crypto"
            );
            localStorage.setItem("user", ciphertext);
            if (response.sessionResponse.toReplace) {
              window.location.href = "/newRecovery";
            } else {
              if (redirectUrl) {
                window.location.href = redirectUrl;
              } else {
                window.location.href = "/dashboard";
              }
            }
          } catch {
            window.location.reload();
          }
        } else {
          Toastify(
            "error",
            `${statusMessages.find((message) => message.code === response.status)
              ?.message ||
            "Ocorreu um erro interno. Tente novamente em alguns minutos!"
            }`
          );
          if (response.status === 404) {
            setLoginData({ ...loginData, email: "", password: "" });
          } else if (response.status === 403) {
            setLoginData({ ...loginData, password: "" });
          }
        }
      })
      .catch((error) => {
        if (
          statusMessages.find((message) => message.code === error.status)
            ?.message ||
          "Ocorreu um erro interno, tente novamente em alguns minutos."
        ) {
          Toastify(
            "error",
            statusMessages.find((message) => message.code === error.status)
              ?.message ||
            "Ocorreu um erro interno. Tente novamente em alguns minutos..."
          );
        } else {
          Toastify("error", "Ocorreu um erro desconhecido.");
        }
      })
      .finally(() => {
        setSubmitState(false);
      });
  }

  return (
    <main className="flex w-screen h-screen max-lg:h-full overflow-hidden bg-backgroundOne text-textPrimary">
      <div className="w-fit min-w-[25vw] flex flex-col relative max-lg:hidden">
        <div className="flex w-full items-center justify-center">
          <img
            src={`/images/logos/logo-text-on-${certifier.colors.basics.primary_darkTheme ? "light" : "dark"}.webp`}
            alt="Logo"
            className="absolute w-1/3 top-5 z-[2]"
          />
        </div>
        <div className="w-auto h-full relative overflow-hidden">
          <img
            src={`/images/WebPageLogos/imageLogin.webp`}
            alt="Imagem da Logo"
            className="w-full h-full object-cover pointer-events-none select-none"
          />
        </div>
      </div>

      <section className="w-full h-full overflow-y-auto flex flex-col justify-between items-center">
        <div className="hidden max-lg:flex w-full h-full">
          <img
            src="/images/WebPageLogos/imageLogin-mobile.webp"
            alt="Logo"
            className="w-full object-cover"
          />
          <div className="absolute bottom-0 w-full bg-backgroundOne h-3 rounded-t-xl" />
        </div>
        <section className="max-lg:w-full max-lg:px-[5vw] w-[45%] mt-[10%] flex flex-col max-lg:pb-16 items-start gap-y-10">
          <div className="flex flex-col items-start gap-5 max-lg:gap-3">
            <h1 className="font-semibold max-lg:text-[5vw] text-[2rem] text-left leading-none">
              Bem-vindo {certifier.generalSettings.grammatical.pronoun_introduction} {certifier.generalSettings.shortName}
            </h1>
            <div>
              <span className="span-small-white leading-none">
                Novo por aqui?
              </span>
              {` `}
              <Link to={certifier.generalSettings.url.mainWebsite}>
                <span className="link-style leading-none">
                  Conheça nossos cursos
                </span>
              </Link>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="login-right-form flex flex-col gap-y-7 w-full"
          >
            <div className="flex flex-col gap-y-3 w-full">
              <label className="span-small-white">E-mail</label>
              <Input
                type="email"
                name="email"
                value={loginData.email}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col gap-y-3 w-full">
              <label className="span-small-white">Senha</label>
              <Input
                type="password"
                name="password"
                value={loginData.password}
                onChange={handleChange}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <label className="check-container">
                  <input
                    type="checkbox"
                    id="keepConnected"
                    name="keepConnected"
                    onChange={handleCheckbox}
                  />
                  <span className="checkmark" />
                </label>

                <label
                  className="font-normal text-sm select-none"
                  htmlFor="keepConnected"
                >
                  Manter conectado
                </label>
              </div>
              <Link to="/recoveryPass">
                <span className="link-style">Esqueci minha senha</span>
              </Link>
            </div>

            <div className="flex justify-center">
              <ButtonPrimary
                textButton="Acessar"
                desktopWidth="35%"
                mobileWidth="60%"
                disabled={submitState}
              />
            </div>
          </form>
        </section>

        <GuestFooter />
      </section>
    </main>
  );
}

export default NoAuthRequired(Login);
