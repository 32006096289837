import { useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import * as Sentry from "@sentry/react";

const HomePage = (props) => {
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const student = await getStudentInfos();
        if (student && moment().unix() < student.exp) {
          window.location.href = "/dashboard";
        } else {
          Sentry.setUser(null);
          try {
            localStorage.removeItem("user");
          } catch (localStorageError) {
            console.error(
              "Failed to remove user from localStorage:",
              localStorageError
            );
          }
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Erro ao verificar autenticação:", error);
        Sentry.setUser(null);
        try {
          localStorage.removeItem("user");
        } catch (localStorageError) {
          console.error(
            "Failed to remove user from localStorage:",
            localStorageError
          );
        }
        window.location.href = "/login";
      }
    };

    checkAuth();
  }, [props.token]);

  return null;
};

export default HomePage;
