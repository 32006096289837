export const nationalitysSelect = [
  {
    fromForm: "nationality",
    value: "Afegão(ã)",
    label: "Afegão(ã)",
  },
  {
    fromForm: "nationality",
    value: "Albanês(a)",
    label: "Albanês(a)",
  },
  
  {
    fromForm: "nationality",
    value: "Alemão",
    label: "Alemão",
  },
  {
    fromForm: "nationality",
    value: "Americano",
    label: "Americano",
  },
  {
    fromForm: "nationality",
    value: "Andorrano(a)",
    label: "Andorrano(a)",
  },
  {
    fromForm: "nationality",
    value: "Angolano(a)",
    label: "Angolano(a)",
  },
  {
    fromForm: "nationality",
    value: "Antiguano(a)",
    label: "Antiguano(a)",
  },
  {
    fromForm: "nationality",
    value: "Árabe",
    label: "Árabe",
  },
  {
    fromForm: "nationality",
    value: "Argelino(a)",
    label: "Argelino(a)",
  },
  {
    fromForm: "nationality",
    value: "Argentino(a)",
    label: "Argentino(a)",
  },
  {
    fromForm: "nationality",
    value: "Armênio(a)",
    label: "Armênio(a)",
  },
  {
    fromForm: "nationality",
    value: "Australiano(a)",
    label: "Australiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Austríaco(a)",
    label: "Austríaco(a)",
  },
  {
    fromForm: "nationality",
    value: "Azerbaijano(a)",
    label: "Azerbaijano(a)",
  },
  {
    fromForm: "nationality",
    value: "Bahamiano(a)",
    label: "Bahamiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Barbadiano(a)",
    label: "Barbadiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Bareinita",
    label: "Bareinita",
  },
  {
    fromForm: "nationality",
    value: "Bechuano(a)",
    label: "Bechuano(a)",
  },
  {
    fromForm: "nationality",
    value: "Belga",
    label: "Belga",
  },
  {
    fromForm: "nationality",
    value: "Belizenho(a)",
    label: "Belizenho(a)",
  },
  {
    fromForm: "nationality",
    value: "Bengalês(a)",
    label: "Bengalês(a)",
  },
  {
    fromForm: "nationality",
    value: "Beninense",
    label: "Beninense",
  },
  {
    fromForm: "nationality",
    value: "Bielo-Russo(a)",
    label: "Bielo-Russo(a)",
  },
  {
    fromForm: "nationality",
    value: "Birmanês(a)",
    label: "Birmanês(a)",
  },
  {
    fromForm: "nationality",
    value: "Boliviano(a)",
    label: "Boliviano(a)",
  },
  {
    fromForm: "nationality",
    value: "Brasileira",
    label: "Brasileira",
  },
  {
    fromForm: "nationality",
    value: "Bruneano(a)",
    label: "Bruneano(a)",
  },
  {
    fromForm: "nationality",
    value: "Burquinense",
    label: "Burquinense",
  },
  {
    fromForm: "nationality",
    value: "Burundinês(a)",
    label: "Burundinês(a)",
  },
  {
    fromForm: "nationality",
    value: "Butanês(a)",
    label: "Butanês(a)",
  },
  {
    fromForm: "nationality",
    value: "Bósnio(a)",
    label: "Bósnio(a)",
  },
  {
    fromForm: "nationality",
    value: "Búlgaro(a)",
    label: "Búlgaro(a)",
  },
  {
    fromForm: "nationality",
    value: "Caboverdiano(a)",
    label: "Caboverdiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Camaronês(a)",
    label: "Camaronês(a)",
  },
  {
    fromForm: "nationality",
    value: "Cambojano(a)",
    label: "Cambojano(a)",
  },
  {
    fromForm: "nationality",
    value: "Canadense",
    label: "Canadense",
  },
  {
    fromForm: "nationality",
    value: "Catariano(a)",
    label: "Catariano(a)",
  },
  {
    fromForm: "nationality",
    value: "Cazaque",
    label: "Cazaque",
  },
  {
    fromForm: "nationality",
    value: "Congolês(a)",
    label: "Congolês(a)",
  },
  {
    fromForm: "nationality",
    value: "Centro-Africano(a)",
    label: "Centro-Africano(a)",
  },
  {
    fromForm: "nationality",
    value: "Chadiano(a)",
    label: "Chadiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Chileno(a)",
    label: "Chileno(a)",
  },
  {
    fromForm: "nationality",
    value: "Chinês",
    label: "Chinês",
  },
  {
    fromForm: "nationality",
    value: "Cingalês(a)",
    label: "Cingalês(a)",
  },
  {
    fromForm: "nationality",
    value: "Cipriota",
    label: "Cipriota",
  },
  {
    fromForm: "nationality",
    value: "Colombiano(a)",
    label: "Colombiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Costa-Marfinense",
    label: "Costa-Marfinense",
  },
  {
    fromForm: "nationality",
    value: "Costarriquenho(a)",
    label: "Costarriquenho(a)",
  },
  {
    fromForm: "nationality",
    value: "Croata",
    label: "Croata",
  },
  {
    fromForm: "nationality",
    value: "Cubano(a)",
    label: "Cubano(a)",
  },
  {
    fromForm: "nationality",
    value: "Dinamarquês",
    label: "Dinamarquês",
  },
  {
    fromForm: "nationality",
    value: "Djibutiano(a)",
    label: "Djibutiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Dominicano(a)",
    label: "Dominicano(a)",
  },
  {
    fromForm: "nationality",
    value: "Dominicano(a)",
    label: "Dominicano(a)",
  },
  {
    fromForm: "nationality",
    value: "Egípcio(a)",
    label: "Egípcio(a)",
  },
  {
    fromForm: "nationality",
    value: "Equatoriano(a)",
    label: "Equatoriano(a)",
  },
  {
    fromForm: "nationality",
    value: "Eritreu(a)",
    label: "Eritreu(a)",
  },
  {
    fromForm: "nationality",
    value: "Eslovaco(a)",
    label: "Eslovaco(a)",
  },
  {
    fromForm: "nationality",
    value: "Esloveno(a)",
    label: "Esloveno(a)",
  },
  {
    fromForm: "nationality",
    value: "Espanhol",
    label: "Espanhol",
  },
  {
    fromForm: "nationality",
    value: "Estoniano(a)",
    label: "Estoniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Etíope",
    label: "Etíope",
  },
  {
    fromForm: "nationality",
    value: "Fijiano(a)",
    label: "Fijiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Filipino(a)",
    label: "Filipino(a)",
  },
  {
    fromForm: "nationality",
    value: "Finlandês(a)",
    label: "Finlandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Francês",
    label: "Francês",
  },
  {
    fromForm: "nationality",
    value: "Gabonês(a)",
    label: "Gabonês(a)",
  },
  {
    fromForm: "nationality",
    value: "Gambiano(a)",
    label: "Gambiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Ganês(a)",
    label: "Ganês(a)",
  },
  {
    fromForm: "nationality",
    value: "Georgiano(a)",
    label: "Georgiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Granadino(a)",
    label: "Granadino(a)",
  },
  {
    fromForm: "nationality",
    value: "Grego(a)",
    label: "Grego(a)",
  },
  {
    fromForm: "nationality",
    value: "Guatemalteco(a)",
    label: "Guatemalteco(a)",
  },
  {
    fromForm: "nationality",
    value: "Guianês(a)",
    label: "Guianês(a)",
  },
  {
    fromForm: "nationality",
    value: "Guineano(a)",
    label: "Guineano(a)",
  },
  {
    fromForm: "nationality",
    value: "Guineense",
    label: "Guineense",
  },
  {
    fromForm: "nationality",
    value: "Guinéu-Equatoriano(a)",
    label: "Guinéu-Equatoriano(a)",
  },
  {
    fromForm: "nationality",
    value: "Haitiano(a)",
    label: "Haitiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Holandês(a)",
    label: "Holandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Hondurenho(a)",
    label: "Hondurenho(a)",
  },
  {
    fromForm: "nationality",
    value: "Húngaro(a)",
    label: "Húngaro(a)",
  },
  {
    fromForm: "nationality",
    value: "Iemenita",
    label: "Iemenita",
  },
  {
    fromForm: "nationality",
    value: "Indiano(a)",
    label: "Indiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Indonésio(a)",
    label: "Indonésio(a)",
  },
  {
    fromForm: "nationality",
    value: "Inglês(a)",
    label: "Inglês(a)",
  },
  {
    fromForm: "nationality",
    value: "Iraniano(a)",
    label: "Iraniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Iraquiano(a)",
    label: "Iraquiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Irlandês(a)",
    label: "Irlandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Islandês(a)",
    label: "Islandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Israelense",
    label: "Israelense",
  },
  {
    fromForm: "nationality",
    value: "Italiano",
    label: "Italiano",
  },
  {
    fromForm: "nationality",
    value: "Jamaicano(a)",
    label: "Jamaicano(a)",
  },
  {
    fromForm: "nationality",
    value: "Japonês",
    label: "Japonês",
  },
  {
    fromForm: "nationality",
    value: "Jordaniano(a)",
    label: "Jordaniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Kuwaitiano(a)",
    label: "Kuwaitiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Laosiano(a)",
    label: "Laosiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Leonês(a)",
    label: "Leonês(a)",
  },
  {
    fromForm: "nationality",
    value: "Lesoto(a)",
    label: "Lesoto(a)",
  },
  {
    fromForm: "nationality",
    value: "Letão(a)",
    label: "Letão(a)",
  },
  {
    fromForm: "nationality",
    value: "Libanês(a)",
    label: "Libanês(a)",
  },
  {
    fromForm: "nationality",
    value: "Liberiano(a)",
    label: "Liberiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Liechtensteinense",
    label: "Liechtensteinense",
  },
  {
    fromForm: "nationality",
    value: "Lituano(a)",
    label: "Lituano(a)",
  },
  {
    fromForm: "nationality",
    value: "Luxemburguês(a)",
    label: "Luxemburguês(a)",
  },
  {
    fromForm: "nationality",
    value: "Líbio(a)",
    label: "Líbio(a)",
  },
  {
    fromForm: "nationality",
    value: "Macedônio(a)",
    label: "Macedônio(a)",
  },
  {
    fromForm: "nationality",
    value: "Malaio(a)",
    label: "Malaio(a)",
  },
  {
    fromForm: "nationality",
    value: "Malauiano(a)",
    label: "Malauiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Maldivo(a)",
    label: "Maldivo(a)",
  },
  {
    fromForm: "nationality",
    value: "Malgaxe",
    label: "Malgaxe",
  },
  {
    fromForm: "nationality",
    value: "Malinês(a)",
    label: "Malinês(a)",
  },
  {
    fromForm: "nationality",
    value: "Maltês(a)",
    label: "Maltês(a)",
  },
  {
    fromForm: "nationality",
    value: "Marroquino(a)",
    label: "Marroquino(a)",
  },
  {
    fromForm: "nationality",
    value: "Marshalino(a)",
    label: "Marshalino(a)",
  },
  {
    fromForm: "nationality",
    value: "Mauriciano(a)",
    label: "Mauriciano(a)",
  },
  {
    fromForm: "nationality",
    value: "Mauritano(a)",
    label: "Mauritano(a)",
  },
  {
    fromForm: "nationality",
    value: "Mexicano",
    label: "Mexicano",
  },
  {
    fromForm: "nationality",
    value: "Micronésio(a)",
    label: "Micronésio(a)",
  },
  {
    fromForm: "nationality",
    value: "Moldávio(a)",
    label: "Moldávio(a)",
  },
  {
    fromForm: "nationality",
    value: "Monegasco(a)",
    label: "Monegasco(a)",
  },
  {
    fromForm: "nationality",
    value: "Mongol",
    label: "Mongol",
  },
  {
    fromForm: "nationality",
    value: "Montenegrino(a)",
    label: "Montenegrino(a)",
  },
  {
    fromForm: "nationality",
    value: "Moçambicano(a)",
    label: "Moçambicano(a)",
  },
  {
    fromForm: "nationality",
    value: "Namibiano(a)",
    label: "Namibiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Nauruano(a)",
    label: "Nauruano(a)",
  },
  {
    fromForm: "nationality",
    value: "Neozelandês(a)",
    label: "Neozelandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Nepalês(a)",
    label: "Nepalês(a)",
  },
  {
    fromForm: "nationality",
    value: "Nicaraguense",
    label: "Nicaraguense",
  },
  {
    fromForm: "nationality",
    value: "Nigeriano(a)",
    label: "Nigeriano(a)",
  },
  {
    fromForm: "nationality",
    value: "Nigerino(a)",
    label: "Nigerino(a)",
  },
  {
    fromForm: "nationality",
    value: "Norte-Coreano(a)",
    label: "Norte-Coreano(a)",
  },
  {
    fromForm: "nationality",
    value: "Norueguês(a)",
    label: "Norueguês(a)",
  },
  {
    fromForm: "nationality",
    value: "Omani",
    label: "Omani",
  },
  {
    fromForm: "nationality",
    value: "Palauano(a)",
    label: "Palauano(a)",
  },
  {
    fromForm: "nationality",
    value: "Palestino(a)",
    label: "Palestino(a)",
  },
  {
    fromForm: "nationality",
    value: "Panamenho(a)",
    label: "Panamenho(a)",
  },
  {
    fromForm: "nationality",
    value: "Papuásio(a)",
    label: "Papuásio(a)",
  },
  {
    fromForm: "nationality",
    value: "Paquistanês(a)",
    label: "Paquistanês(a)",
  },
  {
    fromForm: "nationality",
    value: "Paraguaio(a)",
    label: "Paraguaio(a)",
  },
  {
    fromForm: "nationality",
    value: "Peruano(a)",
    label: "Peruano(a)",
  },
  {
    fromForm: "nationality",
    value: "Polonês(a)",
    label: "Polonês(a)",
  },
  {
    fromForm: "nationality",
    value: "Português",
    label: "Português",
  },
  {
    fromForm: "nationality",
    value: "Queniano(a)",
    label: "Queniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Quirguiz",
    label: "Quirguiz",
  },
  {
    fromForm: "nationality",
    value: "Quiribatiano(a)",
    label: "Quiribatiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Romeno(a)",
    label: "Romeno(a)",
  },
  {
    fromForm: "nationality",
    value: "Ruandês(a)",
    label: "Ruandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Russo",
    label: "Russo",
  },
  {
    fromForm: "nationality",
    value: "Salomônico(a)",
    label: "Salomônico(a)",
  },
  {
    fromForm: "nationality",
    value: "Salvadorenho(a)",
    label: "Salvadorenho(a)",
  },
  {
    fromForm: "nationality",
    value: "Samarinês(a)",
    label: "Samarinês(a)",
  },
  {
    fromForm: "nationality",
    value: "Samoano(a)",
    label: "Samoano(a)",
  },
  {
    fromForm: "nationality",
    value: "Santa-luciens",
    label: "Santa-luciens",
  },
  {
    fromForm: "nationality",
    value: "Saudito(a)",
    label: "Saudito(a)",
  },
  {
    fromForm: "nationality",
    value: "Seichelense",
    label: "Seichelense",
  },
  {
    fromForm: "nationality",
    value: "Senegalês(a)",
    label: "Senegalês(a)",
  },
  {
    fromForm: "nationality",
    value: "Singapuriano(a)",
    label: "Singapuriano(a)",
  },
  {
    fromForm: "nationality",
    value: "Somali",
    label: "Somali",
  },
  {
    fromForm: "nationality",
    value: "Suazi",
    label: "Suazi",
  },
  {
    fromForm: "nationality",
    value: "Sudanês(a)",
    label: "Sudanês(a)",
  },
  {
    fromForm: "nationality",
    value: "Sul-Africano(a)",
    label: "Sul-Africano(a)",
  },
  {
    fromForm: "nationality",
    value: "Sul-Coreano(a)",
    label: "Sul-Coreano(a)",
  },
  {
    fromForm: "nationality",
    value: "Surinamês(a)",
    label: "Surinamês(a)",
  },
  {
    fromForm: "nationality",
    value: "Suéco(a)",
    label: "Suéco(a)",
  },
  {
    fromForm: "nationality",
    value: "Suíço(a)",
    label: "Suíço(a)",
  },
  {
    fromForm: "nationality",
    value: "São-cristovense",
    label: "São-cristovense",
  },
  {
    fromForm: "nationality",
    value: "São-tomense",
    label: "São-tomense",
  },
  {
    fromForm: "nationality",
    value: "São-vicentino(a)",
    label: "São-vicentino(a)",
  },
  {
    fromForm: "nationality",
    value: "Sérvio(a)",
    label: "Sérvio(a)",
  },
  {
    fromForm: "nationality",
    value: "Sírio(a)",
    label: "Sírio(a)",
  },
  {
    fromForm: "nationality",
    value: "Tailandês(a)",
    label: "Tailandês(a)",
  },
  {
    fromForm: "nationality",
    value: "Tajique",
    label: "Tajique",
  },
  {
    fromForm: "nationality",
    value: "Tanzaniano(a)",
    label: "Tanzaniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Tcheco(a)",
    label: "Tcheco(a)",
  },
  {
    fromForm: "nationality",
    value: "Timorense",
    label: "Timorense",
  },
  {
    fromForm: "nationality",
    value: "Togonês(a)",
    label: "Togonês(a)",
  },
  {
    fromForm: "nationality",
    value: "Tongalês(a)",
    label: "Tongalês(a)",
  },
  {
    fromForm: "nationality",
    value: "Trinitino(a)",
    label: "Trinitino(a)",
  },
  {
    fromForm: "nationality",
    value: "Tunisiano(a)",
    label: "Tunisiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Turco(a)",
    label: "Turco(a)",
  },
  {
    fromForm: "nationality",
    value: "Turcomeno(a)",
    label: "Turcomeno(a)",
  },
  {
    fromForm: "nationality",
    value: "Tuvaluano(a)",
    label: "Tuvaluano(a)",
  },
  {
    fromForm: "nationality",
    value: "Ucraniano(a)",
    label: "Ucraniano(a)",
  },
  {
    fromForm: "nationality",
    value: "Ugandense",
    label: "Ugandense",
  },
  {
    fromForm: "nationality",
    value: "Uruguaio(a)",
    label: "Uruguaio(a)",
  },
  {
    fromForm: "nationality",
    value: "Usbeque",
    label: "Usbeque",
  },
  {
    fromForm: "nationality",
    value: "Vanuatuense",
    label: "Vanuatuense",
  },
  {
    fromForm: "nationality",
    value: "Venezuelano(a)",
    label: "Venezuelano(a)",
  },
  {
    fromForm: "nationality",
    value: "Vietnamita",
    label: "Vietnamita",
  },
  {
    fromForm: "nationality",
    value: "Zambiano(a)",
    label: "Zambiano(a)",
  },
  {
    fromForm: "nationality",
    value: "Zimbabuano(a)",
    label: "Zimbabuano(a)",
  },
];