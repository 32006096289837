import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { HiOutlineArrowSmallRight } from "react-icons/hi2";
import { certifier } from "../../mocks/certifier";

export default function Page404() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{certifier.generalSettings.name} | Página não encontrada.</title>
        <meta
          property="og:title"
          content={`${certifier.generalSettings.name} | Página não encontrada.`}
        />
        <meta
          name="apple-mobile-web-app-title"
          content={`${certifier.generalSettings.name} | Página não encontrada.`}
        />
      </Helmet>
      <section className="overflow-hidden min-h-screen flex flex-col justify-between">
        <section className="flex flex-col justify-center items-center max-lg:px-10 max-lg:text-center h-[100vh]">
          <h1 className="text-themeColor text-[12.5rem] font-bold min-[769px]:leading-[13.75rem] max-lg:text-[5rem]">
            404
          </h1>
          <h2 className="text-textPrimary text-[1.5rem] font-normal leading-[1.8rem] pt-[1.5rem] pb-[1rem] max-lg:text-[1.5rem]">
            Parece que você está perdido. :/
          </h2>
          <h3 className="text-textPrimary text-[1rem] font-normal leading-[1.7rem] pb-[2rem] max-lg:text-[1rem]">
            Não conseguimos encontrar a página que você está procurando.
          </h3>
          <div>
            <Link to={"/"}>
              <button
                className={`px-4 py-2 rounded-full w-[193px] h-[58px] border-[1px] relative transition-all duration-[350ms] group hover:shadow-md hover:bg-themeColor hover:border-transparent border-white`}
              >
                <div className="flex items-center justify-center">
                  <span
                    className={`group-hover-text-textPrimary group-hover:translate-x-[-6px] text-textPrimary transition uppercase duration-[350ms] font-bold text-center span-small-white`}
                  >
                    Ir para home
                  </span>
                  <div
                    className={`absolute right-4 transform mr-4 transition duration-[350ms] group-hover:opacity-100 group-hover:translate-x-2 opacity-0 -translate-x-2 text-textPrimary`}
                  >
                    <HiOutlineArrowSmallRight />
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </section>
      </section>
    </>
  );
}
