import React from "react";
import { Link } from "react-router-dom";
import { certifier } from "../../../mocks/certifier";

function HeaderSpan({ headerHeight, objectsSpans }) {
  return (
    <div
      className="flex justify-center w-full left-0 top-0 absolute bg-backgroundTwo px-10 overflow-hidden opacity-0 h-fit pointer-events-none cursor-default group-hover:py-10 group-hover:opacity-100 group-hover:pointer-events-auto"
      style={{
        marginTop: `${headerHeight}px`,
      }}
    >
      <div className="gap-y-10 gap-x-2 grid grid-cols-3 max-w-[1170px]">
      {objectsSpans.map((span, index) => (
        <div
          key={index}
          className="flex gap-3 items-center justify-center w-full"
        >
          <Link to={span.url} className="flex w-full h-full gap-x-5 items-center group/mainObject">
            <div className={`flex items-center justify-center rounded-full h-16 bg-backgroundThree px-5 group-hover/mainObject:bg-themeColor transition-all duration-300 ${certifier.colors.darkTheme ? "text-iconAndText group-hover/mainObject:text-textPrimary" : "text-iconAndText group-hover/mainObject:text-white"}`}>
              {span.icon}
            </div>
            <div className="flex flex-col items-start text-left gap-y-1.5">
              <span className="text-textPrimary font-medium text-sm">{span.title}</span>
              <span className="text-[#86888e] text-xs pr-10">{span.description}</span>
            </div>
          </Link>
        </div>
      ))}
    </div>
    </div>
  );
}

export default HeaderSpan;
