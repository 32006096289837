import React, { useEffect, useState } from "react";
import "./ButtonPrimary.css";
import { HiOutlineArrowSmallRight } from "react-icons/hi2";
import { certifier } from "../../mocks/certifier";

function ButtonPrimary({
  leftIcon,
  optionalDisabledColor,
  optionalIcon,
  absoluteIcon,
  optionalMovingIcon,
  optionalBackground,
  disableHorizontalMovement,
  optionalHoverBackground,
  optionalTextColor,
  textButton,
  desktopWidth,
  mobileWidth,
  desktopPadding,
  mobilePadding,
  disabled,
  disableSubmit,
  outlineType,
  outlineColor,
  optionalClass,
  disableBorderRadius,
  verifyOnClick,
  reverseItems,
  removeTextPadding
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const effectiveWidth = isMobile ? mobileWidth : desktopWidth;
  const effectivePadding = isMobile
    ? mobilePadding
      ? mobilePadding
      : 12
    : desktopPadding;

  const styleButton = {
    minWidth: effectiveWidth || "100%",
    paddingTop: effectivePadding || "3",
    paddingBottom: effectivePadding || "3",
    backgroundColor: disabled
      ? optionalDisabledColor || `${certifier.colors.background.backgroundTwo}`
      : hover && optionalHoverBackground
      ? optionalHoverBackground
      : outlineType
      ? ""
      : optionalBackground || `${certifier.colors.buttons.buttonPrimary}`,
    border: outlineType ? `1px solid ${outlineColor || `${certifier.colors.buttons.buttonPrimary}`}` : "",
    color: disabled ? "#9e9e9e" : optionalTextColor ? optionalTextColor : !certifier.colors.buttons.buttonPrimary_darkTheme ? "black" : "white",
    cursor: disabled ? "default" : "pointer",
  };

  return (
    <button
      onClick={verifyOnClick && verifyOnClick}
      style={styleButton}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`relative text-[#0f172a61] cursor-pointer max-lg:py-3 py-3 font-semibold max-lg:text-lg text-sm group transition-300 ${
        !disabled && "hover:-translate-y-[2px] hover:shadow-md"
      } ${optionalClass || ""} ${
        disableBorderRadius ? "rounded-md" : "rounded-full"
      }`}
      disabled={disabled}
      type={disableSubmit ? "button" : "submit"}
    >
      <div
        className={`flex flex-center justify-center items-center ${
          optionalIcon && "gap-x-3"
        } ${reverseItems && "flex-row-reverse"}`}
      >
        {optionalIcon && (
          <div className={`${absoluteIcon && "absolute left-0 ml-[5%]"}`}>
            {optionalIcon}
          </div>
        )}
        <span
          className={`transition-300 max-lg:text-xs ${
            !absoluteIcon &&
            !disabled &&
            !disableHorizontalMovement &&
            `${
              leftIcon
                ? "group-hover:-translate-x-[-6px]"
                : "group-hover:translate-x-[-6px]"
            }`
          } ${!removeTextPadding && "px-6"}`}
        >
          {textButton}
        </span>
        {!optionalIcon && !disableHorizontalMovement && (
          <div
            className={`absolute flex items-center h-full top-0 opacity-0 transition-300
            ${
              leftIcon
                ? "translate-x-2 left-4 ml-[3%]"
                : "-translate-x-2 right-4 mr-[3%]"
            }
             ${
               !disabled &&
               `group-hover:opacity-100 ${
                 leftIcon
                   ? "group-hover:-translate-x-2"
                   : "group-hover:translate-x-2"
               }`
             } `}
          >
            {optionalMovingIcon ? (
              optionalMovingIcon
            ) : (
              <HiOutlineArrowSmallRight />
            )}
          </div>
        )}
      </div>
    </button>
  );
}

export default ButtonPrimary;
