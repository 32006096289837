// verifyAuth
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";

const verifyAuth = (Component) => {
  const WithAuth = (props) => {
    const [isValidAuth, setIsValidAuth] = useState(null);

    useEffect(() => {
      const checkAuth = async () => {
        let storedUser = null;
        try {
          storedUser = localStorage.getItem("user");
        } catch (error) {
          console.error("Failed to access localStorage:", error);
          redirectToLogin("localAuthBlocked");
          return;
        }
        if (storedUser) {
          try {
            const localToken = await CryptoService(storedUser, "decrypt-json");
            if (!localToken.sessionId) {
              redirectToLogin("sessionInvalid");
              return;
            }

            if (moment().unix() > localToken.exp) {
              redirectToLogin("sessionExpired");
              return;
            }

            if (localToken.firstAccess && window.location.pathname !== "/user/basicInfos") {
              setIsValidAuth(false);
              window.location.href = "/user/basicInfos";
              return;
            }

            if (localToken.toReplace) {
              setIsValidAuth(false);
              window.location.href = "/newRecovery";
              return;
            }

            Sentry.setUser({
              email: localToken.email ? localToken.email : undefined,
              id: localToken.sessionId ? localToken.sessionId : undefined,
              username: localToken.name ? localToken.name : undefined,
            });

            setIsValidAuth(true);
          } catch (err) {
            redirectToLogin("error");
          }
        } else {
          redirectToLogin("noToken");
        }
      };

      const redirectToLogin = (reason) => {
        setIsValidAuth(false);
        Sentry.setUser(null);
        const currentPath = window.location || "/";
        window.location.href = `/login?redirect=${encodeURIComponent(
          currentPath
        )}&reason=${reason}`;
      };

      checkAuth();
    }, [props.token]);

    return isValidAuth === null ? null : isValidAuth ? (
      <Component {...props} />
    ) : null;
  };

  return WithAuth;
};

export default verifyAuth;
