// verifyToken.js
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";

const verifyToken = (Component) => {
  const WithToken = (props) => {
    const [isValidAuth, setIsValidAuth] = useState(null);

    useEffect(() => {
      const checkAuth = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token) {
          setIsValidAuth(true);
          return;
        }

        try {
          let storedUser = null;
          try {
            storedUser = localStorage.getItem("user");
          } catch (error) {
            console.error("Failed to access localStorage:", error);
            setIsValidAuth(false);
            window.location.href = "/login&reason=localTokenBlocked";
            return;
          }
          if (storedUser) {
            const localToken = await CryptoService(storedUser, "decrypt-json");

            if (!localToken.sessionId || moment().unix() > localToken.exp) {
              setIsValidAuth(false);
              window.location.href = "/login&reason=tokenSessionExpired";
              return;
            }

            if (
              localToken.toReplace &&
              !String(window.location.pathname).includes("/newRecovery")
            ) {
              setIsValidAuth(false);
              window.location.href = "/newRecovery";
              return;
            }

            setIsValidAuth(true);
          } else {
            setIsValidAuth(false);
            window.location.href = "/login&reason=tokenInexistent";
          }
        } catch (err) {
          setIsValidAuth(false);
          window.location.href = "/login&reason=tokenFailed";
        }
      };

      checkAuth();
    }, []);

    return isValidAuth ? <Component {...props} /> : null;
  };

  return WithToken;
};

export default verifyToken;
