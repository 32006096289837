import React from "react";
import "./RecommendedCard.css";
import { Link } from "react-router-dom";
import { HiLockClosed } from "react-icons/hi2";

function undoTranslateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    "Pós-Graduação": "posGraduacao",
    "Cursos Livres": "cursosLivres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

function RecommendedCard({ index, image, name, type, courseAlias, isAvailableCourses, typeCourse }) {
  return (
    <div className="w-full my-3 -ml-1">
      <section
        className="w-full cursor-pointer mt-3 flex items-center relative hover:scale-[102%] group"
        style={{
          transitionTimingFunction: "ease-in-out",
          transitionProperty: "transform",
          transitionDuration: "300ms",
        }}
      >
        <Link
          // Adquire Screen
          // to={`/adquire/${courseAlias}`}
          to={isAvailableCourses ? `/rescueCourse/${undoTranslateTypeCourse(typeCourse)}/${courseAlias}` : `#`}
          className="w-full flex justify-end"
        >
          <div className="w-[75%] rounded-lg flex flex-col items-end relative">
            <div className="rounded-[0.8rem] group-hover:min-lg:border-white p-1 min-h-[308px] w-full max-lg:min-h-[255px] relative">
              <div className="absolute top-0 h-full z-[8] flex items-center -ml-[3rem] max-lg:-ml-[10vw]">
                <span className="text-[9rem] font-medium text-themeColor select-none max-lg:text-[33vw]">
                  {index + 1}
                </span>
              </div>
              <div className="relative w-full h-[369px] max-lg:h-[73.96vw]">
                {/* Lock div */}
                <div className="z-[1] flex absolute w-full h-full top-0 rounded-md justify-end items-start pt-3 pr-3">
                  <div className="bg-[#3a3b41cc] h-7 w-7 rounded-full flex items-center justify-center text-[#fff]">
                    <HiLockClosed className="w-3 h-3" />
                  </div>
                </div>
                <img
                  src={image}
                  alt="image"
                  className="w-full rounded-[0.5rem] object-cover h-full max-lg:w-[41.6vw] max-lg:h-[73.96vw]"
                  // className="w-full rounded-[0.5rem] object-cover h-full max-lg:w-[41.6vw] max-lg:h-[73.96vw] grayscale"
                />
              </div>
            </div>
            <div className="w-full opacity-100 px-3 pt-3 flex flex-col gap-1">
              <div className="w-full text-sm select-none leading-tight font-semibold text-themeColor max-lg:text-[0.75rem]">
                {type}
              </div>
              <p className="w-full font-medium text-base select-none leading-tight max-lg:text-sm">
                {name}
              </p>
            </div>
          </div>
        </Link>
      </section>
    </div>
  );
}

export default RecommendedCard;
