import { Link } from "react-router-dom";

export default function NextSteps({ nextSteps }) {
  return (
    <>
      <span className="font-bold text-[1.5rem] text-left max-w-[1170px] w-full leading-tight max-lg:text-[1.25rem]">
        Próximos passos
      </span>
      <Link to={nextSteps.link} target="_self" className="flex w-full relative">
        <img
          src={nextSteps.image}
          alt={nextSteps.text}
          className="w-full h-full block max-md:hidden object-cover rounded-[0.8rem]"
        />
        <img
          src={nextSteps.mobileImage}
          alt={nextSteps.text}
          className="w-full h-full hidden max-md:block max-md object-cover rounded-[0.8rem]"
        />
        <div className="absolute w-full h-full top-0 left-0 flex flex-col gap-1.5 justify-end p-12 max-md:p-6 max-w-[45%] max-md:max-w-[100%]">
          <span className="text-3xl text-textPrimary font-bold leading-[1.35] max-lg:leading-tight max-lg:text-2xl">
            {nextSteps.text}
          </span>
          <p className="text-textPrimary text-sm">
            Clique aqui para resgatar o curso.
          </p>
        </div>
      </Link>
    </>
  );
}
