import React, { useState } from "react";
import "./input.css";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";

function Input({ type, name, value, onChange, onFocus, placeholder, optionalIcon, limitCharacter, disabled }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderEyeIcon = () => {
    if (type === "password") {
      return (
        <div
          className="flex w-12 max-lg:w-16 max-lg:text-2xl h-full items-center justify-center rounded-xl cursor-pointer transition-all duration-500 text-inputIcon hover-text-textPrimary max-lg:hover:text-inputIcon hover:bg-backgroundOne/10 max-lg:hover:bg-transparent"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <HiOutlineEye /> : <HiOutlineEyeSlash />}
        </div>
      );
    }
    return null;
  };

  const renderIcon = () => {
    if (optionalIcon) {
      return (
        <div
          className="flex w-10 max-lg:text-2xl h-full items-center justify-center rounded-xl text-inputIcon"
        >
          {optionalIcon}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`flex items-center px-2 justify-between w-full h-full bg-backgroundTwo text-lg rounded-md`}
    >
      {renderIcon()}
      <input
        disabled={disabled}
        type={type === "password" ? (showPassword ? "text" : type) : type}
        maxLength={limitCharacter ?? undefined}
        value={value}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder || ""}
        className={`text-sm max-md:text-base w-full bg-transparent px-1 py-4 ${disabled ? "text-inputIcon" : "text-textPrimary"}`}
      />
      {renderEyeIcon()}
    </div>
  );
}

export default Input;
