import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import {
  HiInformationCircle,
  HiMiniArrowDownTray,
  HiMiniArrowUpTray,
  HiArrowPath,
  HiExclamationTriangle,
  HiDocument,
  HiArchiveBox,
} from "react-icons/hi2";
import Blockquote from "../../components/Blockquote/Blockquote";
import ButtonSecondary from "../../components/ButtonSecondary/ButtonSecondary";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { Link } from "react-router-dom";
import SelectElement from "../../components/SelectElement/MainSelect";
import StepsList from "../../components/StepsList/StepsList";
import { getListStudentCourses } from "../../services/courseService/course.service";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import Input from "../../components/Input/input";
import {
  getStudentTCC,
  sendDispenseTCC,
  sendStudentTCC,
} from "../../services/tccService/tcc.service";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import Cookies from "js-cookie";
import "./tcc.css";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import { certifier } from "../../mocks/certifier";

function TCCPage() {
  const [anyFileUploaded, setAnyFileUploaded] = useState(false);
  const [TCCTitle, setTCCTitle] = useState("");
  const [TCCFile, setTCCFile] = useState(undefined);
  const [NameFile, setNameFile] = useState("");
  const [TCCStep, setTCCStep] = useState(undefined);
  const [TCCModal, setTCCModal] = useState(undefined);
  const [TCCIsRequired, setTCCIsRequired] = useState(undefined);
  const [TCCGrade, setTCCGrade] = useState(undefined);
  const [TCCSteps, setTCCSteps] = useState(undefined);
  const [TCCCorrectionInfos, setTCCCorrectionInfos] = useState(undefined);
  const [TCCObservation, setTCCObservation] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [selectedCourseOption, setSelectedCourseOption] = useState([]);
  const [studentInfos, setStudentInfos] = useState({});
  const [mobileScreen, setMobileScreen] = useState(1);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const fetchStudentCourses = async () => {
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        setStudentInfos(student);
        // getAllStudentCourses --- START
        const coursesResponse = await getListStudentCourses({
          sessionId: student.sessionId,
        });
        if (coursesResponse.status !== 200) {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
          console.error(
            "Erro ao buscar informações de todos os cursos - 1:",
            coursesResponse.status
          );
        }
        try {
          const allCourses = coursesResponse.data
            .filter((course) => course.areaName !== "Softskills")
            .map((course) => ({
              label: course.courseName,
              value: course.courseAlias,
              courseId: course.courseId,
              matriculationId: course.matriculationId,
              typeCourse: course.typeCourse,
              tccRequired: course.courseTccRequired,

            }));
          const lastCourseAccessed = Cookies.get("lastCourseAccessed_tcc");
          const lastCourse = allCourses.find(
            (course) => course.value === lastCourseAccessed
          );
          if (lastCourse) {
            setSelectedCourseOption(lastCourse);
          } else {
            setSelectedCourseOption(allCourses[0]);
          }

          setCoursesOptions(allCourses);
          // getAllStudentCourses --- END
          // loadingStudentTCC --- INICIO
          if (lastCourse) {
            loadingStudentTCC(student, lastCourse);
          } else {
            loadingStudentTCC(student, allCourses[0]);
          }
          // loadingStudentTCC --- END
        } catch (error) {
          Toastify("error", "Erro ao processar nomes dos cursos: #01");
          console.error("Erro ao processar nomes dos cursos: ", error);
        }
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
        console.error("Erro ao buscar os cursos: ", error);
      }
    };
    fetchStudentCourses();
    // eslint-disable-next-line
  }, []);

  const handleSelectCourseChange = async (e) => {
    setSelectedCourseOption(e);
    setIsLoading(true);
    setAnyFileUploaded(false);
    setTCCTitle("");
    loadingStudentTCC(null, e);
    Cookies.set("lastCourseAccessed_tcc", e.value, {
      expires: 1 / 24,
    });
  };

  const handleAttachOrChangeFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".doc,.docx";
    input.click();
    input.onchange = (event) => {
      const file = event.target.files[0];
      let fileName =
        file.name.length > 80 ? `${file.name.slice(0, 80)}...` : file.name;
      setNameFile(fileName);
      setTCCFile(file);
      setAnyFileUploaded(true);
    };
  };

  const handleSubmitFile = async () => {
    setIsLoading(true);
    const data = {
      title: TCCTitle,
      courseId: selectedCourseOption.courseId,
      matriculationId: selectedCourseOption.matriculationId,
    };
    const response = await sendStudentTCC({ TCCFile, data, studentInfos });
    if (response.status === 200) {
      await loadingStudentTCC();
      Toastify("success", "Arquivo enviado com sucesso!");
    } else {
      Toastify("error", "Erro ao enviar arquivo!");
    }
  };

  const handleTCCTitleChange = (e) => {
    setTCCTitle(e.target.value);
  };

  const handleSetMobileScreen = () => {
    setMobileScreen(2);
    window.scrollTo(0, 0);
  };

  const handleDispenseTCC = async () => {
    setIsLoading(true);
    const data = {
      courseId: selectedCourseOption.courseId,
      matriculationId: selectedCourseOption.matriculationId,
    };
    const response = await sendDispenseTCC({ data, studentInfos });
    if (response.status === 200) {
      Toastify("success", "TCC dispensado!");
    } else {
      Toastify("error", `Erro ao dispensar TCC: ${response.status}`);
    }
    setTCCModal(undefined);
    loadingStudentTCC();
  };

  const loadingStudentTCC = async (manualStudent, manualCourseOption) => {
    setIsLoading(true);
    try {
      const student = manualStudent ? manualStudent : studentInfos;
      const course = manualCourseOption
        ? manualCourseOption
        : selectedCourseOption;
      if (student && course) {
        const studentTCC = await getStudentTCC({
          studentInfos: student,
          selectedCourseOption: course,
        });
        if (studentTCC.status === 207) {
          setTCCStep(0);
          setMobileScreen(1);
          setTCCIsRequired(studentTCC.data.tccRequired);
        } else if (studentTCC.status === 200) {
          if (
            typeof studentTCC.data.tcc.dispense === "boolean" &&
            studentTCC.data.tcc.dispense
          ) {
            setTCCStep(10);
            setMobileScreen(2);
          } else if (studentTCC.data.tcc.status === "in_analysis") {
            setTCCStep(1);
            setMobileScreen(2);
            setTCCSteps(
              studentTCC.data.tcc.timeline.map((item) => ({
                content: (
                  <div className="flex flex-col gap-1.5">
                    <span className="font-semibold text-sm">{item.title}</span>
                    <span className="text-xs text-textSecondary">
                      {item.description}
                    </span>
                  </div>
                ),
                activated:
                  item.status === "approved" || item.status === "in_analysis"
                    ? true
                    : false,
              }))
            );
          } else if (studentTCC.data.tcc.status === "correction") {
            setTCCStep(2);
            setMobileScreen(1);
            setTCCObservation(studentTCC.data.tcc.observation);
            setTCCTitle(studentTCC.data.tcc.title);
            setTCCCorrectionInfos({
              link: studentTCC.data.tcc.linkDocumentCorrection,
              version: studentTCC.data.tcc.timeline[0].title[0],
            });
            setTCCSteps(
              studentTCC.data.tcc.timeline.map((item) => ({
                content: (
                  <div className="flex flex-col gap-1.5">
                    <span className="font-semibold text-sm">{item.title}</span>
                    <span className="text-xs text-textSecondary">
                      {item.description}
                    </span>
                  </div>
                ),
                activated: true,
                warning: item.status === "correction" ? true : false,
              }))
            );
          } else if (studentTCC.data.tcc.status === "approved") {
            setTCCStep(3);
            setMobileScreen(1);
            setTCCGrade(studentTCC.data.tcc.grade);
            setTCCSteps(
              studentTCC.data.tcc.timeline.map((item) => ({
                content: (
                  <div className="flex flex-col gap-1.5">
                    <span className="font-semibold text-sm">{item.title}</span>
                    <span className="text-xs text-textSecondary">
                      {item.description}
                    </span>
                  </div>
                ),
                activated: true,
              }))
            );
          }
        } else {
          Toastify(
            "error",
            `Erro ao buscar informações do TCC: ${studentTCC.status}`
          );
        }
      }
    } catch (error) {
      Toastify("error", "Erro ao buscar informações do TCC: #01");
      console.error("Erro ao buscar informações do TCC: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne h-full min-h-screen items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        actualPage="Provas"
        mobileMainText="TCC"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Provas" />
      <LoadingSection isLoading={isLoading} />
      <section className="mt-9 mb-9 pl-0 overflow-hidden flex w-full h-full min-h-[600px] max-w-[1170px] justify-center max-mb:min-h-[100vh] bg-backgroundTwo rounded-xl max-mb:mt-0 max-mb:mb-0 max-mc:rounded-none">
        <div
          style={{
            paddingTop: windowWidth < 960 ? navBarHeader : 0,
            paddingBottom: windowWidth < 960 ? navBar : 0,
          }}
          className={`${windowWidth < 960 && mobileScreen === 1
            ? "h-[auto] w-full"
            : windowWidth < 960
              ? "hidden"
              : "flex w-[65%]"
            }`}
        >
          <div
            className={`p-32 py-16 relative h-full flex w-full bg-backgroundTwo max-mc:p-8 max-mc:px-[5vw]`}
          >
            <div className="flex flex-col gap-y-8 w-full h-full">
              {(TCCStep === 0 || TCCStep === 2) && (
                <>
                  <div className="flex flex-col gap-y-4">
                    <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                      {String(studentInfos.name).split(" ")[0]}, veja as
                      orientações sobre o TCC
                    </h1>
                    <span className="text-textSecondary text-sm">
                      O TCC é obrigatório apenas para os cursos de Engenharia e
                      deverá ser um Artigo Científico de revisão bibliográfica.
                      Não aceitamos monografias, estudos de caso, pesquisas de
                      campo, projetos, relatos de experiências, etc.
                    </span>
                  </div>
                  <div className="flex flex-col gap-y-4 w-full">
                    <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                      Materiais de Apoio
                    </h1>
                    <div className="flex gap-x-8 w-full">
                      <div
                        className={`flex flex-col ${window.innerWidth < 960 ? "items-center" : ""
                          } gap-y-2 w-full`}
                      >
                        <span className="text-textSecondary text-sm w-full">
                          Metodologia
                        </span>
                        <img
                          src="/images/assets/backDiploma.webp"
                          alt="Documentos"
                          className="w-[100%] h-auto rounded-md"
                        />
                        <div className="mt-2">
                          <Link
                            to="https://storage.googleapis.com/fl-tcc/tcc-docs/ORIENTAC%CC%A7A%CC%83O%20PARA%20ELABORAC%CC%A7A%CC%83O%20DO%20PROJETO%20FINAL.pdf"
                            target="_blank"
                          >
                            <ButtonSecondary
                              textButton="Baixar"
                              optionalMovingIcon={<HiMiniArrowDownTray />}
                              desktopWidth="120px"
                              mobileWidth="120px"
                            />
                          </Link>
                        </div>
                      </div>
                      <div
                        className={`flex flex-col ${window.innerWidth < 960 ? "items-center" : ""
                          } gap-y-2 w-full`}
                      >
                        <span className="text-textSecondary text-sm w-full">
                          Artigo Científico
                        </span>
                        <img
                          src="/images/assets/backDiploma.webp"
                          alt="Documentos"
                          className="w-[100%] h-auto rounded-md"
                        />
                        <div className="mt-2">
                          <Link
                            to="https://storage.googleapis.com/fl-tcc/tcc-docs/TEMPLATE-DE-EDICAO-TCC.docx"
                            target="_blank"
                          >
                            <ButtonSecondary
                              textButton="Baixar"
                              optionalMovingIcon={<HiMiniArrowDownTray />}
                              desktopWidth="120px"
                              mobileWidth="120px"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {TCCStep === 1 && (
                <div className="flex flex-col gap-y-4">
                  <div>
                    <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                      Fantástico! O seu TCC foi enviado com sucesso.
                    </h1>
                    <p className="text-textSecondary text-sm">
                      Agora é com a gente, o setor tem até 10 dias úteis para
                      corrigi-lo e fazer a devolutiva. Utilize esse espaço para
                      acompanhar e saber se o seu Trabalho de Conclusão de Curso
                      será aprovado.
                    </p>
                  </div>
                </div>
              )}
              {TCCStep === 3 && (
                <div className="flex flex-col gap-y-8">
                  <div className="flex flex-col gap-y-4">
                    <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                      {TCCGrade &&
                        TCCGrade.gradeTotal &&
                        `Fantástico! O seu TCC foi aprovado com nota ${TCCGrade.gradeTotal}`}
                    </h1>
                    <p className="text-textSecondary text-sm">
                      Parabéns pela aprovação do TCC! Seu esforço e dedicação
                      renderam frutos merecidos. Sucesso em sua jornada
                      acadêmica e profissional.
                    </p>
                  </div>
                  <div className="tcc-grade-table w-full">
                    <h2 className="text-sm">Aspectos Gerais</h2>
                    <table className="table-auto w-full">
                      <thead className="w-full">
                        <tr className="w-full bg-backgroundThree grid grid-table-tcc">
                          <th className="text-left truncate w-full">
                            Tópicos a serem avaliados
                          </th>
                          <th className="text-center w-full">Valor</th>
                          <th className="text-center w-full">Nota</th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">
                            Identificação do Autor
                          </td>
                          <td className="text-center">0.5</td>
                          <td className="text-center">
                            {TCCGrade.generalAspects.authorIndentification}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Formatação</td>
                          <td className="text-center">1.5</td>
                          <td className="text-center">
                            {TCCGrade.generalAspects.formatting}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">
                            Normas de Referência
                          </td>
                          <td className="text-center">1.5</td>
                          <td className="text-center">
                            {TCCGrade.generalAspects.referenceNorms}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Citações</td>
                          <td className="text-center">1.5</td>
                          <td className="text-center">
                            {TCCGrade.generalAspects.citations}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tcc-grade-table w-full">
                    <h2 className="text-sm">Parte Escrita</h2>
                    <table className="w-full">
                      <thead className="w-full">
                        <tr className="w-full bg-backgroundThree grid grid-table-tcc">
                          <th className="text-left truncate">
                            Tópicos a serem avaliados
                          </th>
                          <th className="text-center">Valor</th>
                          <th className="text-center">Nota</th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Resumo</td>
                          <td className="text-center">1.0</td>
                          <td className="text-center">
                            {TCCGrade.writtenPart.abstract}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Introdução</td>
                          <td className="text-center">1.0</td>
                          <td className="text-center">
                            {TCCGrade.writtenPart.introduction}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Metodologia</td>
                          <td className="text-center">1.0</td>
                          <td className="text-center">
                            {TCCGrade.writtenPart.methodology}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">Discussão</td>
                          <td className="text-center">1.0</td>
                          <td className="text-center">
                            {TCCGrade.writtenPart.discussion}
                          </td>
                        </tr>
                        <tr className="w-full grid grid-table-tcc">
                          <td className="text-left truncate">
                            Considerações Finais
                          </td>
                          <td className="text-center">1.0</td>
                          <td className="text-center">
                            {TCCGrade.writtenPart.finalConsiderations}
                          </td>
                        </tr>
                      </tbody>
                      <thead className="w-full">
                        <tr className="w-full bg-backgroundThree grid grid-table-tcc">
                          <th className="text-left truncate">
                            <strong>Total</strong>
                          </th>
                          <th className="text-center">
                            <strong>10</strong>
                          </th>
                          <th className="text-center">
                            <strong>{TCCGrade.gradeTotal}</strong>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}
              {TCCStep === 10 && (
                <div className="flex flex-col gap-y-4">
                  <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                    Você optou pela dispensa do TCC.
                  </h1>
                  <p className="text-textSecondary text-sm">
                    Ressaltando que essa decisão de dispensa é irrevogável.
                    Respeitamos sua escolha e estamos disponíveis para apoiá-lo
                    em sua trajetória acadêmica e profissional.
                  </p>
                </div>
              )}
              <div
                className={`w-full justify-end max-mc:flex hidden max-mc:pb-8`}
              >
                <ButtonPrimary
                  verifyOnClick={handleSetMobileScreen}
                  textButton="Próximo"
                  desktopWidth={150}
                  mobileWidth={150}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingTop: windowWidth < 960 ? navBarHeader : 0,
            paddingBottom: windowWidth < 960 ? navBar : 0,
          }}
          className={`bg-backgroundThree ${windowWidth < 960 && mobileScreen === 2
            ? "h-[auto] w-full"
            : windowWidth < 960
              ? "hidden"
              : "flex w-[38%]"
            }`}
        >
          <div
            className={`flex w-full h-full flex-col m-0 p-16 max-mc:px-[5vw] max-mc:p-8`}
          >
            {TCCStep === 1 && windowWidth < 960 && (
              <div className="flex flex-col">
                <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                  Fantástico! O seu TCC foi enviado com sucesso.
                </h1>
                <p className="text-textSecondary text-sm">
                  Agora é com a gente, o setor tem até 10 dias úteis para
                  corrigi-lo e fazer a devolutiva. Utilize esse espaço para
                  acompanhar e saber se o seu Trabalho de Conclusão de Curso
                  será aprovado.
                </p>
              </div>
            )}

            {TCCStep === 1 && windowWidth > 960 && (
              <div>
                <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                  TCC
                </h1>
                <p className="text-textPrimary text-sm">
                  Acompanhe o seu Trabalho de Conclusão de Curso.
                </p>
              </div>
            )}
            {TCCStep === 10 && windowWidth < 960 && (
              <div className="flex flex-col">
                <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                  Você optou pela dispensa do TCC.
                </h1>
                <p className="text-textSecondary text-sm">
                  Ressaltando que essa decisão de dispensa é irrevogável.
                  Respeitamos sua escolha e estamos disponíveis para apoiá-lo em
                  sua trajetória acadêmica e profissional.
                </p>
              </div>
            )}

            {TCCStep === 10 && windowWidth > 960 && (
              <div>
                <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                  TCC
                </h1>
                <p className="text-textPrimary text-sm">
                  Acompanhe o seu Trabalho de Conclusão de Curso.
                </p>
              </div>
            )}
            {TCCStep !== 1 && TCCStep !== 10 && (
              <div>
                <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                  TCC
                </h1>
                <span className="text-textPrimary text-sm">
                  {TCCStep === 0
                    ? "Envie o seu Trabalho de Conclusão de Curso."
                    : "Acompanhe o seu Trabalho de Conclusão de Curso."}
                </span>
              </div>
            )}
            <div className="flex flex-col w-full gap-y-8 my-8">
              {TCCStep === 0 && (
                <Blockquote
                  text={
                    TCCIsRequired
                      ? `Este curso é obrigatório o envio do TCC.`
                      : `Este curso não exige o envio do TCC. Caso deseje dispensar, clique no botão dispensar.`
                  }
                  color="#2563eb"
                  icon={<HiInformationCircle />}
                />
              )}
              {selectedCourseOption && selectedCourseOption.value &&(
              <div>
                <div className="flex flex-col gap-y-3 w-full h-full">
                  <label className="span-small-white">Selecione o curso</label>
                  <SelectElement
                    options={coursesOptions}
                    value={selectedCourseOption.value}
                    onChange={handleSelectCourseChange}
                  />
                </div>
              </div>
              )}
              {TCCStep === 2 && (
                <div className="flex flex-col gap-y-8 w-full h-full">
                  <h1 className="font-bold text-[1.5rem]">Situação</h1>
                  <StepsList steps={TCCSteps} />
                  <div className="flex w-full py-4">
                    <Blockquote
                      text={`É necessário que seu TCC seja reformulado. Baixe o arquivo, realize as correções indicadas pelo(a) tutor(a) e envie uma nova versão.`}
                      observation={TCCObservation}
                      color={certifier.colors.steps.stepReproved}
                      icon={<HiExclamationTriangle />}
                    />
                  </div>
                </div>
              )}

              {(TCCStep === 0 || TCCStep === 2) && (
                <div className="flex flex-col gap-y-3 w-full">
                  <label className="span-small-white">Título *</label>
                  <Input
                    type="text"
                    name="title"
                    value={TCCTitle}
                    onChange={handleTCCTitleChange}
                    optionalIcon={<HiDocument />}
                  />
                </div>
              )}
              {TCCStep === 2 && (
                <div className="flex flex-col gap-y-3 w-full">
                  <label className="span-small-white">TCC Corrigido</label>
                  <div className="pt-5">
                    {TCCCorrectionInfos && (
                      <div className="flex flex-col gap-y-5 items-center">
                        <div className="flex flex-col gap-4 justify-center items-center">
                          <HiDocument className="text-[#64758b] w-10 h-auto rounded-m" />
                          <label className="select-none text-xs">
                            versão-{String(TCCCorrectionInfos.version)}
                            -corrigida.docx
                          </label>
                        </div>
                        <Link to={TCCCorrectionInfos.link} target="_blank">
                          <ButtonSecondary
                            textButton="Baixar"
                            optionalMovingIcon={<HiMiniArrowDownTray />}
                            desktopWidth="120px"
                            mobileWidth="120px"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {(TCCStep === 0 || TCCStep === 2) && (
              <div className="flex flex-col gap-y-10">
                <div className="flex flex-col gap-y-3">
                  {TCCStep === 2 && (
                    <label className="span-small-white">Nova Versão</label>
                  )}
                  {anyFileUploaded ? (
                    <div className="flex flex-col justify-center items-center pt-5 gap-4">
                      <HiDocument className="text-[#64758b] w-10 h-auto rounded-m" />
                      {/* <img
                        src="/images/assets/miniDocument.webp"
                        alt="Documentos"
                        className="w-16 h-auto rounded-md"
                      /> */}
                      <label className="select-none text-xs text-center">
                        {NameFile}
                      </label>
                    </div>
                  ) : (
                    <img
                      src="/images/assets/backDiploma.webp"
                      alt="Documentos"
                      className="w-full h-auto rounded-md"
                    />
                  )}
                  <div className="my-2 flex w-full justify-center">
                    {anyFileUploaded ? (
                      <ButtonSecondary
                        optionalMovingIcon={<HiArrowPath />}
                        textButton="Substituir"
                        desktopWidth="120px"
                        mobileWidth="120px"
                        verifyOnClick={handleAttachOrChangeFile}
                        disabled={studentInfos.userTeste}
                      />
                    ) : (
                      <ButtonSecondary
                        verifyOnClick={handleAttachOrChangeFile}
                        textButton="Anexar"
                        optionalMovingIcon={<HiMiniArrowUpTray />}
                        desktopWidth="120px"
                        mobileWidth="120px"
                        disabled={studentInfos.userTeste}
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-x-5 w-full justify-end max-sm:justify-between max-mc:pb-8">
                  {(!selectedCourseOption.tccRequired && (
                    <ButtonPrimary
                    optionalBackground={certifier.colors.buttons.buttonTertiary}
                    optionalTextColor={certifier
                      .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                      verifyOnClick={() => {
                        setTCCModal(selectedCourseOption);
                      }}
                      textButton="Dispensar"
                      desktopWidth={150}
                      mobileWidth={150}
                      optionalMovingIcon={<HiArchiveBox />}
                      disabled={isLoading || studentInfos.userTeste}
                    />
                  ))}
                  <ButtonPrimary
                    verifyOnClick={handleSubmitFile}
                    disabled={
                      TCCTitle.length < 5 || !anyFileUploaded || isLoading
                    }
                    textButton="Próximo"
                    mobileWidth={150}
                    desktopWidth={150}
                  />
                </div>
              </div>
            )}
            {(TCCStep === 1 || TCCStep === 3) && TCCSteps && (
              <div className="flex flex-col gap-y-8 w-full h-full">
                <h1 className="font-bold text-[1.5rem]">Situação</h1>
                <StepsList steps={TCCSteps} />
              </div>
            )}
          </div>
        </div>
      </section>
      {TCCModal && (
        <BlockquoteModal
          text_top="Dispensar TCC"
          text_blockquote={`Tem certeza que deseja dispensar o TCC do curso de <b>${TCCModal.label}</b>? Esta ação é irreversível.`}
          outsideClick={() => setTCCModal(undefined)}
          buttonsRight={[
            <ButtonPrimary
              verifyOnClick={() => setTCCModal(undefined)}
              disableHorizontalMovement
              optionalHoverBackground="#FFFFFF08"
              optionalBackground="#00000000"
              textButton="Cancelar"
              optionalTextColor={certifier.colors.text.textPrimary}
              desktopWidth="160px"
              mobileWidth="100%"
            />,
            <ButtonPrimary
              verifyOnClick={handleDispenseTCC}
              textButton="Confirmar"
              desktopWidth="160px"
              mobileWidth="100%"
            />,
          ]}
        />
      )}
    </main>
  );
}

export default verifyAuth(TCCPage);
