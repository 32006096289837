import React, { useEffect, useRef, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import { Toastify } from "../../components/Toastify/Toastify";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { useParams } from "react-router-dom";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import {
  getAllStudentCourses,
  getPublicCourseInfo
} from "../../services/courseService/course.service";
import VideoClipSlide from "../../components/VideoSliderElements/VideoClipSlide";
import "./rescue.css";
import {
  getCoursesRecommendedRequest,
  getNextStepsRequest
} from "../../services/dashboardService/dashboard.service";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import CourseList from "../../components/CourseList/CourseList";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { addNewCourse } from "../../services/studentService/student.service";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import { certifier } from "../../mocks/certifier";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { getClassFromDemoCourse } from "../../services/disciplineService/discipline.service";

function copyCurriculumnContent(curriculumn) {
  const content = `${curriculumn
    .replaceAll("<ul>", "<br/>")
    .replaceAll("<li>", "<br/>• ")
    .replaceAll("&nbsp;", "")
    .replaceAll("</ul>", "")
    .replaceAll("</li>", "")
    .replaceAll("<p>", "")
    .replaceAll("</p>", "")
    .replaceAll("<br>", "")
    .replaceAll("\n ", "<br/>• ")
    .replace(/^(<br\/>)+/, "")
    .replaceAll("<br/>• ", `<div class="h-[6px]"></div>• `)}`;

  return content;
}

function Rescue() {
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [course, setCourse] = useState(undefined);
  const [coursesRecommended, setCoursesRecommended] = useState([]);
  const [isBeginning, setIsBeginning] = useState(true);
  const [openRescuePopUp, setOpenRescuePopUp] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isAvailableCourses, setIsAvailableCourses] = useState(false);
  const [classContent, setClassContent] = useState(undefined);

  const typeCourse = useParams().typeCourse;
  const courseAlias = useParams().courseAlias;
  const swiperRef = useRef(null);

  useEffect(() => {
    const fetchCourseInfo = async () => {
      try {
        const student = await getStudentInfos();
        const arrayCourses = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: true
        });

        const response = await getClassFromDemoCourse({
          courseAlias: courseAlias
        });
        if (response.status !== 200) {
          Toastify("error", "Erro ao buscar informações de demonstração.");
          console.error(
            "Erro ao buscar informações de demonstração - 1:",
            response.status
          );
        }
        try {
          setClassContent(response.data);
        } catch {
          Toastify("error", "Erro ao processar itens da demonstração: #01");
        }

        const arrayNextSteps = await getNextStepsRequest({
          sessionId: student.sessionId
        });
        if (arrayNextSteps.status !== 200) {
          Toastify("error", "Erro ao buscar informações de steps.");
          console.error(
            "Erro ao buscar informações de steps - 9:",
            arrayNextSteps
          );
        }
        try {
          if (arrayNextSteps.data !== undefined) {
            const nextStep = arrayNextSteps.data.find(
              (item) => item.status === true
            );
            if (!(nextStep && nextStep.type === "availableCourse"))
              window.location.href = `/dashboard?message=${encodeURI(
                "Você não possui cursos disponíveis para resgate."
              )}`;
            else if (
              !(
                nextStep.details.type[0] === "posGraduacao" ||
                nextStep.details.type[0] === typeCourse
              )
            )
              window.location.href = `/dashboard?message=${encodeURI(
                "Você não possui esta modalidade de curso disponível para resgate."
              )}`;
            else setIsAvailableCourses(nextStep.details.type[0]);
          }
        } catch {}

        const arrayStudentCourses = await getAllStudentCourses({
          sessionId: student.sessionId
        });
        if (arrayStudentCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de seus cursos.");
          console.error(
            "Erro ao buscar informações de seus cursos - 1:",
            arrayStudentCourses.status
          );
        } else {
          let allStudentCoursesArray = [];
          if (
            arrayStudentCourses.data.coursesPosGraduation &&
            arrayStudentCourses.data.coursesPosGraduation.length > 0
          ) {
            allStudentCoursesArray = allStudentCoursesArray.concat(
              arrayStudentCourses.data.coursesPosGraduation
            );
          }
          if (
            arrayStudentCourses.data.extraCourses &&
            arrayStudentCourses.data.extraCourses.length > 0
          ) {
            allStudentCoursesArray = allStudentCoursesArray.concat(
              arrayStudentCourses.data.extraCourses
            );
          }
          const hasTheCourse = allStudentCoursesArray.find(
            (course) => course.alias === courseAlias
          );

          let studentHasSpecifiedWord = false;
          let specifiedWord = "estudos";

          allStudentCoursesArray.find((course) => {
            if (course.alias.includes(specifiedWord)) {
              studentHasSpecifiedWord = true;
            }
          });

          if (!studentHasSpecifiedWord && courseAlias.includes(specifiedWord)) {
            window.location.href = `/dashboard?message=${encodeURI(
              "Procure o atendimento para a liberação do curso."
            )}`;
          }

          if (hasTheCourse)
            window.location.href = `/dashboard?message=${encodeURI(
              "Você já possui este curso."
            )}`;
        }

        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        getPublicCourseInfo(courseAlias).then((response) => {
          if (response.status !== 200) {
            Toastify(
              "error",
              "Erro ao buscar informações de curso indisponível."
            );
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            return;
          }
          setCourse(response.data);
        });

        if (arrayCourses.status !== 200) {
          // Toastify(
          //   "error",
          //   "Erro ao buscar informações de cursos recomendados."
          // );
          console.error(
            "Erro ao buscar informações de cursos recomendados:",
            arrayCourses.status
          );
        } else {
          setCoursesRecommended(arrayCourses.data.courses || []);
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar curso.");
        console.error("Erro ao buscar curso: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourseInfo();
  }, [courseAlias]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const swiperSettings = {
    spaceBetween: 32,
    slidesPerView: window.innerWidth > 768 ? 2.7 : 1,
    centeredSlides: true,
    loop: false,
    cubeEffect: {
      slideShadows: false
    },
    speed: 600,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    onSlideChange: (swiper) => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    },
    onSwiper: (swiper) => {
      swiperRef.current = swiper;
    }
  };

  const handleChangeSlide = (type, slideIndex = null) => {
    if (!swiperRef.current) return;
    if (type === "next") {
      swiperRef.current.slideNext();
    } else if (type === "prev") {
      swiperRef.current.slidePrev();
    } else if (type === "goTo" && slideIndex !== null) {
      swiperRef.current.slideTo(slideIndex);
    }
  };

  const handleConfirmRescue = async () => {
    const student = await getStudentInfos();
    try {
      setIsLoading(true);
      const response = await addNewCourse(student, {
        courseAlias: courseAlias,
        typeCourse: course.typeCourse
      });
      if (response.status === 200) {
        setOpenRescuePopUp(undefined);
        window.location.href = `/course/${courseAlias}?message=${encodeURIComponent(
          `${course.name} resgatado com sucesso!`
        )}`;
      } else {
        Toastify("error", "Erro ao resgatar o curso.");
      }
    } catch (error) {
      Toastify("error", "Erro ao resgatar o curso!");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <div
        className={`fixed top-0 left-0 w-full h-full z-[200] flex items-center justify-center transition-300 ${
          !openRescuePopUp && "opacity-0 scale-95 pointer-events-none"
        }`}>
        <BlockquoteModal
          text_top="Adesão ao curso"
          text_blockquote={`Você está resgatando o curso de <span class="font-semibold">${
            course ? course.name : "..."
          }</span>, tem certeza que deseja continuar com essa ação?`}
          outsideClick={() => setOpenRescuePopUp(undefined)}
          buttonsRight={[
            <ButtonPrimary
              verifyOnClick={() => setOpenRescuePopUp(undefined)}
              disableHorizontalMovement
              optionalHoverBackground="#FFFFFF08"
              optionalBackground="#00000000"
              textButton="Cancelar"
              optionalTextColor={certifier.colors.text.textPrimary}
              desktopWidth="160px"
              mobileWidth="160px"
            />,
            <ButtonPrimary
              verifyOnClick={handleConfirmRescue}
              textButton={`Confirmar`}
              desktopWidth={150}
            />
          ]}
        />
      </div>
      <div
        style={{ height: windowWidth < 960 ? windowHeight - navBar : "auto" }}
        className={`w-full justify-center items-center overflow-x-hidden ${
          windowWidth < 960
            ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
            : "flex flex-col"
        }`}>
        <HeaderBar
          actualPage="Aulas"
          courseNavigation={true}
          mobileMainText={"Indefinido no momento."}
          mobileBackPage="/"
        />
        <MobileNavigationBar actualPage="Aulas" />
        <LoadingSection isLoading={isLoading || !course} />
        {course && classContent && (
          <section className="flex flex-col gap-12 w-full items-center pb-12">
            {/* Section 1 */}
            <VideoClipSlide
              rescueScreen={course}
              rescueAction={() => setOpenRescuePopUp(true)}
              limitedMode
              item={{
                id: 0,
                video: classContent.currentClass.src,
                imageThumb: classContent.currentClass.thumb,
                title: course.name,
                description: course.description,
                buttonText: "Resgatar curso"
              }}
            />

            {/* Section 2 */}
            {course.curriculumns.length && (
              <section className="w-full flex justify-center">
                <div className="flex flex-col w-full max-w-[1170px] max-md:px-[5vw]">
                  <div className="flex justify-between w-full md:items-end max-md:flex-col-reverse">
                    <h2 className="font-bold text-[1.5rem] leading-tight text-left max-lg:text-[1.25rem]">
                      O que você irá aprender
                    </h2>
                    <div className="flex flex-col items-end max-md:pb-6">
                      <h2 className="text-[75px] font-bold leading-none text-stroke-[1px] text-stroke-themeColor text-transparent -mb-1">
                        {course.workload}h
                      </h2>
                      <p className="font-semibold text-lg leading-none">
                        de Conteúdo
                      </p>
                    </div>
                  </div>

                  <div className="flex w-full mt-6 relative">
                    <div
                      className={`absolute top-0 left-0 bg-gradient-to-r from-backgroundOne z-[2] w-[48px] h-full flex justify-center items-center transition-300 ${
                        isBeginning && "opacity-0 pointer-events-none"
                      } max-md:from-transparent`}>
                      <HiChevronLeft
                        onClick={() => handleChangeSlide("prev")}
                        className="cursor-pointer max-md:absolute"
                        size={30}
                      />
                    </div>
                    <div
                      className={`absolute top-0 right-0 bg-gradient-to-l from-backgroundOne z-[2] w-[48px] h-full flex justify-center items-center transition-300 ${
                        isEnd && "opacity-0 pointer-events-none"
                      } max-md:from-transparent`}>
                      <HiChevronRight
                        onClick={() => handleChangeSlide("next")}
                        className="cursor-pointer max-md:absolute"
                        size={30}
                      />
                    </div>
                    {course.curriculumns.length === 1 ? (
                      <CurriculumnSlide
                        uniqueSlide
                        isActive={true}
                        curriculumn={course.curriculumns[0]}
                        index={0}
                      />
                    ) : (
                      <Swiper {...swiperSettings}>
                        {course.curriculumns.map((curriculumn, index) => (
                          <SwiperSlide key={index}>
                            {({ isActive }) => (
                              <CurriculumnSlide
                                key={index}
                                isActive={isActive}
                                curriculumn={curriculumn}
                                index={index}
                                handleChangeSlide={handleChangeSlide}
                              />
                            )}
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </div>
                </div>
              </section>
            )}
            {/* Section 3 */}
            {coursesRecommended && coursesRecommended.length > 0 && (
              <section className="flex swiper-cards-progress w-full flex-col gap-2 max-w-[1170px] max-md:px-[5vw] pt-12">
                <section className="w-full flex justify-center">
                  <CourseList
                    isAvailableCourses={isAvailableCourses !== "cursosLivres"}
                    area={{
                      areaName: "Recomendados para você",
                      courses: coursesRecommended,
                      specifiedRedirect: "/recommendedCourses"
                    }}
                  />
                </section>
              </section>
            )}
          </section>
        )}
      </div>
    </main>
  );
}

export default verifyAuth(Rescue);
const CurriculumnSlide = ({
  uniqueSlide,
  curriculumn,
  index,
  isActive,
  handleChangeSlide
}) => (
  <div
    onClick={() => !isActive && handleChangeSlide("goTo", index)}
    className={`w-full relative rounded-2xl overflow-hidden h-full transition-300 cursor-pointer ${
      isActive ? "opacity-100 scale-100 z-10 group" : "opacity-40"
    }`}>
    {process.env.REACT_APP_CERTIFIER === "faculdadelibano" && (
      <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end z-[0] pointer-events-none">
        <img
          src="/images/assets/libano-silhouette.svg"
          className="w-[300px] h-auto transition-300 translate-x-1/3 translate-y-[43%] group-hover:translate-y-[35%]"
        />
      </div>
    )}
    <div className="w-full h-full bg-backgroundTwo rounded-2xl flex flex-col gap-8 p-8 md:min-h-[380px]">
      <div className="flex w-full gap-6 justify-between">
        <span className="font-bold text-[1.5rem] text-left max-w-[1170px] w-full leading-tight max-lg:text-[1.25rem] transition-300 group-hover:translate-y-2 group-hover-text-themeColor">
          {curriculumn.name}
        </span>
        <span className="text-[64px] pl-12 font-bold leading-none text-stroke-[1px] text-stroke-themeColor text-transparent -mb-1 text-end">
          {uniqueSlide ? "Ementa" : index + 1}
        </span>
      </div>
      <div className="transition-300 group-hover:translate-y-[2px] z-[1]">
        <style jsx>
          {`
            span.curriculumn, span.curriculumn > span {
              color: white !important;
              background-color: transparent !important;
              font-size: 0.875rem !important;
            }
          `}
        </style>
        <span
          className="curriculumn"
          dangerouslySetInnerHTML={{
            __html: copyCurriculumnContent(curriculumn.description)
          }}></span>
      </div>
    </div>
  </div>
);
