import React, { useEffect, useRef } from "react";
import { postPlayerMoment } from "../../services/playerService/player.service";

const IframeVideoPlayer = ({
  autoPlay,
  backgroundVideo,
  sessionId,
  disciplineId,
  classId,
  courseId,
  src,
  time,
}) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const newHeight = (9 / 16) * containerWidth;
      containerRef.current.style.height = `${newHeight}px`;
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleMetadataLoaded = () => {
      if (time) {
        videoElement.currentTime = time;
      }
    };

    let intervalId;
    let videoStatus = "paused";

    const handlePlay = () => {
      videoStatus = "playing";
    };

    const handlePause = () => {
      videoStatus = "paused";
    };

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);

      intervalId = setInterval(async () => {
        if (
          !backgroundVideo &&
          sessionId &&
          classId &&
          courseId &&
          disciplineId &&
          videoStatus === "playing"
        ) {
          postPlayerMoment({
            sessionId: sessionId,
            watchedSeconds: Math.floor(videoElement.currentTime),
            totalSeconds: Math.floor(videoElement.duration),
            disciplineId: disciplineId,
            classId: classId,
            courseId: courseId,
          });
        }
      }, 5000);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionId, classId, courseId, disciplineId, backgroundVideo, time]);

  return (
    <div ref={containerRef} style={{ width: "100%", overflow: "hidden" }}>
      <video
        ref={videoRef}
        src={src}
        style={{ border: "0", width: "100%", height: "100%" }}
        autoPlay={autoPlay}
        controls
        controlsList="nodownload"
      />
    </div>
  );
};

export default IframeVideoPlayer;
