import axios from "axios";
import JSEncrypt from 'jsencrypt';

export const getHashCard = async ({ card_number, card_holder_name, card_expiration_date, card_cvv }) => {
  try {
    const { id, public_key } = await getPublicKey();

    const queryString = encodeURI(`card_number=${card_number}&card_holder_name=${card_holder_name}&card_expiration_date=${card_expiration_date}&card_cvv=${card_cvv}`);

    let encrypt = new JSEncrypt();

    encrypt.setPublicKey(public_key);

    let encrypted = encrypt.encrypt(queryString);

    const hash_card = `${id}_${encrypted}`;
    return { status: true, hash_card };

  } catch (err) {
    console.error("Error no Gerar Card", err);
    return { status: false, hash_card: null };
  }
};

async function getPublicKey() {
  const options = {
    method: 'GET',
    url: `${process.env.REACT_APP_PAGAR_ME_API}/1/transactions/card_hash_key`,
    params: { api_key: process.env.REACT_APP_PAGAR_ME_CRYPTOGRAPHY },
    headers: { accept: 'application/json' },
  };

  let publicKey = await axios
    .request(options)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    });

  return publicKey
}

