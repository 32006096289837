import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const getStudentDocuments = async ({ student }) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: student.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };

    const responseCrypt = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/getStudentDocuments`,
      config
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};

export const sendStudentDocuments = async ({
  studentInfos,
  file,
  documentInfos,
  alias,
  type,
  isCorrectionType,
}) => {
  try {
    const post_data = await CryptoService(
      {
        sessionId: studentInfos.sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );

    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    
    let currentDocument = undefined

    if (isCorrectionType) {
      currentDocument = documentInfos.documentsDisapproved.some(
        (item) => item.alias === alias && item.type === type
      );
      if (!currentDocument) {
              throw new Error("Document not found.");
      } else {
        currentDocument = documentInfos.documentsDisapproved.find(
          (item) => item.alias === alias && item.type === type
        );
      }
    } else {
    const documentsArray = Object.values(documentInfos.document).flatMap(
      (doc) => doc.documents
    );
    currentDocument = documentsArray.find(
      (document) => document.alias === alias && document.type === type
    );
    }

    if (!currentDocument) {
      throw new Error("Document not found.");
    }

    const documentData = {
      type: isCorrectionType ? "correction" : "add",
      alias: currentDocument.alias,
      counter: currentDocument.counter,
      group: currentDocument.type,
      observation: currentDocument.observation,
      status: "in_analysis",
      exp: moment().add(2, "hours").unix(),
    };

    const cryptData = await CryptoService(documentData, "encrypt-jwt");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", cryptData);

    const response = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/sendStudentDocument`,
      formData,
      config
    );
    if (response.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      status: response.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response ? err.response.data.code : 500 };
  }
};