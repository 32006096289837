import React, { useEffect, useRef, useState } from "react";

export default function CreditCardObject({
  number,
  name,
  expiry,
  cvv,
  focused,
  preview,
  locale,
  placeholders,
  logo,
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    setIsFlipped(focused === "cvv");
  }, [focused]);

  const defaultPlaceholders = {
    number: "#### #### #### ####",
    name: "NOME IGUAL AO CARTÃO",
    expiry: "MM/AA",
    cvv: "CVV",
  };

  const finalPlaceholders = { ...defaultPlaceholders, ...placeholders };
  return (
    <div className="relative w-full h-full flex justify-center items-center">
      <div
        ref={cardRef}
        className="perspective"
        style={{
          perspective: "1000px",
        }}
      >
        <div
          className={`card text-textPrimary text-lg font-medium ${
            isFlipped ? "flipped" : ""
          }`}
          style={{
            perspective: "1000px",
            transformStyle: "preserve-3d",
            transition: "transform 1s",
            transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
          }}
        >
          {/* Front of the card */}
          <div className="card-face front">
            {/* Content of the front of the card */}
            <div className="card-face-inner relative">
              <div className="flex flex-col gap-10">
                <div className="absolute left-5 top-5 w-10">
                  <img src="/images/chip.webp" alt="chip" />
                </div>
                {logo && (
                  <div className="absolute right-5 top-5 w-16">
                    <img src={`/images/card/${logo}.webp`} alt="chip" />
                  </div>
                )}
              </div>
              <div className="absolute left-5 top-[6rem] text-lg font-normal">
                {number || finalPlaceholders.number}
              </div>
              <div className="absolute top-[7.5rem] left-5 w-full h-full">
                <div className="text-[10px] font-normal mb-1 leading-tight">
                  Titular do cartão
                </div>
                <div className="text-xs font-semibold leading-none line-clamp-1 max-w-[180px] uppercase">
                  {name || finalPlaceholders.name}
                </div>
                <div className="absolute top-0 right-10 w-full h-full">
                  <div className="text-[10px] font-normal mb-1 text-right leading-tight">
                    Validade
                  </div>
                  <div className="text-xs text-right font-semibold leading-none">
                    {expiry || finalPlaceholders.expiry}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Back of the card */}
          <div className="card-face back">
            <div className="card-face-inner">
              {/* Content of the back of the card */}
              <div className="flex justify-end items-end h-full mirror relative">
                <div className="absolute w-[320px] h-10 top-[1rem] left-0 bg-backgroundOne/80 text-black flex justify-center items-center" />
                <div className="absolute ml-2 w-[305px] h-8 top-[5rem] left-0 bg-white text-black flex justify-center items-center rounded-md">
                  <div className="text-xs text-textPrimary w-full text-right -mt-4 absolute top-0 right-5">
                    CVV
                  </div>
                  <span className="w-full text-right mr-5">
                    {cvv.replace(/./g, "*") || finalPlaceholders.cvv}
                  </span>
                </div>
                {logo && (
                  <div className="absolute left-[15.55rem] bottom-5 w-16 grayscale brightness-60">
                    <img src={`/images/card/${logo}.webp`} alt="chip" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ... (CSS classes remain unchanged)
