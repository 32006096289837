import "./DiscoveryCourse.css";

import { useEffect, useState, useRef } from "react";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import { HiBeaker } from "react-icons/hi2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import { certifier } from "../../../mocks/certifier";
import BlockquoteModal from "../../..//components/Modals/BlockquoteModal";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { Toastify } from "../../Toastify/Toastify";
import getStudentInfos from "../../../services/getStudentInfos/getStudentInfos";
import { addNewCourse } from "../../../services/studentService/student.service";
import LoadingSection from "../../LoadingElements/LoadingSection";

function undoTranslateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    "Pós-Graduação": "posGraduacao",
    "Cursos Livres": "cursosLivres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

export default function DiscoveryCourse({ courses }) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openRescuePopUp, setOpenRescuePopUp] = useState(false);
  const [rescueCourseContent, setRescueCourseContent] = useState(false);
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const [lastActiveSwiperIndex, setLastActiveSwiperIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [student, setStudent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const swiperRef = useRef(null);
  const indexLimit = 1;

  useEffect(() => {
    const updateStudentVariable = async () => {
      const student = await getStudentInfos();
      setStudent(student);
    };
    updateStudentVariable();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textIndex >= indexLimit) {
        setTextIndex(0);
      } else {
        setTextIndex(textIndex + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [textIndex]);

  useEffect(() => {
    const container = document.createElement("div");
    document.body.prepend(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  const handlePopUp = () => {
    setOpenPopUp(!openPopUp);
  };

  const handleConfirmRescue = async () => {
    try {
      if (!rescueCourseContent)
        return Toastify("error", "Selecione um curso para prosseguir");
      setIsLoading(true);
      const response = await addNewCourse(student, {
        courseAlias: rescueCourseContent.alias,
        typeCourse: undoTranslateTypeCourse(rescueCourseContent.typeCourse)
      });
      if (response.status === 200) {
        setOpenRescuePopUp(undefined);
        window.location.href = `/course/${rescueCourseContent.alias}?message=${encodeURIComponent(
          `${rescueCourseContent.name} resgatado com sucesso!`
        )}`;
      } else {
        Toastify("error", "Erro ao resgatar o curso.");
      }
    } catch (error) {
      Toastify("error", "Erro ao resgatar o curso!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoadingSection isLoading={isLoading} />
      <div
        className={`fixed top-0 left-0 w-full h-full z-[200] flex items-center justify-center transition-300 ${
          !openRescuePopUp && "opacity-0 scale-95 pointer-events-none"
        }`}>
        <BlockquoteModal
          text_top="Adesão ao curso"
          text_blockquote={`Você está resgatando o curso de <span class="font-semibold">${
            rescueCourseContent ? rescueCourseContent.name : "..."
          }</span>, tem certeza que deseja continuar com essa ação?`}
          outsideClick={() => setOpenRescuePopUp(undefined)}
          buttonsRight={[
            <ButtonPrimary
              verifyOnClick={() => setOpenRescuePopUp(undefined)}
              disableHorizontalMovement
              optionalHoverBackground="#FFFFFF08"
              optionalBackground="#00000000"
              textButton="Cancelar"
              optionalTextColor={certifier.colors.text.textPrimary}
              desktopWidth="160px"
              mobileWidth="160px"
            />,
            <ButtonPrimary
              verifyOnClick={handleConfirmRescue}
              textButton={`Confirmar`}
              desktopWidth={150}
            />
          ]}
        />
      </div>
      <ButtonPrimary
        optionalBackground={certifier.colors.buttons.buttonTertiary}
        optionalTextColor={
          certifier.colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"
        }
        leftIcon
        removeTextPadding
        disableHorizontalMovement
        optionalIcon={<HiBeaker />}
        textButton={`Recomendados`}
        desktopWidth="180px"
        mobileWidth="100%"
        verifyOnClick={handlePopUp}
      />
      <div
        className={`fixed top-0 left-0 w-full h-full z-[199] flex items-center justify-center transition-300 ${
          !openPopUp && "opacity-0 scale-95 pointer-events-none"
        } max-md:px-[5vw]`}>
        <div
          className="absolute w-full h-full bg-backgroundOne/40 backdrop-blur-[1px]"
          onClick={handlePopUp}
        />
        <div className="flex justify-center rounded-3xl z-[201] md:overflow-hidden max-w-screen w-fit max-md:w-full">
          <section
            className={`flex flex-col text-textPrimary rounded-3xl p-8 max-md:p-[5vw] md:pb-0 bg-backgroundTwo w-full max-w-[700px] overflow-x-hidden relative transition-300 justify-between custom-dark-scroll custom-transparent-scroll-background md:overflow-y-scroll md:max-h-[95vh]`}>
            <div className="flex w-full flex-col">
              <div className="flex w-full justify-between items-center">
                <span className="font-bold text-[1.5rem] text-left max-w-[1170px] w-full leading-none max-lg:text-[1.25rem]">
                  Com base nos seus interesses
                </span>
                <button
                  onClick={handlePopUp}
                  className="text-[2rem] -mt-[2rem] text-textPrimary/60 hover:text-textPrimary h-[20px] transition-300">
                  &times;
                </button>
              </div>
              <div className="w-full h-[1px] min-h-[1px] bg-backgroundThree rounded-full my-3" />
              <div className="w-full h-full relative">
                <div className="flex items-center absolute w-fit h-full top-0 z-20 bg-gradient-to-r from-backgroundTwo-via-50 to-transparent">
                  <button
                    className="text-2xl p-2 ml-6 hover:text-gray-300 transition-300 max-md:ml-0"
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <HiChevronLeft />
                  </button>
                </div>
                <div className="flex justify-between items-center absolute w-fit h-full top-0 right-0 z-20 bg-gradient-to-l from-backgroundTwo-via-50 to-transparent">
                  <button
                    className="text-2xl p-2 mr-6 hover:text-gray-300 transition-300 max-md:mr-0"
                    onClick={() => swiperRef.current?.slideNext()}>
                    <HiChevronRight />
                  </button>
                </div>

                <Swiper
                  spaceBetween={2}
                  slidesPerView={3}
                  centeredSlides={true}
                  loop={true}
                  className="mySwiper"
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                    swiper.slides.forEach((slide) => {
                      slide.style.cursor = "grab";
                    });
                  }}
                  onSlideChangeTransitionStart={(swiper) => {
                    setActiveSwiperIndex(null);
                    swiper.slides.forEach((slide) => {
                      slide.style.cursor = "grab";
                    });
                  }}
                  onSlideChangeTransitionEnd={(swiper) => {
                    setTimeout(() => {
                      setActiveSwiperIndex(swiper.realIndex || 0);
                      setLastActiveSwiperIndex(swiper.realIndex || 0);
                    }, 100);
                  }}>
                  {courses.map((course, index) => (
                    <SwiperSlide key={index}>
                      {({ isActive }) => (
                        <div
                          className={`relative p-2 transition-300 ${
                            isActive
                              ? "opacity-100 z-10"
                              : "opacity-80 scale-90"
                          } max-md:px-1`}>
                          <div
                            className={`flex items-center justify-center max-h-[280px] transition-300 shadow-lg h-full active:cursor-grabbing`}
                            style={{
                              filter: isActive ? "none" : "brightness(0.2)"
                            }}>
                            <img
                              src={course.image}
                              alt={course.title}
                              className="w-full h-full object-cover select-none max-h-[280px] rounded-[0.8rem] max-md:rounded-[0.5rem]"
                            />
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div
              className={`transition-300 flex flex-col gap-1.5 w-full z-[202] min-h-[150px] pt-3 justify-between`}
              style={{
                background: `linear-gradient(to top, ${certifier.colors.background.backgroundTwo} 60%, transparent)`
              }}>
              <div className="flex flex-col gap-1.5">
                <span
                  className={`w-full text-sm select-none leading-tight font-semibold text-themeColor max-lg:text-[0.75rem] pointer-events-none duration-300 ${
                    activeSwiperIndex === null && "opacity-0"
                  }`}>
                  {courses[lastActiveSwiperIndex].typeCourse}
                </span>
                <h2
                  className={`font-bold text-[1.5rem] text-left max ${
                    activeSwiperIndex === null && "opacity-0"
                  }w}-[1170px] w-full leading-tight max-lg:text-[1.25rem] transition-300 ${
                    activeSwiperIndex === null && "opacity-0"
                  } w-full select-none pointer-events-none`}>
                  {courses[lastActiveSwiperIndex].name}
                </h2>
                <p
                  className={`transition-300 ${
                    activeSwiperIndex === null && "opacity-0"
                  } text-textPrimary text-sm max-lg:text-center line-clamp-3 select-none pointer-events-none max-md:text-left`}>
                  {courses[lastActiveSwiperIndex].description}
                </p>
                <div
                  className={`transition-300 ${
                    activeSwiperIndex === null && "opacity-0"
                  } flex justify-between items-center w-full pt-4`}>
                  <div className="flex items-center gap-3 w-fit h-fit">
                    <div className="flex items-center min-w-[220px] h-5 relative overflow-hidden">
                      <p className="text-textPrimary text-sm max-lg:text-center">
                        Início imediato
                      </p>
                      <div
                        className={`absolute flex items-center gap-2 w-fit transition-300 ${
                          textIndex === 0
                            ? "translate-y-0 opacity-100"
                            : "translate-y-full opacity-0"
                        }`}>
                        <p className="text-textPrimary text-sm max-lg:text-center opacity-0">
                          Início imediato
                        </p>
                        <p className="text-textPrimary text-sm max-lg:text-center">
                          •
                        </p>
                        <p className="text-textPrimary text-sm max-lg:text-center">
                          <b>
                            {courses[lastActiveSwiperIndex].minCourseTime} meses
                          </b>
                        </p>
                      </div>
                      <div
                        className={`absolute flex items-center gap-2 w-fit transition-300 ${
                          textIndex === 1
                            ? "translate-y-0 opacity-100"
                            : "translate-y-full opacity-0"
                        }`}>
                        <p className="text-textPrimary text-sm max-lg:text-center opacity-0">
                          Início imediato
                        </p>
                        <p className="text-textPrimary text-sm max-lg:text-center">
                          •
                        </p>
                        <p className="text-textPrimary text-sm max-lg:text-center">
                          {courses[lastActiveSwiperIndex].workload} horas
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1.5 pt-1.5">
                <div className="w-full h-[1px] bg-backgroundThree rounded-full my-3" />
                <div className="flex justify-between items-center w-full gap-3 md:pb-8">
                  <div className="w-full">
                    <ButtonPrimary
                      optionalBackground={
                        certifier.colors.buttons.buttonTertiary
                      }
                      optionalTextColor={
                        certifier.colors.buttons.buttonTertiary_darkTheme
                          ? "#fff"
                          : "#000"
                      }
                      textButton="Resgatar curso"
                      desktopWidth="100%"
                      mobileWidth="100%"
                      verifyOnClick={() => {
                        setOpenPopUp(false);
                        setOpenRescuePopUp(true);
                        setRescueCourseContent(courses[lastActiveSwiperIndex]);
                      }}
                    />
                  </div>
                  <Link
                    to={`/rescueCourse/${courses[lastActiveSwiperIndex].typeCourse}/${courses[lastActiveSwiperIndex].alias}`}
                    target="_blank"
                    className="w-full">
                    <ButtonPrimary
                      optionalBackground="transparent"
                      outlineType
                      outlineColor="#FFF"
                      optionalTextColor={
                        certifier.colors.buttons.buttonTertiary_darkTheme
                          ? "#000"
                          : "#fff"
                      }
                      textButton={`Saiba mais`}
                      desktopWidth="100%"
                      mobileWidth="100%"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
