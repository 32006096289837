import React, { useEffect, useState } from "react";

function SelectButton({
  text,
  icon,
  value,
  onClick,
  selectedMethod,
  enableBorder,
}) {
  return (
    <button
      onClick={onClick}
      className={`flex pl-4 items-center w-full gap-x-3 bg-backgroundTwo rounded-md py-4
      ${!enableBorder && "transition-all duration-300"} ${
        enableBorder && value === selectedMethod ? "border-2" : ""
      } ${
        value === selectedMethod || enableBorder
          ? "border-themeColor"
          : "border-transparent"
      }`}
    >
      <span>{icon}</span>
      <span className={`text-xs text-textPrimary ${value === selectedMethod ? "font-bold" : "font-normal" }`}>{text}</span>
    </button>
  );
}

export default SelectButton;
