import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toastify.css";
import * as Sentry from "@sentry/react";
// const sign = require("jwt-encode"); //

export const Toastify = async (type, message, reportBug) => {
  let messageString = "";

  if (Array.isArray(message)) {
    messageString = message.join(", ");
  } else if (typeof message !== "string") {
    messageString = String(message);
  } else if (message && message !== null) {
    messageString = message;
  }

  if (!messageString || messageString === "") return;

    switch (type) {
      case "success":
        toast.success(messageString, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "dark",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
        break;
      case "error":
        if (reportBug) reportSentryBug();

        toast.error(messageString, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "dark",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
        break;
      default:
        toast(messageString, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "dark",
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
    }

  function reportSentryBug() {
    Sentry.init({
      dsn: "https://edb998eb01a834efde0627bd5719f27b@o4507074725871616.ingest.us.sentry.io/4507074768994304",

      integrations: [
        Sentry.feedbackIntegration({
          buttonLabel: "Reportar Erro",
          submitButtonLabel: "Enviar",
          cancelButtonLabel: "Cancelar",
          formTitle: "Reportar o Erro",
          nameLabel: "Nome",
          namePlaceholder: "Seu Nome",
          emailLabel: "E-mail",
          emailPlaceholder: "seu.email@example.org",
          messageLabel: "Descrição",
          messagePlaceholder: "Qual é o erro?",
          successMessageText: "Obrigado pelo seu relatório!",
          isRequiredText: "(obrigatório)",

          colorScheme: "system",
        }),
      ],
    });
  }
};
