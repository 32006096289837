export const barCodeImg = (barCode) => {
  const str = barCode.replace(/\D/g, "");
  const str1 = str.substring(0, 4);
  const str2 = str.substring(32, 47);
  const str3 = str.substring(4, 9);
  const str4 = str.substring(10, 20);
  const str5 = str.substring(21, 31);
  const barcode = str1 + str2 + str3 + str4 + str5;
  const url = `http://api-bwipjs.metafloor.com/?bcid=interleaved2of5&text=${barcode}`;
  return url;
};
