import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../cryptoService/crypto.service";
import * as Sentry from "@sentry/react";
import { certifier } from "../../mocks/certifier";

export const getDisciplineAndClasses = async (data) => {
  try {
    const sessionId = data.sessionId;
    const classId = data.classId;
    const courseAlias = data.courseAlias;
    const disciplineId = data.disciplineId;
    const post_data = await CryptoService(
      {
        sessionId: sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        classId: classId,
        courseAlias: courseAlias,
        disciplineId: disciplineId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/getClassPage`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.responseCrypt.data.code };
  }
};

export const getClassFromDemoCourse = async (data) => {
  try {
    const responseCrypt = await axios.put(
      `${process.env.REACT_APP_API_ADDRESS}/getNextClassCourseAvailable`,
      {courseAlias: data.courseAlias}
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: responseCrypt.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.responseCrypt.data.code };
  }
};

export const getNextClass = async (data) => {
  try {
    const sessionId = data.sessionId;
    const disciplineId = data.disciplineId;
    const classId = data.classId;
    const courseAlias = data.courseAlias;
    const post_data = await CryptoService(
      {
        sessionId,certifierName: certifier.generalSettings.name,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const cryptoInfos = await CryptoService(
      {
        classId: classId,
        courseAlias: courseAlias,
        disciplineId: disciplineId,
        exp: moment().add(2, "hours").unix(),
      },
      "encrypt-jwt"
    );
    const body = { token: cryptoInfos };
    const config = {
      headers: {
        auth: `${post_data}`,
      },
    };
    const responseCrypt = await axios.post(
      `${process.env.REACT_APP_API_ADDRESS}/nextClass`,
      body,
      config
    );
    const response = await CryptoService(
      responseCrypt.data.data,
      "decrypt-json"
    );
    if (responseCrypt.data.code !== 200) {
      return { status: response.data.code };
    }
    return {
      data: response,
      status: responseCrypt.data.code,
    };
  } catch (err) {
    if (err.response && String(err.response.status) === "401") {
      Sentry.setUser(null);
      
      localStorage.removeItem("user");
      if (window) window.location.reload();
      return;
    }
    return { status: err.response.data.code };
  }
};
