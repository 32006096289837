import React, { useEffect, useState } from "react";
import "./FinanceTablePaid.css";
import { HiMiniCheck } from "react-icons/hi2";

function FinanceTablePaid({ finances }) {
  return (
    <main className="flex flex-col w-full gap-y-8">
      <div className="">
        <h1 className="text-xl text-textPrimary font-bold leading-[1.35]">Pagas</h1>
        <span className="text-sm text-textSecondary">
          Cobranças que já foram pagas.
        </span>
      </div>

      <section>
        <div className="grid finance-grid gap-2 pb-5 px-4">
          <span className="text-sm font-semibold">Cobrança</span>
          <span className="text-sm font-semibold text-center">Valor</span>
          <span className="text-sm font-semibold text-center max-sm:hidden">
            Pagamento
          </span>
          <span className="text-sm font-semibold text-center max-sm:hidden">
            Método
          </span>
          <span className="text-sm font-semibold text-center">Situação</span>
        </div>
        <div className="flex flex-col gap-2">
          {finances.map((finance, index) => (
            <div className="grid finance-grid gap-2 bg-backgroundThree rounded-md py-5 px-4 items-center">
              <span className="text-sm truncate">{finance.financeName}</span>
              <span className="text-sm text-center">{finance.value}</span>
              <span className="text-sm text-center max-sm:hidden">
                {finance.datePaid}
              </span>
              <span className="text-sm text-center max-sm:hidden">
                {finance.methodPayment}
              </span>
              <div className="flex w-full justify-center">
                <span className="text-xs text-center font-bold w-fit px-2 py-1 rounded-full text-[#3730a3] bg-[#e0e7ff]">
                  {String(finance.situation).toUpperCase()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default FinanceTablePaid;
