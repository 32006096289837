import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ListCourses from "../../components/ListCourses/ListCourses";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import { getAllStudentCourses } from "../../services/courseService/course.service";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";

function StudentCourses() {
  const [studentCourses, setStudentCourses] = useState(undefined);
  const [formattedStudentCourses, setFormattedStudentCourses] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);

  useEffect(() => {
    const fetchAreasInfo = async () => {
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const student = await getStudentInfos();
        // get --- START
        const arrayCourses = await getAllStudentCourses({
          sessionId: student.sessionId,
        });
        if (arrayCourses.status !== 200) {
          Toastify(
            "error",
            "Erro ao buscar informações de seus cursos!"
          );
          console.error(
            "Erro ao buscar informações de seus cursos. - 2:",
            arrayCourses.status
          );
        }
        // get --- END
        try {
          setStudentCourses(arrayCourses.data);
        } catch {
          Toastify(
            "error",
            "Erro ao processar itens dos cursos do estudante: #01"
          );
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar cursos do estudante.");
        console.error("Erro ao buscar cursos do estudante: ", error);
      }
    };
    fetchAreasInfo();
  }, []);

  useEffect(() => {
    if (studentCourses !== undefined) {
      let allCourses = studentCourses.coursesPosGraduation;
      if (studentCourses.extraCourses)
        allCourses = allCourses.concat(studentCourses.extraCourses);
      setFormattedStudentCourses(allCourses);
    }
    setIsLoading(false);
  }, [studentCourses]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <HeaderBar
        actualPage="Aulas"
        courseNavigation={false}
        mobileMainText="Meus Cursos"
        mobileMinHeaderHeight={0}
        mobileBackPage="/"
      />
      <MobileNavigationBar actualPage="Aulas" />
      <LoadingSection isLoading={isLoading} />
      <div
        style={{ height: windowWidth < 960 ? windowHeight - navBar : "auto" }}
        className={`w-full justify-center items-center overflow-x-hidden ${
          windowWidth < 960
            ? "overflow-y-scroll custom-dark-scroll custom-transparent-scroll-background"
            : "flex flex-col"
        }`}
      >
        {formattedStudentCourses && (
          <ListCourses
            enableEnterAllCourses
            allCourses={formattedStudentCourses}
            // headerTitle="Meus Cursos"
            // headerSubtitle="Continue aprendendo e evoluindo sua vida acadêmica"
          />
        )}
      </div>
    </main>
  );
}

export default verifyAuth(StudentCourses);
