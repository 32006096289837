import React, { useEffect, useState } from "react";
import { Toastify } from "../../components/Toastify/Toastify";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import {
  getSendAvaliation,
  getStartAvaliation,
  getViewCorrectionAvaliation,
} from "../../services/avaliationServices/avaliation.service";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import BlockquoteModal from "../../components/Modals/BlockquoteModal";
import { Link } from "react-router-dom";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import * as Sentry from "@sentry/react";

function InsideAvaliationPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [avaliationData, setAvaliationData] = useState(undefined);
  const [avaliationModal, setAvaliationModal] = useState(undefined);
  const [pageParams, setPageParams] = useState(undefined);
  const [isViewAvaliation, setIsViewAvaliation] = useState(false);
  const [timer, setTimer] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [navBarHeader, setNavBarHeader] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);
        const storedQuestionsEncrypted = localStorage.getItem("questionsData");
        let storedQuestions = storedQuestionsEncrypted
          ? await CryptoService(storedQuestionsEncrypted, "decrypt-json")
          : null;

        const urlParams = new URLSearchParams(window.location.search);
        const key = decodeURIComponent(String(urlParams.get("key")));
        const decryptParam = await CryptoService(key, "decrypt-json");
        setPageParams(decryptParam);
        if (decryptParam.isViewAvaliation) {
          storedQuestions = null;
          const avaliation = await getViewCorrectionAvaliation(decryptParam);
          setIsViewAvaliation(true);
          setAvaliationData(avaliation.data);
          setIsLoading(false);
        } else {
          const avaliationResponse = await getStartAvaliation(decryptParam);

          if (avaliationResponse.status === 200) {
            const apiQuestions = avaliationResponse.data.questions;
            if (storedQuestions) {
              const questionsWithSelectedAndUpdated = apiQuestions.map(
                (question) => {
                  const storedQuestion = storedQuestions.find(
                    (q) => q._id === question._id
                  );
                  if (storedQuestion) {
                    const answersWithSelectedFlag = question.answers.map(
                      (answer) => ({
                        ...answer,
                        selectedAnswer: !!storedQuestion.answers.find(
                          (a) => a._id === answer._id && a.selectedAnswer
                        ),
                      })
                    );
                    const selectedAnswer = answersWithSelectedFlag.find(
                      (answer) => answer.selectedAnswer
                    );
                    return {
                      ...question,
                      answers: answersWithSelectedFlag,
                      selectedAnswer: selectedAnswer
                        ? selectedAnswer._id
                        : undefined,
                    };
                  }
                  return question;
                }
              );
              setAvaliationData({
                ...avaliationResponse.data,
                questions: questionsWithSelectedAndUpdated,
              });
            } else {
              setAvaliationData(avaliationResponse.data);
            }
          } else {
            Toastify("error", "Erro ao buscar avaliação.");
            window.location.href = "/avaliations";
          }
        }
        const navBarHeader = document.getElementById("mainHeader");
        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        console.error("error1", error);
        Toastify("error", "Erro generalizado ao buscar a avaliação.");
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleAnswerClick = async (questionId, answerId) => {
    const updatedQuestions = avaliationData.questions.map((question) => {
      if (question._id === questionId) {
        const updatedAnswers = question.answers.map((answer) => ({
          ...answer,
          selectedAnswer: answer._id === answerId,
        }));

        return {
          ...question,
          answers: updatedAnswers,
          selectedAnswer: answerId,
        };
      }
      return question;
    });

    setAvaliationData((prevState) => ({
      ...prevState,
      questions: updatedQuestions,
    }));

    const encryptedData = await CryptoService(updatedQuestions, "encrypt-jwt");
    localStorage.setItem("questionsData", encryptedData);
  };

  const alphabet = [
    { number: 0, replaceBy: "A" },
    { number: 1, replaceBy: "B" },
    { number: 2, replaceBy: "C" },
    { number: 3, replaceBy: "D" },
    { number: 4, replaceBy: "E" },
    { number: 5, replaceBy: "F" },
    { number: 6, replaceBy: "G" },
    { number: 7, replaceBy: "H" },
    { number: 8, replaceBy: "I" },
    { number: 9, replaceBy: "J" },
    { number: 10, replaceBy: "K" },
  ];

  const handleSendAvaliation = async () => {
    try {
      const allQuestionsAnswered = avaliationData.questions.every((question) =>
        question.answers.some((answer) => answer.selectedAnswer)
      );

      if (!allQuestionsAnswered) {
        setAvaliationModal("emptyQuestions");
      } else {
        const response = await getSendAvaliation({
          pageParams,
          avaliationData,
        });
        if (response.status === 200) {
          localStorage.removeItem("questionsData");
          window.location.href = "/avaliations";
        } else {
          if (response.status) {
            Toastify("error", `Erro ${response.status} ao enviar avaliação.`);
          } else {
            Toastify("error", `Erro desconhecido ao enviar avaliação.`);
          }
          return;
        }
      }
      const timerDataEncrypted = localStorage.getItem("timerData");
      let timerData = timerDataEncrypted
        ? await CryptoService(timerDataEncrypted, "decrypt-json")
        : {};
      delete timerData[avaliationData._id];
      const newTimerDataEncrypted = await CryptoService(
        timerData,
        "encrypt-jwt"
      );
      localStorage.setItem("timerData", newTimerDataEncrypted);
    } catch (error) {
      Sentry.captureMessage(
        `Erro ao enviar avaliação: ${String(error.message)}`
      );
      Toastify(
        "error",
        `Erro ao enviar a avaliação. Tente novamente mais tarde.`
      );
    }
  };

  async function startTimer() {
    const timerDataEncrypted = localStorage.getItem("timerData");
    let timerData = timerDataEncrypted
      ? await CryptoService(timerDataEncrypted, "decrypt-json")
      : {};

    if (avaliationData)
      if (timerData[avaliationData._id]) {
        setTimer(timerData[avaliationData._id]);
      } else {
        setTimer(avaliationData.avaliationTime.value * 60);
      }
  }

  useEffect(() => {
    startTimer();

    const interval = setInterval(async () => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          const newTimer = prevTimer - 1;
          // Salvar no localStorage
          async function saveTimer() {
            const timerDataEncrypted = localStorage.getItem("timerData");
            let timerData = timerDataEncrypted
              ? await CryptoService(timerDataEncrypted, "decrypt-json")
              : {};
            timerData[avaliationData._id] = newTimer;
            const newTimerDataEncrypted = await CryptoService(
              timerData,
              "encrypt-jwt"
            );
            localStorage.setItem("timerData", newTimerDataEncrypted);
          }
          saveTimer();
          return newTimer;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [avaliationData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatTimer(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <main className="flex flex-col w-full overflow-x-hidden bg-backgroundOne min-h-screen items-center text-textPrimary">
      <HeaderBar
        courseNavigation={false}
        actualPage="Provas"
        mobileMainText={
          avaliationData?.disciplineName
            ? avaliationData.disciplineName
            : "Realize a avaliação"
        }
        mobileBackPage="/avaliations"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Provas" />
      <LoadingSection isLoading={isLoading} />
      {avaliationData && (
        <section
          style={{
            paddingBottom: windowWidth < 960 ? navBar : 0,
            paddingTop: windowWidth < 960 ? navBarHeader : 0,
          }}
          className={`overflow-hidden mb-9 flex flex-col gap-y-8 w-full h-[auto] max-w-[1170px] min-lg:justify-center`}
        >
          <div className="flex px-10 pt-10 gap-4 items-center max-sm:flex-col justify-center">
            <div className="flex flex-col w-full gap-y-1 max-sm:items-center">
              <h1 className="my-2 text-3xl text-textPrimary font-bold leading-[1.35] max-md:text-center">
                {avaliationData.disciplineName}
              </h1>
              <p className="text-textPrimary text-sm w-full max-sm:text-center">
                {isViewAvaliation
                  ? `Você foi ${
                      avaliationData.grade < avaliationData.minGrade.value
                        ? "reprovado"
                        : "aprovado"
                    } com nota ${avaliationData.grade} nesta avaliação
                  `
                  : `A prova possui ${
                      avaliationData.questions
                        ? avaliationData.questions.length
                        : 10
                    } questões com
                média de ${
                  avaliationData.minGrade.value
                } para aprovação, essa é a
                sua ${avaliationData.tries}ª tentativa`}
              </p>
            </div>
            <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
              {isViewAvaliation
                ? avaliationData.grade >= avaliationData.minGrade.value
                  ? "Aprovado"
                  : "Reprovado"
                : formatTimer(timer)}
            </h1>
          </div>
          <div className="w-full h-[1px] bg-backgroundThree rounded-full" />
          <div className="w-full">
            <section className="overflow-hidden flex flex-col gap-8 w-full h-full max-w-[1170px] justify-center max-lg:px-[5vw]">
              {avaliationData.questions &&
                avaliationData.questions.map((question, index) => (
                  <section
                    key={index}
                    className="overflow-hidden flex flex-col gap-5 w-fullh-auto max-w-[1170px] justify-center bg-backgroundTwo rounded-xl p-10 max-sm:p-6"
                  >
                    <div className="flex flex-col gap-y-2">
                      <p className="text-textPrimary text-lg font-semibold">
                        Questão {index + 1}
                      </p>
                      {question && question !== null && question.statement ? (
                        <div
                          className="text-textPrimary important-text-textPrimary"
                          dangerouslySetInnerHTML={{
                            __html: question.statement,
                          }}
                        ></div>
                      ) : (
                        <div className="text-textPrimary important-text-textPrimary">
                          <b>Questão não definida.</b> Por favor, procure pelo
                          atendimento.
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-5">
                      {question.answers ? (
                        question.answers.map((answer, answerIndex) =>
                          answer === null ? (
                            <div
                              className={`flex text-sm flex-col gap-y-2 p-5 rounded-xl select-none bg-backgroundThree text-textPrimary`}
                            >
                              <p>
                               Por favor, procure pelo
                                atendimento!
                              </p>
                            </div>
                          ) : (
                            <div
                              key={answerIndex}
                              className={`flex text-sm flex-col gap-y-2 p-5 rounded-xl select-none ${
                                isViewAvaliation
                                  ? question.selectedAnswer === answer._id
                                    ? answer.right
                                      ? "bg-[#22c45e]"
                                      : "bg-[#ed3237]"
                                    : "bg-backgroundThree text-textPrimary"
                                  : answer.selectedAnswer
                                  ? "cursor-pointer bg-white text-black"
                                  : "cursor-pointer bg-backgroundThree text-textPrimary"
                              }`}
                              onClick={() =>
                                !isViewAvaliation &&
                                handleAnswerClick(question._id, answer._id)
                              }
                            >
                              <p>
                                {
                                  alphabet.find(
                                    (letter) => letter.number === answerIndex
                                  ).replaceBy
                                }
                                {") "}
                                {answer.title}
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <div
                          className={`flex text-sm flex-col gap-y-2 p-5 rounded-xl select-none bg-backgroundThree text-textPrimary`}
                        >
                          <p>
                            Por favor, procure pelo
                            atendimento.
                          </p>
                        </div>
                      )}
                    </div>
                  </section>
                ))}
            </section>
          </div>
          <div className="w-full h-[1px] bg-backgroundThree rounded-full" />
          <div className="flex w-full max-sm:justify-end max-sm:px-8 max-mc:mb-5">
            {isViewAvaliation ? (
              <Link to="/avaliations">
                <ButtonPrimary
                  textButton="Voltar"
                  desktopWidth="220px"
                  mobileWidth="150px"
                  mobilePadding={15}
                />
              </Link>
            ) : (
              <ButtonPrimary
                verifyOnClick={handleSendAvaliation}
                textButton="Finalizar avaliação"
                desktopWidth="220px"
                mobileWidth="220px"
                mobilePadding={15}
              />
            )}
          </div>
        </section>
      )}

      {/* Modal */}
      {avaliationModal && (
        <BlockquoteModal
          text_top="Questões em branco"
          text_blockquote="Há questões em branco, marque-as para finalizar sua prova."
          outsideClick={() => setAvaliationModal(undefined)}
          buttonsRight={[
            <ButtonPrimary
              disableHorizontalMovement
              verifyOnClick={() => setAvaliationModal(undefined)}
              textButton="OK"
              desktopWidth="80px"
              mobileWidth="100%"
            />,
          ]}
        />
      )}
    </main>
  );
}

export default verifyAuth(InsideAvaliationPage);
