import React, { useEffect, useRef } from "react";

const HomePandaVideoPlayer = ({
  autoPlay,
  sessionId,
  disciplineId,
  classId,
  courseId,
  className,
  src,
  time,
  backgroundVideo,
}) => {
  let player;

  const containerRef = useRef(null);

  // Function to extract video ID from src
  const getVideoIdFromSrc = (src) => {
    try {
      const url = new URL(src, window.location.origin);
      return url.searchParams.get("v");
    } catch (error) {
      console.error("Invalid src URL:", error);
      return null;
    }
  };

  useEffect(() => {
    const videoId = getVideoIdFromSrc(src);

    if (!videoId) {
      console.error("Unable to extract video ID from src.");
      return;
    }

    const script = document.createElement("script");
    script.src = "https://player.pandavideo.com.br/api.v2.js";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      let videoStatus = "";
      window.pandascripttag = window.pandascripttag || [];
      window.pandascripttag.push(function () {
        player = new window.PandaPlayer(`panda-${videoId}`, {
          onReady: () => {
            player.onEvent(function ({ message }) {
              if (message === "panda_pause") {
                videoStatus = "paused";
              } else if (message === "panda_play") {
                videoStatus = "playing";
              }
            });
          },
          onError: (event) => {
            console.error("Player onError", event);
          },
        });
      });
    };

    script.onerror = () => {
      console.error("Failed to load the PandaVideo script.");
    };

    // Optional: Suppress 'Script error.' messages from cross-origin scripts
    const handleWindowError = (message, source, lineno, colno, error) => {
      if (message === "Script error.") {
        // Suppress the error
        return true; // Prevents the error from being logged to the console
      }
      // For other errors, return false to let them propagate
      return false;
    };

    window.addEventListener("error", handleWindowError);

    return () => {
      window.removeEventListener("error", handleWindowError);
      // document.body.removeChild(script);
    };
  }, [src]);

  useEffect(() => {
    window.addEventListener("resize", adjustVideoDimensions);

    adjustVideoDimensions();

    return () => window.removeEventListener("resize", adjustVideoDimensions);
  }, [backgroundVideo]);

  const adjustVideoDimensions = () => {
    if (!containerRef.current) return;

    if (window.innerWidth <= 1024 && backgroundVideo) {
      const screenHeight = window.innerHeight;
      const desiredHeight = screenHeight * 0.85;
      const desiredWidth = desiredHeight * (16 / 9);
      containerRef.current.style.height = `${desiredHeight}px`;
      containerRef.current.style.width = `${desiredWidth}px`;
      containerRef.current.style.left = "50%";
      containerRef.current.style.transform = "translateX(-50%)";
    } else {
      const desiredHeight = window.innerWidth * (9 / 16);
      containerRef.current.style.width = `${window.innerWidth}px`;
      containerRef.current.style.height = `${desiredHeight}px`;
    }
  };

  // Extract video ID for iframe
  const videoId = getVideoIdFromSrc(src);

  if (!videoId) {
    return <div>Error: Invalid video source URL.</div>;
  }

  return (
    <div
      ref={containerRef}
      id="video-player-container"
      className={className ?? "w-full h-full"}
    >
      <iframe
        id={`panda-${videoId}`}
        src={`${src}&preload=true&startTime=${
          time ?? "0"
        }&troubleshootDialog=false${
          autoPlay
            ? "&autoplay=true&muted=true&hideControlsOnStart=true&hideControls=true&restartAfterEnd=true&initDisabledControls=true"
            : ""
        }`}
        style={{
          border: "0",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default HomePandaVideoPlayer;
